@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background: rgba(12, 17, 23, 1.0) !important;
    scroll-behavior: smooth;
}
a
input,select{
  outline: none;
}
/* .batch_heart{
  background: rgb(70, 211, 0) !important;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* ID classes */

/* Sidebar*/
#desktop-sidebar

/* Switcheroo */
#switcheroo-icon
#current-profile-avatar

/*---------------CUSTOM CSS---------------*/

/* Memberships */

.membership-start-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('https://res.cloudinary.com/dzlopmfj8/image/upload/v1719146372/membershipbackground.webp') no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.membership-start-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

.membership-start-content {
    text-align: center;
    color: white;
}

.membership-start-logo {
    max-height: 240px;
    margin-bottom: 20px;
}

.membership-start-button {
    padding: 10px 20px;
    font-size: 18px;
    color: white;
    background-color: #36d300;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-start-button:hover {
    background-color: #34b704 !important; 
}
.membership-agreement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    padding: 25px;
}

.membership-agreement-content {
    background: rgba(250, 250, 250, 0.1);
    padding: 25px;
    border-radius: 20px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    text-align: left;
}

.membership-agreement-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.membership-agreement-text {
    margin-bottom: 25px;
}
.membership-agreement-text.h3 {
  margin-bottom: 25px;
  text-align: center;
}

.membership-agreement-text h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.membership-tos-text h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.membership-agreement-text.li {
  display: block;
}

.membership-agreement-signature {
    margin-bottom: 25px;
}

.membership-agreement-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.signature-canvas {
    width: 100%;
    height: 150px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 5px;
}

.membership-agreement-clear-button {
    margin-top: 15px;
    padding: 15px 25px;
    font-size: 14px;
    color: white;
    background-color: #ff0000;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-agreement-clear-button:hover {
    background-color: #cc0000;
}

.membership-agreement-error {
    color: red;
    margin-top: 10px;
}

.membership-agreement-buttons {
    display: flex;
    justify-content: space-between;
}

.membership-agreement-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-agreement-button-back {
    background-color: rgba(250, 250, 250, 0.2);
    color: white;
}

.membership-agreement-button-next {
    background-color: #36d300;
    color: white;
}

.membership-agreement-button-back:hover {
    background-color: rgba(250, 250, 250, 0.3);
}

.membership-agreement-button-next:hover {
    background-color: #32b202;
}


.membership-tos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    padding: 25px;
}

.membership-tos-content {
    background: rgba(250, 250, 250, 0.1);
    padding: 40px;
    border-radius: 20px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    text-align: left;
}

.membership-tos-text.h4 {
  text-align: center;
}

.membership-tos-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center
}

.membership-tos-text {
    margin-bottom: 25px;
}

.membership-tos-signature {
    margin-bottom: 25px;
}

.membership-tos-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.signature-canvas {
    width: 100%;
    height: 150px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 5px;
}

.membership-tos-clear-button {
    margin-top: 15px;
    padding: 15px 25px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    background-color: #ff0000;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-tos-clear-button:hover {
    background-color: #cc0000;
}

.membership-tos-error {
    color: red;
    margin-top: 10px;
}

.membership-tos-buttons {
    display: flex;
    justify-content: space-between;
}

.membership-tos-button {
    padding: 15px 25px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-tos-button-back {
    background-color: rgba(250, 250, 250, 0.1);
    color: white;
}

.membership-tos-button-next {
    background-color: #36d300;
    color: white;
}

.membership-tos-button-back:hover {
    background-color: #5a6268;
}

.membership-tos-button-next:hover {
    background-color: #36d300;
}

.progress-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.progress-dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
}

.progress-dot.active {
    background-color: #36d300;
}

.membership-personal-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    padding: 20px;
}

.membership-personal-data-content {
    background: rgba(250, 250, 250, 0.1);
    border: 1px solid rgba(250, 250, 250, 0.4);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.membership-personal-data-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.membership-personal-data-field {
    margin-bottom: 20px;
}

.membership-personal-data-field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.membership-personal-data-field input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 15px;
    background: rgba(0,0,0,0.5);
}

.membership-personal-data-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: #36d000;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.membership-personal-data-button:hover {
    background-color: #36d300;
    color: white;
}

.membership-personal-data-error {
    color: red;
    margin-top: 10px;
}

.membership-personal-data-photo {
    margin-top: 20px;
    max-width: 100%;
    border-radius: 10px;
}

.membership-personal-data-webcam {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.membership-summary {
    background-color: rgba(0,0,0,0.5);
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
}

.membership-summary h3 {
    margin-bottom: 10px;
}

.membership-summary p {
    margin: 5px 0;
}

.membership-photo-preview,
.membership-signature-preview {
    max-width: 100px;
    display: block;
    margin-top: 10px;
}

/* Membership checkout */
.membership-checkout-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.membership-checkout-grid {
  display: grid;
  grid-template-columns: 2fr 1fr; 
  gap: 20px;
}

@media only screen and (max-width: 1000px) {
  .membership-checkout-grid {
    display: block;
  }
}

.membership-checkout-info {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
}

.membership-checkout-details {
  margin-bottom: 20px;
}

.membership-checkout-details h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.membership-checkout-details p {
  margin-bottom: 5px;
}

.membership-checkout-summary-container {
  padding: 20px;
  border: 1px solid rgba(250, 250, 250, 0.4); 
  border-radius: 10px;
  margin-top: 20px;
  background-color: rgba(250, 250, 250, 0.1);
}

.membership-checkout-summary-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 5px;
}

.membership-checkout-summary-description {
  margin-top: 8px;
  max-width: 640px;
  font-size: 0.875rem;
  color: #6b7280; 
}

.membership-checkout-summary-section {
  margin-top: 20px;
  border-top: 1px solid #e5e7eb;
  padding-top: 15px;
}

.membership-checkout-summary-row {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #e5e7eb;
}

.membership-checkout-summary-row dt {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
}

.membership-checkout-summary-row dd {
  margin-top: 0;
  font-size: 0.875rem;
  color: #4b5563; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.membership-checkout-summary-photo {
  max-width: 100px;
  border-radius: 4px;
  margin-top: 10px;
}

.membership-checkout-summary-button {
  font-weight: 500;
  color: #6366f1; /* Indigo */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.membership-checkout-summary-button:hover {
  color: #4f46e5; /* Darker Indigo */
}


.membership-checkout-photo {
  max-width: 100px;
  margin-top: 10px;
  border-radius: 4px;
}

.membership-checkout-signature {
  margin-top: 20px;
}

.membership-checkout-signature-canvas {
  width: 100%;
  height: 150px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 10px;
  margin-top: 10px;
}

.membership-checkout-clear-signature {
  margin-top: 10px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 25px;
}

.membership-checkout-clear-signature:hover {
  background-color: #d9363e;
}

.membership-checkout-form {
  margin-top: 20px;
}

.membership-checkout-form div {
  margin-bottom: 15px;
}

.membership-checkout-input {
  width: 100%;
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 10px;
  font-size: 1rem;
}

.membership-checkout-submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 1rem;
}

.membership-checkout-submit-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.membership-checkout-back-button {
  margin-top: 10px;
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 1rem;
}

.membership-checkout-payment {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}



@media (max-width: 799px) {
/*------LOGO BANNER------*/
    .w-full.flex.items-center.justify-between {
    height: 85px !important;
}

    .site-logo {
    height: auto;
    width: 100px !important;
}

    .bg-\[\#fff\] {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
}
}

@media (min-width: 800px) and (max-width: 1281px) {

.mx-auto.sticky.px-4.py-1.top-0.z-50.bg-black {
    display: block !important;
}

.site-logo {
    height: auto;
    max-width: 300px !important;
}

aside.w-full.lg\:w-1\/4.p-2.bg-\[\#f5f5f5\].p-2.rounded-lg.lg\:sticky.lg\:top-24 {
    margin-bottom: 25px !important;
}

.sidebar-bottom-nav {
    background-color: rgba(0, 0, 0, 0.8) !important;
    bottom: 0;
    box-shadow: 0 -2px 10px #0000001a;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 50;
    border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
}

/*------LOGO BANNER------*/
    .w-full.flex.items-center.justify-between {
    height: 85px !important;
}

    .site-logo {
    height: auto;
    width: 100px !important;
}

    .bg-\[\#fff\] {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
}
	
}
	

@media (max-width: 768px) {
    .grid {
        grid-template-columns: 1fr;
    }

    .membership-summary,
    .membership-details {
        margin-top: 20px;
    }

    .btn-secondary {
        margin-top: 20px;
    }
}









.membership-photo-preview,
.membership-signature-preview {
    max-width: 100px;
    margin-top: 10px;
}

.membership-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
}

.membership-success-content {
    text-align: center;
    max-width: 600px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.membership-success-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.membership-success-message {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.membership-success-email {
    font-size: 1em;
    margin-bottom: 20px;
}

.membership-success-footer {
    font-size: 0.9em;
}

.membership-success-animation {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .membership-success-content {
        padding: 20px;
    }

    .membership-success-title {
        font-size: 1.5em;
    }

    .membership-success-message {
        font-size: 1em;
    }

    .membership-success-email {
        font-size: 0.9em;
    }
}



.qr-reader {
    width: 425px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
  }
  
  .qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
  }
  
  .qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  /* Media Queries for mobile screens */
  @media (max-width: 426px) {
    .qr-reader {
      width: 100%;
    }
  }

/* StudioAdminDashboard.css */
.membership-admin-dashboard {
    padding: 20px;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    font-family: Arial, sans-serif;
}

.membership-admin-dashboard h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
}

.membership-qr-scanner-button {
    display: block;
    margin: 0 auto 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #36d300;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-qr-scanner-button:hover {
    background-color: #3d6000;
}

.membership-members-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 25px;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.membership-members-table th,
.membership-members-table td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.membership-members-table th {
  background-color: rgba(250, 250, 250, 0.1);
  font-weight: bold;
  color: #fff;
}

.membership-members-table td {
  font-size: 14px;
  color: #fff;
}

/* Flexbox layout for member info */
.member-info {
  display: flex;
  align-items: center;
}

.member-details {
  display: flex;
  align-items: center;
}

.member-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
}

.member-text {
  display: flex;
  flex-direction: column;
}

.member-name {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}

.member-email {
  color: #fff;
  font-size: 12px;
  margin-top: 4px;
}

.qr-code-image {
  width: 150px;
  height: 150px;
  margin-top: 10px;
}

.qr-download-link {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.qr-download-link:hover {
  background-color: #0056b3;
}



/* Status Colors */
.membership-member-signed-in {
  background-color: #36d300;
}

.membership-member-canceled {
  background-color: #fe3a3a47;
}

/* Hover effect */
.membership-members-table tr:hover {
  background-color: rgba(250, 250, 250, 0.1);
  transition: background-color 0.2s ease;
}

.membership-admin-logo {
    max-height: 140px;
    margin-bottom: 20px;
}

/* Card-like layout for small screens */
@media (max-width: 768px) {
  .membership-members-table {
      display: none; 
  }
  .membership-member-signed-in {
    background-color: #28a745 !important; 
}

.membership-member-canceled {
    background-color: #e23333 !important; 
}

  .membership-card-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
  }

  .membership-card {
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(250, 250, 250, 0.4);
      border-radius: 20px;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      color: #fff;
      display: flex; 
      align-items: center;
      transition: background-color 0.3s ease;
  }

  .membership-card img {
      width: 90px;
      height: 90px; 
      border-radius: 50%;
      border: 1px solid rgba(250, 250, 250, 0.4);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      margin-right: 20px;
  }

  .membership-card-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px; 
  }

  .membership-card h3 {
      font-size: 16px;
      margin: 0;
  }

  .membership-card span {
      font-size: 14px;
      color: #ccc;
  }
}

/* Hide card layout on larger screens */
@media (min-width: 769px) {
  .membership-card-list {
      display: none; /* Hide the card layout on larger screens */
  }
}



/* MemberDetails.css */

.member-details-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
    box-sizing: border-box;
}

.member-details-modal {
    background: #2c2c2c;
    color: #fff;
    padding: 25px;
    border-radius: 15px;
    max-width: 600px;
    width: 100%;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
}

.member-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.member-close-button:hover {
    color: #ff6b6b;
}

.member-photo-large {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    border: 3px solid #fff;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    margin-bottom: 12px;
    font-size: 16px;
    justify-content: space-between;
    border-bottom: 1px solid #444;
    padding-bottom: 6px;
}

li strong {
    color: #ff6b6b;
}

.cancel-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancel-modal {
    background: rgba(0,0,0,0.5);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.cancel-confirm-button, .cancel-close-button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-confirm-button {
    background-color: #ff4d4d;
    color: white;
}

.cancel-confirm-button:hover {
    background-color: #ff1a1a;
}

.cancel-close-button {
    background-color: rgba(250, 250, 250, 0.1);
    color: white;
}

.cancel-close-button:hover {
    background-color: #5a6268;
}
.member-reinstate-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.member-reinstate-button:hover {
    background-color: #45a049;
}
.membership-search-bar {
    width: 100%;
    padding: 12px 20px;
    margin-bottom: 20px;
    font-size: 16px;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.membership-search-bar:focus {
    border-color: #36d300;
    box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);
    outline: none;
}

.membership-agreement-checkbox {
    display: flex;
    align-items: center;
    margin: 20px 0; 
}

.membership-agreement-checkbox label {
    font-size: 16px;
    color: #ffffff; 
    cursor: pointer;
    user-select: none;
}

.membership-agreement-checkbox input[type="checkbox"] {
    margin-right: 10px; 
    width: 20px;
    height: 20px; 
    cursor: pointer;
}




@media (max-width: 768px) {
    .member-details-modal {
        padding: 20px;
    }

    .member-photo-large {
        width: 100px;
        height: 100px;
    }

    li {
        font-size: 14px;
    }
}


/* SignInModal.css */
/* Overlay */
.member-sign-in-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Container */
.member-sign-in-modal {
    background: #2c2c2c;
    color: #fff;
    padding: 25px;
    border-radius: 15px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center; 
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Image Styling */
.member-sign-in-modal img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Button Styling */
.member-sign-in-modal button {
    background: linear-gradient(135deg, #ff6b6b, #ff4b4b);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.member-sign-in-modal button:hover {
    background: linear-gradient(135deg, #ff4b4b, #ff2b2b);
    transform: translateY(-2px);
}

/* Heading Styling */
.member-sign-in-modal h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Paragraph Styling */
.member-sign-in-modal p {
    font-size: 18px;
    margin-bottom: 20px;
}


.membership-agreement-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    font-family: 'Arial', sans-serif;
    background-color: rgba(0,0,0,0.5);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto; 
}

.membership-agreement-table th,
.membership-agreement-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.membership-agreement-table th {
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    font-weight: 600;
}

.membership-agreement-table tr {
    transition: background-color 0.3s;
}

.membership-agreement-table tr:hover {
    background-color: green;
}

.membership-agreement-table tbody tr:last-child td {
    border-bottom: none;
}

@media screen and (max-width: 600px) {
    .membership-agreement-table thead {
        display: none; 
    }

    .membership-agreement-table, 
    .membership-agreement-table tbody, 
    .membership-agreement-table tr, 
    .membership-agreement-table td {
        display: block; 
        width: 100%; 
    }

    .membership-agreement-table tr {
        margin-bottom: 15px; 
    }

    .membership-agreement-table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .membership-agreement-table td::before {
        content: attr(data-label); 
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
    }
}

.member-cancel-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.member-cancel-button:hover {
    background-color: #ff1a1a;
}




/* Responsive Design */
@media (max-width: 768px) {
    .membership-members-table th, .membership-members-table td {
        padding: 8px;
        font-size: 14px;
    }

    .membership-qr-scanner-button {
        font-size: 16px;
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .membership-admin-dashboard h1 {
        font-size: 24px;
    }

    .membership-members-table th, .membership-members-table td {
        padding: 6px;
        font-size: 12px;
    }
}







/* Ryan's CSS additions */

/* User sign up page */
.user-sign-up-container {
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 1.5rem;
  }
  
  .user-sign-up-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .user-sign-up-title {
    font-size: 2rem;
    font-weight: 800;
    color: #fff;
  }
  
  .user-sign-up-form-container {
    max-width: 32rem;
    width: 100%;
    margin: 0 auto;
  }
  
  .user-sign-up-form {
    background-color: rgba(0,0,0,0.5);
    padding: 2rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0.375rem;
  }
  
  .user-sign-up-form-elements {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .user-sign-up-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #fff;
  }
  
  .user-sign-up-input-container {
    margin-top: 0.25rem;
  }
  
  .user-sign-up-input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.7rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
    color: #fff;
    background-color: #4e4e4e;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .user-sign-up-input:focus {
    border-color: #36d300;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
    outline: none;
  }
  
  .user-sign-up-relative {
    position: relative;
  }
  
  .user-sign-up-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
    color: #9ca3af;
  }
  
  .user-sign-up-file-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .user-sign-up-avatar-preview,
  .user-sign-up-banner-preview {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: #e5e7eb;
  }
  
  .user-sign-up-avatar {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .user-sign-up-avatar-icon {
    height: 2rem;
    width: 2rem;
    color: #9ca3af;
  }
  
  .user-sign-up-file-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    background-color: #ffffff;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .user-sign-up-file-label:hover {
    background-color: #f3f4f6;
  }
  
  .user-sign-up-file-input {
    display: none;
  }
  
  .user-sign-up-banner-placeholder {
    height: 2rem;
    width: 2rem;
    background-color: #e5e7eb;
    border-radius: 50%;
  }
  
  .user-sign-up-submit-button {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #ffffff;
    background-color: #36d300;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .user-sign-up-submit-button:hover {
    background-color: #359e01;
  }
  
  .user-sign-up-footer {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .user-sign-up-link {
    color: #3b82f6;
    text-decoration: none;
  }
  
  .user-sign-up-link:hover {
    text-decoration: underline;
  }
  

/* Activation page */
.activation-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    font-family: Arial, sans-serif;
  }
  
  .activation-signin-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .activation-signin-button:hover {
    background-color: #45a049;
  }
  

/* Switcheroo */
.switcheroo-avatar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green; 
  border-radius: 50%;
  border: 1px solid rgba(250, 250, 250, 0.4); 
  width: 42px;
  height: 42px;
  position: relative;
}

.switcheroo-current-profile-avatar {
  height: 42px;
  width: 42px;
  max-width: 42px;
  border-radius: 100%;
}

.switcheroo-swap-icon {
  position: absolute;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  padding: 3px;
  font-size: 14px;
  color: white;
  background-color: #36d300;
  border: 1px solid #00000080 !important;
}

.switcheroo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.switcheroo-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 420px; 
  max-width: 90%; 
  background-color: rgba(0, 0, 0, 0.8); 
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25); 
  padding: 24px;
  border-radius: 24px;
  overflow: hidden;
  border: 2px solid green;
}

.switcheroo-close-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
}

.switcheroo-modal-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
}

.switcheroo-modal-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.switcheroo-modal-item-image {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.switcheroo-check-icon {
  height: 24px;
  width: 24px;
  margin-left: auto;
  color: green;
}

/* avatar for social */
  .avatar-component-relative {
    position: relative;
  }
  
  .avatar-component-inline-block {
    display: inline-block;
  }
  
  .avatar-component-text-left {
    text-align: left;
  }
  
  .avatar-component-avatar {
    width: 65px !important;
    height: 65px !important;
    border-radius: 50%; 
    border: 0.5px solid #ffffff;
    cursor: pointer;
  }
  
  .avatar-component-subscription-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .avatar-component-subscription-img {
    height: 24px; 
    width: 24px;
  }
  

  /* Sidebar */
  .sidebar-relative {
    position: relative;
  }
  
  .sidebar-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  
  .sidebar-badge {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: #d1fae5;
    background-color: #059669;
    border-radius: 9999px;
  }
  
  .sidebar-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    background-color: #1f2937;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar-nav {
    display: flex;
    justify-content: space-around;
    padding: 0.5rem;
  }
  
  .sidebar-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 0.75rem;
    text-align: center;
    width: 100%;
  }
  
  .sidebar-text-current {
    color: #059669;
  }
  
  .sidebar-text-default {
    color: #6b7280;
  }
  
  .sidebar-icon-current {
    color: #047857;
  }
  
  .sidebar-icon-default {
    color: #9ca3af;
  }
  
  .sidebar-profile-switch {
    padding: 0.5rem;
  }
  
  .sidebar-desktop {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    align-items: center;
  }
  
  @media (min-width: 1024px) {
    .sidebar-desktop {
      display: flex;
    }

    .lg\:px-8 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    }

    .lg\:mx-auto {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }

    .text-white.font-bold {
    font-size: 20px !important;
    }
    .lg\:mx-auto {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }

    .lg\:max-w-7xl {
        max-width: 100% !important;
    }

    .profile-main-area {
        order: 2;
        width: 40%;
        padding: 0px !important;
    }
	  
    .lg\:px-24 {
        padding: 25px 7% !important;
    }
}
  
  
  .sidebar-container {
    width: 4rem;
    background-color: rgba(74, 74, 74, 0.6);
    border-radius: 0 0.5rem 0.5rem 0;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
  }
  
  .sidebar-nav-large {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding-top: 1rem;
  }
  
  .sidebar-nav-item-large {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem;
    border-left-width: 4px;
  }
  
  .sidebar-nav-item-current {
    border-color: #059669;
    background-color: #059669;
    color: #d1fae5;
  }
  
  .sidebar-nav-item-default {
    border-color: transparent;
    color: #6b7280;
  }
  
  .sidebar-nav-item-large:hover {
    background-color: #374151;
    color: #1f2937;
  }
  
  .sidebar-icon-large {
    margin-right: 0.75rem;
    height: 1.5rem;
    width: 1.5rem;
  }
  
  /* Ensure bottom nav is hidden on larger screens */
  @media (min-width: 1024px) {
    .lg-hidden {
      display: none;
    }
  }

  .notification-sidebar-dialog {
  position: relative;
  z-index: 10;
}

.notification-sidebar-overlay {
  position: fixed;
  inset: 0;
}

.notification-sidebar-container {
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.notification-sidebar-inner-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.notification-sidebar-panel-container {
    pointer-events: none;
    position: fixed;
    top: 10vh;
    bottom: 0;
    right: 0;
    display: flex;
    max-width: 100%;
    padding-left: 10px;
  }

.notification-sidebar-enter {
  transition: transform ease-in-out 0.5s;
}

.notification-sidebar-enterFrom {
  transform: translateX(100%);
}

.notification-sidebar-enterTo {
  transform: translateX(0);
}

.notification-sidebar-leave {
  transition: transform ease-in-out 0.5s;
}

.notification-sidebar-leaveFrom {
  transform: translateX(0);
}

.notification-sidebar-leaveTo {
  transform: translateX(100%);
}

.notification-sidebar-panel {
  pointer-events: auto;
  width: 100%;
  max-width: 28rem;
  background-color: rgba(0, 0, 0, .9) !important;
}

.notification-sidebar-panel-content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: rgb(250, 250, 250, 0.2);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.notification-sidebar-header {
  padding: 24px 16px;
}

.notification-sidebar-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-sidebar-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: white;
}

.notification-sidebar-close-container {
  margin-left: 12px;
  display: flex;
  height: 1.75rem;
  align-items: center;
}

.notification-sidebar-close-button {
  position: relative;
  border-radius: 0.375rem;
  background-color: #36d300;
  color: #fff;
}

.notification-sidebar-close-button:hover {
  color: white;
}

.notification-sidebar-close-button:focus {
  outline: none;
}

.notification-sidebar-close-overlay {
  position: absolute;
  inset: -10px;
}

.notification-sidebar-close-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.notification-sidebar-header-spacer {
  margin-top: 4px;
}

.notification-sidebar-body {
  position: relative;
  flex: 1;
  padding: 24px 16px;
}

.notification-feed-flow-root {
    display: flow-root;
}

.notification-feed-list {
    margin-bottom: -2rem;
}

.notification-feed-item {
    position: relative;
    padding-bottom: 2rem;
}

.notification-feed-connector {
    position: absolute;
    left: 1.25rem;
    top: 1.25rem;
    margin-left: -1px;
    height: 100%;
    width: 0.125rem;
    background-color: #e5e7eb; 
}

.notification-feed-icon {
  color: #6b7280;
  height: 1.25rem;
  width: 1.25rem;
  float: left !important;
  margin-left: 0px !important;
  margin-right: 15px !important;
}

.notification-feed-icon-wrapper {
    position: relative;
}

.notification-feed-avatar {
    display: flex;
    height: 2.5rem; 
    width: 2.5rem; 
    align-items: center;
    justify-content: center;
    border-radius: 9999px; 
}

.notification-feed-content-wrapper {
    display: flex;
    align-items: start;
    padding-left: 0.75rem;
    padding-right: 0.75rem; 
}

.notification-feed-text-wrapper {
    min-width: 0;
    flex: 1;
}

.friend-list-panel-root {
    position: relative;
    z-index: 10;
  }
  
  .friend-list-panel-fixed-inset {
    position: fixed;
    inset: 0;
  }
  
  .friend-list-panel-overflow-hidden {
    overflow: hidden;
  }
  
  .friend-list-panel-absolute-inset {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }
  
  .friend-list-panel-pointer-events-none {
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    max-width: 100%;
    padding-left: 10px;
  }
  
  .friend-list-panel-transition-enter {
    transform: translateX(100%);
    transition: transform 500ms ease-in-out;
  }
  
  .friend-list-panel-transition-enter-active {
    transform: translateX(0);
  }
  
  .friend-list-panel-transition-exit {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;
  }
  
  .friend-list-panel-transition-exit-active {
    transform: translateX(100%);
  }
  
  .friend-list-panel-panel {
    pointer-events: auto;
    width: 100%;
    max-width: 28rem;
  }
  
  .friend-list-panel-panel-content {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    background-color: rgba(250, 250, 250, 0.5);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
  }
  
  .friend-list-panel-header {
    padding: 1.5rem;
  }
  
  .friend-list-panel-header-content {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  
  .friend-list-panel-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #1a202c;
  }
  
  .friend-list-panel-close-button-container {
    margin-left: 0.75rem;
    display: flex;
    height: 1.75rem;
    align-items: center;
  }
  
  .friend-list-panel-close-button {
    border-radius: 0.375rem;
    color: #a0aec0;
    transition: color 0.15s ease-in;
  }
  
  .friend-list-panel-close-button:hover {
    color: #718096;
  }
  
  .friend-list-panel-close-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
  }
  .friend-list-widget-wrapper {
    margin-bottom: 1rem;
  }
  
  .friend-list-widget-search {
    margin-bottom: 1.5rem;
  }
  
  .friend-list-widget-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .friend-list-widget-title {
    color: #2c4a2e;
    font-weight: 500;
  }

  .profile-page {
    background-color: black;
  }
  
  .profile-header {
    background-color: #36d300;
    height: 0.3rem;
  }
  
  
  .profile-avatar-wrapper {
   
    align-items: center;
    margin-right: 1rem;
  }
  
  .profile-avatar {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 4px solid white;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  
  
  .profile-name {
    font-size: 2rem;
    font-weight: bold;
    color: #48bb78;
  }
  
  .profile-handle {
    font-size: 0.875rem;
    color: #48bb78 !important;
  }
  
  .profile-followers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .profile-followers-add-button {
    padding-bottom: 1rem;
  }
  
  /* Profile Main Content */
  
  .profile-sidebar-left {
    order: 1;
    width: 100%;
    padding: 10px;
    border-radius: 0.375rem;
  }
  
  .profile-main-area {
    order: 2;
    flex-grow: 1;
    padding: 0.5rem;
  }
  
  .profile-sidebar-right {
    order: 3;
    width: 100%;
    padding: 10px;
    border-radius: 0.375rem;
  }
  
  @media (min-width: 1024px) {
  
    .profile-sidebar-left {
      order: 1;
      width: 25%;
    }
  
    .profile-main-area {
      order: 2;
      width: 40%;
    }
  
    .profile-sidebar-right {
      order: 3;
      width: 25%;
    }
  }
  
  

  /* UserProfileData.css */
.user-profile-data-content-container {
    padding: 20px;
  }
  
  .user-profile-data-tab-navigation {
    background-color: #fafafa1a !important;
    border: 1px solid #fafafa66 !important;
    border-radius: 10px !important;
    padding: 15px 25px !important;
  }
  
  .user-profile-data-tabs {
    display: flex;
    gap: 20px;
  }
  
  .user-profile-data-tab-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
  }
  
  .user-profile-data-tab-active {
    color: #22c55e;
    border-bottom-color: #22c55e;
  }
  
  .user-profile-data-tab-inactive {
    color: #6b7280;
  }
  
  .user-profile-data-tab-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
  
  .user-profile-data-tab-content {
    margin-top: 20px;
  }

  /* Shop Profile Data  */
.shop-profile-data-content-container {
    padding: 20px;
  }
  
  .shop-profile-data-tab-navigation {
    background-color: #fafafa1a !important;
    border: 1px solid #fafafa66 !important;
    border-radius: 10px !important;
    padding: 15px 25px !important;
  }
  
  .shop-profile-data-tabs {
    display: flex;
    gap: 20px;
  }
  
  .shop-profile-data-tab-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
  }
  
  .shop-profile-data-tab-active {
    color: #22c55e;
    border-bottom-color: #22c55e;
  }
  
  .shop-profile-data-tab-inactive {
    color: #ffffff;
  }
  
  .shop-profile-data-tab-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
  
  .shop-profile-data-events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 25px;
    margin-bottom: 12px;
  }
  
  .shop-profile-data-no-events,
  .shop-profile-data-no-reviews {
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 18px;
  }
  
  .shop-profile-data-review {
    display: flex;
    margin: 10px 0;
  }
  
  .shop-profile-data-review-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .shop-profile-data-review-content {
    padding-left: 10px;
  }
  
  .shop-profile-data-review-header {
    display: flex;
    align-items: center;
  }
  
  .shop-profile-data-review-user {
    font-weight: 600;
    padding-right: 10px;
  }
  
  .shop-profile-data-review-comment {
    font-weight: 400;
    color: #000000a7;
  }
  
  .shop-profile-data-review-date {
    color: #000000a7;
    font-size: 14px;
  }
  
  .shop-profile-data-dashboard-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    color: white;
    background-color: #4f46e5;
    text-decoration: none;
  }
  
  .shop-profile-data-dashboard-link:hover {
    background-color: #4338ca;
  }

  /* ShopInfoSidebar.css */
.shop-info-sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 10px;
    border-bottom: 1px solid #fff;
  }
  
  .shop-info-sidebar-top-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    /*background-color: black;*/
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 20px;
  }
  
  .shop-info-sidebar-avatar-container {
    position: relative;
    width: 150px;
    height: 150px;
  }
  
  .shop-info-sidebar-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .shop-info-sidebar-avatar-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #099910;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .shop-info-sidebar-avatar-input {
    display: none;
  }
  
  .shop-info-sidebar-avatar-label {
    color: white;
    font-size: 24px;
  }
  
  .shop-info-sidebar-name {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .shop-info-sidebar-follow-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .shop-info-sidebar-middle-row {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .shop-info-sidebar-description {
    font-size: 16px;
    color: #000000a6;
  }
  
  .shop-info-sidebar-detail {
    display: flex;
    flex-direction: column;
  }
  
  .shop-info-sidebar-detail h5 {
    font-size: 16px;
    font-weight: 600;
  }
  
  .shop-info-sidebar-detail h4 {
    font-size: 14px;
    color: #000000a6;
  }
  
  .shop-info-sidebar-bottom-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .shop-info-sidebar-bottom-row h5 {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  
  .shop-info-sidebar-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .shop-info-sidebar-action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #4f46e5;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
  }
  
  .shop-info-sidebar-action-button:hover {
    background-color: #4338ca;
  }
  
  
  
    /* Main Feed  */
  .main-feed-page {
    background-color: black;
    height: 100vh; 
    overflow: hidden; 
  }
  
  .main-feed-page-scrollable {
    overflow-y: auto;
    height: 85vh; 
  }
  
  
  .main-feed-page-scrollable::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
  }
  
  
  .main-feed-page-scrollable {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  
  .main-feed-page-main-content {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .main-feed-page-sticky-sidebar {
    height: 85vh;
    overflow-y: auto;
  }
  
  
    


  .Hero-all-products-container {
    position: relative;
    min-height: 70vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (min-width: 800px) {
    .Hero-all-products-container {
      min-height: 42vh;
    }
  }
  
  .Hero-all-products-section {
    width: 90%;
  }
  
  @media (min-width: 800px) {
    .Hero-all-products-section {
      width: 60%;
    }
  }
  
  .Hero-all-products-title {
    font-size: 35px;
    line-height: 1.2;
    color: #ffffff;
    font-weight: 600;
    text-transform: capitalize;
  }
  
  @media (min-width: 800px) {
    .Hero-all-products-title {
      font-size: 60px;
    }


.\38 00px\:my-\[20px\] {
        margin-bottom: 18px !important;
        margin-top: 20px;
    }
  }

  /* Link Post Preview*/

  .link-post-preview-container {
    background-color: rgba(0,0,0,0.5);
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    transition: transform 0.3s ease;
  }
  
  .link-post-preview-container:hover {
    transform: scale(1.02);
  }
  
  .link-post-preview-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .link-post-preview-content {
    padding: 1rem;
    background-color: rgba(0,0,0,0.5);
  }
  
  .link-post-preview-title {
    font-size: 1.2em;
    margin-bottom: 0.5rem;
    color: #fff;
  }
  
  .link-post-preview-description {
    font-size: 0.9em;
    color: #666666;
    margin-bottom: 0.5rem;
  }
  
  .link-post-preview-url {
    font-size: 0.9em;
    color: #1bab27;
    text-decoration: none;
    word-break: break-all;
    margin-top: 0.5rem;
  }
  

  .categories-dropdown-header-container {
    position: absolute;
    z-index: 50;
    margin-top: 1rem;
    top: 3.5rem;
    width: 100%;
    max-width: 100%;
    min-width: 690px;
    max-width: 1000px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.375rem;
    background-color: rgba(34, 34, 34, 0.8);
    border: 2px solid green;
  }
  
  .categories-dropdown-header-inner {
    display: flex;
  }
  
  .categories-dropdown-header-section {
    padding: 1rem;
  }
  
  .categories-dropdown-header-categories {
    flex-grow: 1;
    flex-basis: 40%;
  }
  
  .categories-dropdown-header-feature-products {
    flex-grow: 1;
    flex-basis: 60%;
  }
  
  .categories-dropdown-header-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: #36D300;
    margin-bottom: 1rem;
  }
  
  .categories-dropdown-header-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 0.375rem;
  }
  
  .categories-dropdown-header-item:hover {
    background-color: #36d300;
  }
  
  .categories-dropdown-header-item-image {
    width: 3rem;
    height: 3rem;
    border-radius: 0.125rem;
    object-fit: cover;
  }
  
  .categories-dropdown-header-item-text {
    font-size: 0.875rem;
    font-weight: 600;
  }
  
  .categories-dropdown-header-product {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    border-radius: 0.375rem;
  }
  
  .categories-dropdown-header-product:hover {
    background-color: black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .categories-dropdown-header-product-image {
    width: 150px;
    height: 150px;
    border-radius: 0.375rem;
    object-fit: cover;
  }
  
  .categories-dropdown-header-product-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: white;
  }
  
  .categories-dropdown-header-product-shop {
    font-size: 0.75rem;
    color: #36d300;
  }
  
  .categories-dropdown-header-featured-shop {
    padding: 1rem;
  }
  
  .categories-dropdown-header-featured-shop-inner {
    position: relative;
  }
  
  .categories-dropdown-header-featured-shop-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.375rem;
  }
  
  .categories-dropdown-header-featured-shop-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  
  .categories-dropdown-header-featured-shop-text {
    font-size: 1rem;
    font-weight: 600;
    color: white;
    text-align: center;
  }
  
  .home-page-content {
    padding-left: 120px;
    padding-right: 120px;
  }
  
  @media (min-width: 768px) {
    .home-page-content {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  
  @media (min-width: 1024px) {
    .home-page-content {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
  

.site-logo {
    width: 260px; 
    height: auto; 
  }
.reshare-container {
    margin: 10px 0;
  }
  
  .reshare-textarea {
    width: 100%;
    min-height: 80px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 10px; 
    background-color: rgba(0,0,0,0.5); 
    color: white; 
    resize: vertical; 
  }
  
  .reshare-textarea:focus {
    border-color: #36d300; 
  }
  
  .reshare-button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #026506;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reshare-button:hover {
    background-color: #14b506;
  }

  .cancel-button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #a0a0a0; /* A neutral grey color */
    color: white;
    cursor: pointer;
    margin-left: 10px; /* Optional: Add some space between the Share and Cancel buttons */
    transition: background-color 0.3s ease;
  }
  
  .cancel-button:hover {
    background-color: #808080; /* A darker shade of grey for hover effect */
  }
  
  .cart-popup {
    position: fixed;
    top: 98px !important; /* Added margin with !important */
    right: 0;
    height: calc(100% - 20px); /* Adjusted for the margin */
    width: 80%;
    background-color: #2a2a2ad1 !important;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06); /* Adjust shadow-sm as needed */
  }
  
  /* Media query for screens wider than 800px */
  @media (min-width: 800px) {
    .cart-popup {
      width: 25%;
    }
  }

   /* Comments */

   .comment-container {
    display: flex;
    align-items: start;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid rgba(0,0,0,0.5);
  }
  
  .comment-avatar-container {
    flex-shrink: 0;
  }
  
  .comment-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  
  .comment-content {
    flex: 1;
  }
  
  .comment-author {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
    color: #fff;
  }
  
  .comment-text-box {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.75rem;
    border-radius: 0.5rem;
  }
  
  .comment-edit-input {
    width: 100%;
    background-color: #424242;
    color: white;
    border: 1px solid rgba(0,0,0,0.5);
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  
  .comment-text {
    font-size: 0.875rem;
    color: white;
  }
  
  .comment-options-icon {
    height: 1.25rem;
    width: 1.25rem;
    cursor: pointer;
  }
  
  .comment-options-panel {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    background-color: rgba(250, 250, 250, 0.5);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }
  
  .comment-options-container {
    padding: 0.5rem;
  }
  
  .comment-option-button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: rgba(0,0,0,0.5);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .comment-option-button:hover {
    background-color: #f0f0f0;
  }
  
  .comment-save-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #38a169;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .comment-save-button:hover {
    background-color: #2f855a;
  }

/* reported-posts-admin.css */

.reported-posts-admin-container {
  display: flex;
}

.reported-posts-admin-sidebar {
  width: 250px;
}

.reported-posts-admin-content {
  flex: 1;
  padding: 24px;
  background-color: rgba(250, 250, 250, 0.2);
  color: white;
}

.reported-posts-admin-title {
  margin-bottom: 16px;
}

.reported-posts-admin-loader {
  display: block;
  margin: 0 auto;
}

.reported-posts-admin-table-container {
  overflow-x: auto;
}

.reported-posts-admin-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #444;
}

.reported-posts-admin-table-head {
  background-color: #555;
}

.reported-posts-admin-table-row {
  cursor: pointer;
}

.reported-posts-admin-table-cell,
.reported-posts-admin-table-body-cell {
  padding: 8px;
  border-bottom: 1px solid #666;
  color: white;
}

.reported-posts-admin-table-body-row-removed {
  background-color: red;
}

.reported-posts-admin-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px;
  max-height: 80vh;
  overflow: auto;
  z-index: 1000;
}

.reported-posts-admin-modal-box {
  position: relative;
  background-color: #222;
  color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px;
  max-height: 80vh;
  overflow: auto;
}

.reported-posts-admin-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reported-posts-admin-modal-title {
  margin-bottom: 8px;
}

.reported-posts-admin-modal-date {
  margin-bottom: 16px;
  color: gray;
}

.reported-posts-admin-modal-description {
  margin-bottom: 16px;
}

.reported-posts-admin-modal-images img,
.reported-posts-admin-modal-videos video {
  border-radius: 8px;
  margin-top: 8px;
}

.reported-posts-admin-modal-comments {
  margin-top: 16px;
}

.reported-posts-admin-view-profile-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.reported-posts-admin-cancel-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.39);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.reported-posts-admin-cancel-modal {
  width: 95%;
  max-width: 500px;
  min-height: 20vh;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 16px;
  position: relative;
}

.reported-posts-admin-cancel-confirm-button,
.reported-posts-admin-cancel-close-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}


  /* Cart sidebar*/

  .cart-sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.29);
    z-index: 10;
  }
  
  .cart-sidebar-popup {
    background: rgba(54, 54, 54, 0.7);
    width: 400px;
    max-width: 100%;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 10%;
    padding: 20px;
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .cart-sidebar-close-btn-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .cart-sidebar-close-btn {
    cursor: pointer;
  }
  
  .cart-sidebar-header {
    display: flex;
    align-items: center;
    padding: 16px 0;
  }
  
  .cart-sidebar-item-count {
    padding-left: 8px;
    font-size: 20px;
    font-weight: 500;
  }
  
  .cart-sidebar-items {
    border-top: 1px solid #ddd;
  }
  
  .cart-sidebar-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }
  
  .cart-sidebar-item {
    border-bottom: 1px solid #ddd;
    padding: 16px 0;
  }
  
  .cart-sidebar-item-content {
    display: flex;
    align-items: center;
  }
  
  .cart-sidebar-item-controls {
    display: flex;
    align-items: center;
  }
  
  .cart-sidebar-item-control {
    background: rgba(167, 171, 177, 0.31);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .cart-sidebar-item-quantity {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .cart-sidebar-item-image {
    width: 125px;
    height: auto;
    margin: 0 16px;
    border-radius: 5px;
  }
  
  .cart-sidebar-item-details {
    flex-grow: 1;
    padding-left: 8px;
  }
  
  .cart-sidebar-item-details h4 {
    margin: 4px 0;
    color: rgba(0, 0, 0, 0.51);
    font-weight: 400;
    font-size: 15px;
  }
  
  .cart-sidebar-item-total-price {
    font-weight: 600;
    font-size: 17px;
    color: #d02222;
  }
  
  .cart-sidebar-remove-item {
    cursor: pointer;
  }
  
  .cart-sidebar-checkout {
    padding: 16px 0;
  }
  
  .cart-sidebar-checkout-btn-disabled,
  .cart-sidebar-checkout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 100%;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
  
  .cart-sidebar-checkout-btn-disabled {
    background-color: grey;
    cursor: not-allowed;
  }
  
  .cart-sidebar-checkout-btn {
    background-color: #e44343;
    cursor: pointer;
    text-decoration: none;
  }
  
  .cart-sidebar-checkout-btn:hover {
    background-color: #d02222;
  }
  

  /* Sponsored Shops widget*/
  
  .sponsored-shops-widget-container {
    padding: 20px;
    background-color: rgba(250, 250, 250, 0.1);
    border: 1px solid rgba(250, 250, 250, 0.5);
    border-radius: 10px;
    margin: 10px auto;
    max-width: 1000px;
}

.sponsored-shops-widget-title {
    text-align: center;
    margin-bottom: 20px;
    color: white;
    font-size: 1em;
}

.sponsored-shops-widget-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sponsored-shops-widget-item {
    width: 100%;
    height: 240px;
    text-align: center;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background-color: #444;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.sponsored-shops-widget-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.sponsored-shops-widget-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.sponsored-shops-widget-banner-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.sponsored-shops-widget-banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s ease;
}

.sponsored-shops-widget-item:hover .sponsored-shops-widget-banner {
    filter: brightness(0.7);
}

.sponsored-shops-widget-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.sponsored-shops-widget-avatar {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 2px solid #fff;
}

.sponsored-shops-widget-name {
    font-size: 1.5em;
    margin: 0;
}

.sponsored-shops-widget-button {
    margin-top: 15px;
    padding: 10px 20px;
    border-radius: 10px;
    background: #36d300;
    color: white;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: background 0.3s ease;
}

.sponsored-shops-widget-button:hover {
    background: #138c1f;
}




  /* Sponsored brands widget*/

  .sponsored-brands-widget-container {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 15px;
    margin: 20px auto;
    max-width: 1200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sponsored-brands-widget-title {
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 1em;
    font-weight: 300;
    letter-spacing: 1px;
}

.sponsored-brands-widget-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.sponsored-brands-widget-item {
    width: 300px;
    text-align: center;
    margin: 10px;
    border: 1px solid #444;
    border-radius: 15px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.9);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.sponsored-brands-widget-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.sponsored-brands-widget-link {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
}

.sponsored-brands-widget-images {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.sponsored-brands-widget-avatar {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #1f9c29;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff;
}

.sponsored-brands-widget-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.sponsored-brands-widget-details {
    padding: 15px;
    background-color: rgba(250, 250, 250, 0.1);
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sponsored-brands-widget-name {
    font-size: 1.5em;
    margin: 10px 0;
    font-weight: 500;
}

.sponsored-brands-widget-button {
    padding: 10px 20px;
    border-radius: 5px;
    background: #1f9c29;
    color: white;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: background 0.3s ease, transform 0.3s ease;
}

.sponsored-brands-widget-button:hover {
    background: #138c1f;
    transform: scale(1.05);
}


  
  
  

/* Daily Ad Graph Button */
.daily-ad-graph-button {
    padding: 10px 20px;
    margin-right: 5px; /* Provide spacing between buttons */
    font-size: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    background-color: #4CAF50; /* Example color */
    color: white; /* Text color */
}

/* Hover and Active States for Daily Button */
.daily-ad-graph-button:hover, .daily-ad-graph-button:focus {
    background-color: #45a049; /* Darker shade for hover */
}

/* Hourly Ad Graph Button */
.hourly-ad-graph-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    background-color: #008CBA; /* Example color */
    color: white; /* Text color */
}

/* Hover and Active States for Hourly Button */
.hourly-ad-graph-button:hover, .hourly-ad-graph-button:focus {
    background-color: #007bb5; /* Darker shade for hover */
}

/* Disabled State for Both Buttons */
.daily-ad-graph-button:disabled,
.hourly-ad-graph-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    border-color: #bbbbbb;
}

/* Filter graph Buttons */
.filter-button {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #36d300;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.filter-button:hover {
    background-color: #36d300;
}

.filter-button:active {
    background-color: #004085;
    transform: translateY(2px);
}

.filter-button:focus {
    box-shadow: 0 0 0 3px rgba(0,123,255,.5);
}



/* Brand */

/* BrandCreate.module.css */
.brand-formContainer {
    max-width: 500px;
    margin: 2rem auto;
    padding: 1.5rem;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 8px;
    background: #f9f9f9;
  }
  
  .brand-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .brand-input,
  .brand-textarea,
  .brand-fileInput {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 4px;
  }
  
  .brand-textarea {
    height: 100px;
  }
  
  .brand-button {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: #36d300;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .brand-button:hover {
    background-color: #36d300;
  }

  .catalog-create-modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    background-color: #353535de;
    border: 2px solid #000;
    max-height: 90vh; 
    overflow-y: auto; 
    box-shadow: 24px;
    padding: 4px;
}
  

.locator-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .locator-modal-content {
    background-color: rgb(55, 55, 55);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .locator-button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #00ff2a;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .locator-button:hover {
    background-color: #36d300;
  }
  /* Shipping */
  .shipping-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #444444;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.region-select-field {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 4px;
}

.shipping-form label {
    display: block;
    color: rgba(0,0,0,0.5);
    margin-bottom: 5px;
}

.shipping-form input {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 4px;
    transition: border-color 0.3s;
}

.shipping-form input:focus {
    border-color: #4CAF50; 
}

.shipping-form button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #4CAF50; 
    cursor: pointer;
    transition: background-color 0.3s;
}

.shipping-form button:hover {
    background-color: #45a049; 
}

.shipping-form button[type="button"] {
    background-color: #888; 
}

.shipping-form button[type="button"]:hover {
    background-color: #666; 
}

.shipping-container {
    padding: 20px;
    background-color: #323232;
    min-height: 100vh;
    min-width: 80vw;
}

.shipping-rules-list {
    margin-top: 20px;
    border-top: 1px solid #e1e1e1;
    padding-top: 20px;
}

.shipping-rule-item {
    display: flex;
    flex-direction: column;
    background-color: #444444;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 10px;
    padding: 10px;
}

.shipping-rule-item > div {
    margin-bottom: 5px;
}

.shipping-rate-conditions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    background-color: #444444;
    border-radius: 5px;
}

.shipping-rate-conditions div {
    margin: 0 10px;
}

.shipping-rate-conditions h4, .shipping-rate-conditions h5 {
    margin-top: 0;
}

.rule-actions {
    display: flex;
    justify-content: flex-end;
}

.rule-actions button {
    margin-left: 10px;
}


.add-rule-btn, .edit-rule-btn, .delete-rule-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-rule-btn {
    background-color: #5c67f2;
}

.edit-rule-btn {
    background-color: #4CAF50;
}

.delete-rule-btn {
    background-color: #f44336;
}

.add-rule-btn:hover {
    background-color: #4a52d1;
}

.edit-rule-btn:hover, .delete-rule-btn:hover {
    opacity: 0.9;
}

.region-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 8px;
    max-width: 400px;
    margin: auto;
}

.region-form input[type="text"], .region-form button, .region-form .select__control {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    background-color: #323232;
}

/* Styling for React Select */
.region-form .select__control {
    display: flex;
    border-color: #ccc;
    box-shadow: none; /* Removes focus shadow */
    background-color: #323232;
}

.region-form .select__control--is-focused {
    border-color: #36d300;
    box-shadow: none; /* Consistent focus styling */
}

.region-form .select__menu {
    background-color: #535353;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.region-form .select__option--is-focused {
    background-color: #323232;
    color: white;
}

.region-form .select__option--is-selected {
    background-color: #323232;
    color: white;
}

.region-form .select__multi-value {
    background-color: #323232;
    color: white;
}

.region-form .select__multi-value__remove:hover {
    background-color: #323232;
    color: white;
}

.region-form button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.region-form button.cancel-button {
    background-color: #f44336;
}

.region-form input[type="text"]:focus {
    border-color: #36d300;
    outline: none;
}

.region-form button:hover {
    opacity: 0.9;
}


.regions-container {
    padding: 20px;
    background: rgba(250, 250, 250, 0.1);
    border-radius: 8px;
    min-width: 80vw;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.region-list {
    list-style: none;
    padding: 0;
}

.region-item {
    background: rgba(250, 250, 250, 0.2);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.region-actions {
    display: flex;
    gap: 10px;
}

.regions-button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.regions-button-edit {
    background-color: #36d300;
    color: white;
}

.regions-button-edit:hover {
    background-color: #36d300;
}

.regions-button-delete {
    background-color: #dc3545;
    color: white;
}

.regions-button-delete:hover {
    background-color: #c82333;
}

.regions-button-add {
    background-color: rgba(250, 250, 250, 0.1);
    color: white;
}

.regions-button-add:hover {
    background-color: #5a6268;
}

/* subscription checkout section */
.subscription-details {
    background-color: #015e07; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    margin-top: 20px;
  }
  
  .subscription-details h2 {
    color: #ffffff; 
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .subscription-details p {
    color: #ffffff; 
    font-size: 16px;
    line-height: 1.5;
  }

  .change-subscription-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.change-subscription-modal-content {
    background-color: #3b3b3b;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
}

.change-subscription-close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.change-subscription-close-btn:hover,
.change-subscription-close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.change-subscription-plan {
    padding: 10px;
    cursor: pointer;
}

.change-subscription-plan-current {
    background-color: lightgreen;
}

.upgrade-plan-btn {
    background-color: #4A90E2; 
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .upgrade-plan-btn:hover {
    background-color: #357ABD; 
  }

  .user-subscriptions-overview {
    padding: 20px;
    background-color: rgba(0,0,0,0.5);
}

.user-subscriptions-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.user-subscriptions-loading,
.user-subscriptions-error {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
}

.user-subscriptions-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.user-subscriptions-modal {
    width: 300px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
}

.user-subscriptions-confirm-btn,
.user-subscriptions-cancel-btn {
    margin-right: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
}

.user-subscriptions-cancel-btn {
    background-color: #f44336;
}

.user-subscriptions-list {
    list-style-type: none;
    padding: 0;
}

.user-subscriptions-item {
    background-color: #fff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: background-color 0.3s;
}

.user-subscriptions-item-active {
    background-color: #e6ffe6;
}

.user-subscriptions-detail {
    margin: 5px 0;
}

.user-subscriptions-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.user-subscriptions-empty {
    text-align: center;
    margin-top: 20px;
}

.user-subscriptions-view-plans-btn {
    background-color: #36d300;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

/* Shop subscriptions overview */

/* General Styles */
.shop-subscriptions-overview-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shop-subscriptions-overview-container h2 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
}

/* Modal Styles */
.shop-subscriptions-overview-modal-overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.shop-subscriptions-overview-modal-content {
  width: 300px;
  background-color: rgba(250, 250, 250, 0.2); 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  text-align: center; 
}

.shop-subscriptions-overview-modal-confirm-btn,
.shop-subscriptions-overview-modal-cancel-btn {
  margin-top: 15px;
  background-color: #36d300;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
}

.shop-subscriptions-overview-modal-cancel-btn {
  background-color: #f44336;
}

/* Subscription List Styles */
.shop-subscriptions-overview-list {
  list-style-type: none;
  padding: 0;
}

.shop-subscriptions-overview-item {
  background-color: rgba(250, 250, 250, 0.1);
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s;
}

.shop-subscriptions-overview-item-active {
  background-color: rgba(250, 250, 250, 0.1);
}

.shop-subscriptions-overview-detail {
  margin: 5px 0;
}

.shop-subscriptions-overview-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.shop-subscriptions-overview-cancel-btn {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
}
.shop-change-subscription-upgrade-plan-btn {
  background-color: #36d000;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
}

.shop-subscriptions-overview-empty {
  text-align: center;
  margin-top: 20px;
}

.shop-subscriptions-overview-view-plans-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

/* Loading and Error Styles */
.shop-subscriptions-overview-loading {
  text-align: center;
  font-size: 18px;
  color: #333;
}

.shop-subscriptions-overview-error-message {
  text-align: center;
  color: #f44336;
  font-size: 18px;
}


/* ShopPaymentSettingsPage.css */
.shop-onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: rgba(0,0,0,0.5);
    padding: 20px;
  }
  
  .shop-onboarding-banner {
    background-color: #36d300;
    color: white;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
    text-align: center;
    font-size: 30px;
  }
  
  .shop-onboarding-content {
    background-color: rgba(250, 250, 250, 0.1);
    border: 1px solid rgba(250, 250, 250, 0.4);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
  }
  
  .shop-onboarding-onboarding-container {
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
  .shop-onboarding-error {
    color: red;
    margin-top: 20px;
  }
  

/* Container for payment method details and button */
.save-payment-method-container {
  text-align: center;
  padding: 20px;
}

/* Styling for payment details */
.save-payment-method-details h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.save-payment-method-details p {
  font-size: 1rem;
  margin-bottom: 5px;
}

.save-payment-method-open-btn {
  background-color: #36D300;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-payment-method-open-btn:hover {
  background-color: #0056b3;
}

/* Modal styling */
.save-payment-method-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.save-payment-method-modal-content {
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  color: #fff;
  padding: 20px;
  border-radius: 20px;
  width: 400px;
  text-align: center;
  position: relative;
}

.save-payment-method-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}

.save-payment-method-close-btn:hover {
  color: #ff0000;
}

/* Form styling */
.save-payment-method-form {
  margin-top: 20px;
}

.save-payment-method-card-element {
  border: 1px solid rgba(250, 250, 250, 0.4);
  background-color: rgba(250, 250, 250, 0.2);
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.save-payment-method-submit-btn {
  background-color: #36D300;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.save-payment-method-submit-btn:disabled {
  background-color: #6c757d;
}


.boost-animation-container {
    position: fixed; 
    top: 50%;       
    left: 50%;      
    transform: translate(-50%, -50%); 
    z-index: 1000;  
    width: 100%;    
    height: 100%;   
    display: flex;  
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}
 /* Tag manager */

 .admin-tag-management {
    padding: 20px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 8px;
    width: 42vw;
    max-width: 80vw;
    margin: auto;
}

.admin-heading {
    text-align: center;
    color: rgba(0,0,0,0.5);
}

.tag-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.tag-input {
    padding: 8px 12px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    background-color: rgba(0,0,0,0.5);
    border-radius: 4px;
}

.tagbtn {
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}

.tagbtn-add-tag {
    background-color: #36d300;
    color: white;
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tagbtn-add-tag:hover {
    background-color: #36d300;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

.tagbtn-add-tag:active {
    background-color: #004085;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
}

.tagbtn-add-tag:focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}


.tagbtn-approve {
    background-color: #28a745;
    color: white;
}

.tagbtn-delete {
    background-color: #dc3545;
    color: white;
}

.loading-text {
    text-align: center;
}

.tag-list {
    list-style-type: none;
    padding: 0;
    background-color: rgba(0,0,0,0.5)
}

.tag-item {
    background-color: rgba(0,0,0,0.5);
    padding: 6px;
    border-radius: 4px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tag-name {
    display: inline-flex;
    align-items: center;
    gap: 2px; 
    border-radius: 0.375rem; 
    background-color: #38a169; 
    padding: 8px;
    font-size: 0.75rem; 
    font-weight: 500;
    color: white;
    box-shadow: inset 0 0 0 1px rgba(107, 114, 128, 0.1);
  }
  

.tag-info {
    flex-grow: 1;
}

.tag-selector .tag {
    margin: 5px;
    padding: 5px 10px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    background-color: #f9f9f9;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .tag-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #36d300;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 999px; /* Highly rounded corners */
    margin: 5px;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    cursor: default;
  }
  
  .tag-badge button {
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .tag-badge button:hover {
    color: #ccc;
  }
  
  .tag-selector .tag-dropdown {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 10px;
    background-color: #636363;
    border:  1px solid #ccc;
    border-radius: 5px;
  }
  
  .tag-selector input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .tag-selector button {
    padding: 0.5rem;
    width: 100%;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .tag-selector button:hover {
    background-color: #218838;
  }
  
  .tag-badge.approved {
    background-color: green;
  }
  
  .tag-badge.unapproved {
    background-color: red;
  }
  
  .remove-tag-button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    padding-left: 8px;
  }
  
  .remove-tag-button:hover {
    color: red; 
  }

  .tag-custom-input {
    width: 100%; 
    padding: 8px 12px; 
    margin: 10px 0; 
    box-sizing: border-box; 
    border: 2px solid #ccc; 
    border-radius: 4px; 
    background-color: rgba(0,0,0,0.5); 
    font-size: 16px;
    color: #fff; 
}

.tag-custom-input:focus {
    border-color: green; 
    outline: none; 
    box-shadow: 0 0 8px rgba(44, 204, 0, 0.533); 
}
/* Coupon code form*/

.coupon-code-create-form {
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .coupon-code-create-form__form {
    display: flex;
    flex-direction: column;
  }
  
  .coupon-code-create-form__input-group {
    margin-bottom: 1rem;
  }
  
  .coupon-code-create-form__input-group label {
    font-weight: bold;
  }
  
  .coupon-code-create-form__input-group input,
  .coupon-code-create-form__input-group select,
  .coupon-code-create-form__input-group .basic-multi-select {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid rgba(250, 250, 250, 0.4);
  }
  
  .coupon-code-create-form__input-group input:focus,
  .coupon-code-create-form__input-group select:focus,
  .coupon-code-create-form__input-group .basic-multi-select:focus {
    border-color: #36d300;
    outline: none;
  }
  
  .coupon-code-create-form__input-group input[type="submit"] {
    background-color: #36d300;
    color: white;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .coupon-code-create-form__input-group input[type="submit"]:hover {
    background-color: #36d300;
  }

  /* Hashtag Suggestions */
.hashtag-suggestions-container {
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    width: 50%;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 0.5rem; 
    margin-left: 5rem;
  }
  
  .hashtag-suggestions-loading {
    padding: 8px;
    text-align: center;
    color: #888;
  }
  
  .hashtag-suggestion-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .hashtag-suggestion-item:hover {
    background-color: #11c54a;
  }
  
  .hashtag-suggestion-item .MuiTypography-root {
    color: #1f9c29;
    font-weight: bold;
  }
  
  
/* Suggested Friend Card */

.friend-card {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    padding: 10px;
    transition: transform 0.2s, box-shadow 0.2s;
    width: 180px;
}


.friend-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.friend-card-image img {
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-radius: 10px !important;
    height: 120px;
    object-fit: cover;
    width: 100%;
}


.friend-card-content {
    padding: 12px;
    text-align: center;
}

.friend-card-name {
    margin: 0;
    font-size: 18px;
    color: #fff;
}

.friend-card-actions {
    display: flex;
    justify-content: center;
    padding: 5px;
}

.add-friend-button-button {
    background-color: #36d300 !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    padding-bottom: 1rem;
    color: #fff !important;
    gap: 8px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    justify-content: center;
    margin-top: 10px;
}
.recommended-friends-container {
    margin: 0 auto;
    max-width: 85vw;
    padding: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.recommended-friends-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: rgba(0,0,0,0.5);
}

.suggested-friend-list {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    padding: 20px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    justify-content: center;
}

.suggested-friend-list::-webkit-scrollbar {
    height: 8px;
}

.suggested-friend-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

/* Homepage hero slider */
.homepage-hero-slider {
    position: relative;
    height: 50vh;
  }
  
  .homepage-hero-slide {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    transition: opacity 0.5s ease-in-out;
  }
  
  .homepage-hero-slide-active {
    opacity: 1;
  }
  
  .homepage-hero-slide-inactive {
    opacity: 0;
  }
  
  .homepage-hero-slide-content {
    height: 100%;
    padding: 6em;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .homepage-hero-slide-title {
    font-size: 7rem;
    font-weight: bold;
  }
  
  .homepage-hero-slide-button {
    margin-top: 1rem;
    display: inline-block;
    background-color: #22c55e;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .homepage-hero-slide-button:hover {
    background-color: #15803d;
  }
  
  .homepage-hero-slider-button {
    position: absolute;
    top: 50%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 0.75rem;
    cursor: pointer;
    transform: translateY(-50%);
  }
  
  .homepage-hero-slider-button-left {
    left: 1rem;
  }
  
  .homepage-hero-slider-button-right {
    right: 1rem;
  }
  
  .homepage-hero-icon {
    height: 1.5rem;
    width: 1.5rem;
  }

/* Homepage category overview */
  .homepage-category-component-section {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .homepage-category-component-branding {
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: rgba(0,0,0,0.5);
    padding: 1.25rem;
    border-radius: 0.375rem;
  }
  
  .homepage-category-component-branding-item {
    display: flex;
    align-items: start;
  }
  
  .homepage-category-component-branding-text {
    padding-left: 0.75rem;
  }
  
  .homepage-category-component-branding-title {
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
  }
  
  .homepage-category-component-branding-description {
    font-size: 0.875rem;
    color: #666;
  }
  
  .homepage-category-component-categories-section {
    background-color: rgba(0,0,0,0.5);
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 3rem;
  }
  
  .homepage-category-component-categories-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
  }
  
  @media (min-width: 768px) {
    .homepage-category-component-categories-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
  
  @media (min-width: 1024px) {
    .homepage-category-component-categories-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }
  }
  
  @media (min-width: 1280px) {
    .homepage-category-component-categories-grid {
      grid-template-columns: repeat(5, 1fr);
      gap: 25px;
    }

    .xl\:px-8 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .lg\:px-8 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    }

    .lg\:mx-auto {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }

    .text-white.font-bold {
    font-size: 20px !important;
    }

	  /*----EDITS FOR WIDTHS TEST----*/
    .xl\:mx-auto {
        margin-left: 7% !important;
        margin-right: auto !important;
    }

    .xl\:px-8 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .py-16.sm\:py-24.xl\:mx-auto.xl\:max-w-7xl.xl\:px-8 {
    margin: 0px 7% !important;
}

    .lg\:px-8 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

        .lg\:mx-auto {
        margin-left: 7% !important;
        margin-right: 7% !important;
    }
    .xl\:max-w-7xl {
        max-width: 100% !important;
    }
  }
  
  .homepage-category-component-category-item {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .homepage-category-component-category-item:hover {
    background-color: #36d300;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .homepage-category-component-category-title {
    font-size: 1.125rem;
    line-height: 1.3;
    color: #fff;
  }
  
  .homepage-category-component-category-image {
    width: 120px;
    height: 100%;
    object-fit: cover;
  }

  .best-deals-homepage-container {
    width: 100%;
    padding: 0 20px;
  }
  
  .best-deals-homepage-section {
    width: 83.33%;
    margin: 0 auto;
  }
  
  .best-deals-homepage-heading {
    text-align: center;
    font-size: 27px;
    color: #fff;
    font-weight: 600;
    font-family: Roboto, sans-serif;
    padding-bottom: 20px;
  }
  
  .best-deals-homepage-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 3rem;
  }
  
  @media (min-width: 768px) {
    .best-deals-homepage-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
    }
  }
  
  @media (min-width: 1024px) {
    .best-deals-homepage-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 25px;
    }
  }
  
  @media (min-width: 1280px) {
    .best-deals-homepage-grid {
      grid-template-columns: repeat(5, 1fr);
      gap: 25px;
    }
  }
  
  
  


 /* Responsive design for smaller screens */
 @media (max-width: 768px) {
    .subscription-details {
      background-color: #015e07; 
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
  }

/* Styling for the list containing subscription details */
.user-subscriptions-list {
    list-style: none; 
    padding: 0;
    margin: 0;
}

.user-subscriptions-item {
    background-color: #3f3f3f; 
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px; 
    border-radius: 5px; 
}

.user-subscriptions-detail {
    font-size: 16px;
    color: #ffffff; 
    margin: 5px 0; 
}

.user-subscriptions-cancel-btn {
    background-color: #f44336; 
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    display: inline-block; 
    margin-top: 10px; 
}

.user-subscriptions-cancel-btn:hover {
    background-color: #d32f2f; 
}

/* Additional styling for active subscription management */
.active-subscription-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px; 
}

.active-subscription-button {
    background-color: #4CAF50; 
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
}

.active-subscription-button:hover {
    background-color: #388E3C; 
}

/* Order Confirmation page */

.order-confirmation-container {
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 20px;
    background-color: rgba(0,0,0,0.5); 
  }
  
  .order-confirmation-side-image {
    flex: 1;
    max-width: 40%;
  }
  
  .order-confirmation-hero-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .order-confirmation-details {
    flex: 2;
    padding: 20px;
    max-width: 60%;
  }
  
  .order-confirmation-title {
    font-size: 2.5em;
    color: #ffffff;
  }
  
  .order-confirmation-id {
    margin-top: 10px;
    font-size: 1.5em;
    color: #e0e0e0;
  }
  
  .order-confirmation-customer {
    color: #488bf0;
  }
  
  .order-confirmation-product-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #e2e8f0;
    padding: 20px 0;
  }
  
  .order-confirmation-product-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .order-confirmation-product-info {
    flex-grow: 1;
  }
  
  .order-confirmation-shipping {
    margin-top: 20px;
  }
  
  .order-confirmation-loading {
    text-align: center;
    font-size: 18px;
    color: #4a5568;
  }

  .order-confirmation-payment-info {
    background-color: #c4c4c4; 
    border-top: 1px solid #e5e7eb; 
    padding: 20px; 
    margin-top: 20px; 
    border-radius: 8px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.order-confirmation-payment-info h3 {
    color: #1a202c; 
    font-size: 18px; 
    margin-bottom: 10px; 
    font-weight: 600; 
}

.order-confirmation-payment-info p {
    color: #4a5568; 
    font-size: 16px; 
    margin-bottom: 5px; 
    line-height: 1.6; 
}
.order-confirmation-animation {
    text-align: center;
    margin-bottom: 20px;
  }

.continue-shopping-button {
    background-color: #36d300;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
  }
  
  .continue-shopping-button:hover {
    background-color: #36d300;
  } 

  /* Shop Orders list */
  
  .shop-all-orders-container {
    background-color: rgba(0,0,0,0.5)333;
    padding: 16px;
    margin-top: 20px;
    min-height: 100vh;
    width: 80vw;
    border-radius: 6px;
    color: #F7FAFC;
}

.shop-all-orders-header {
    width: 100%;
    margin: auto;
    padding: 16px;
}

.shop-all-orders-title {
    font-size: 24px;
    font-weight: bold;
    color: #F7FAFC;
}

.shop-all-orders-table {
    margin-top: 32px;
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    color: #F7FAFC;
    background-color: rgba(250, 250, 250, 0.1);
}

.table th,
.table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #2D3748;
}

.table th {
    background-color: rgba(250, 250, 250, 0.1);
    color: #F7FAFC;
}

.table .greenColor {
    color: green;
}

.table .redColor {
    color: red;
}

.table .yellowColor {
    color: yellow;
}

.shop-all-orders-pending-row {
    background-color: rgba(250, 250, 250, 0.2);
}

.shop-all-orders-actions {
    text-align: center;
}

.shop-all-orders-actions a {
    color: #146e10;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}


  /* Shop Purchases list */
  .shop-purchases-container {
    width: 100%;
    padding: 16px;
    margin-top: 20px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 6px;
    color: #F7FAFC;
  }
  
  .shop-purchases-title {
    font-size: 24px;
    font-weight: bold;
    color: #F7FAFC;
    margin-bottom: 16px;
  }
  
  .shop-purchases-status-delivered {
    color: green;
  }
  
  .shop-purchases-status-pending {
    color: red;
  }
  
  .shop-purchases-button {
    color: #F7FAFC;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .shop-purchases-button:hover {
    color: #1e7e34;
  }
  
  .shop-purchases-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .shop-purchases-table th,
  .shop-purchases-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #2D3748;
  }
  
  .shop-purchases-table th {
    background-color: #146e10;
    color: #F7FAFC;
  }
  
  .shop-purchases-table tr:hover {
    background-color: #555;
  }
  
  

  

/* CSS Document */

.bg-gray-900 {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.text-green-600 {
    --tw-text-opacity: .2;
    color: rgb(22 163 74/var(--tw-text-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity)) !important;
    color: #ffffff !important;
	background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-white {
    --tw-bg-opacity: .2;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgb(250, 250, 250, 0.2) !important;
    color: #ffffff !important;
}

.text {
    color: #ffffff !important;
}

h4 {
    color: #ffffff !important;
}

.lg\:block {
    display: block;
    width: 25% !important;
}

.flex {
    display: flex;
}

.xl\:block {
    display: block;
    width: 25% !important;
    vertical-align: top !important;
}

.xl\:gap-\[20px\] {
    gap: 20px;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-radius: 10px !important;
    padding: 15px !important;
}

.css-1ug174f {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}

.top-24 {
    top: 0 !important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: .7rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(250, 250, 250);
    font-weight: 500;
}

.css-1pqb849 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(250, 250, 250) !important;
}

.css-zevtcw {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(70, 211, 0) !important;
}

.buzzweed-green {
    color: rgb(70, 211, 0) !important;
}

.css-pg6ig3 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(70, 211, 0) !important;
    margin: 0.5rem 0px;
}

.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    /* border-bottom: rgb(70, 211, 0) !important; */
}

.text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74/var(--tw-text-opacity));
    background-color: rgba(250, 250, 250, 0.2);
}

.cart-icon {
    color: rgb(250, 250, 250) !important;
}

.text-\[\#68d284\] {
    --tw-text-opacity: 1;
    color: rgb(250 250 250/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    border-bottom: 3px solid rgb(70, 211, 0) !important;
}

.text-sm {
    font-size: 1.0rem !important;
    line-height: 1.25rem;
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 {
    color: rgb(70, 211, 0) !important;
    border-bottom: none !important;
}

.border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74/var(--tw-border-opacity));
    border-color: rgb(70, 211, 0) !important;
}

.bg-\[\#3bc177\] {
    --tw-bg-opacity: 1;
    background-color: rgb(59 193 119/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

svg.absolute.right-2.top-4.cursor-pointer {
    color: rgb(250, 250, 250) !important;
}

path {
    color: rgb(250, 250, 250) !important;
}

.border-\[\#3957db\] {
    --tw-border-opacity: 1;
    border-color: rgb(57 87 219/var(--tw-border-opacity));
    border-radius: 50px !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
}

.border-\[\#3957db\] {
    --tw-border-opacity: 1;
    border-color: rgb(57 87 219/var(--tw-border-opacity));
    border-radius: 50px !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
    color: rgb(250, 250, 250) !important;
}

path {
    color: rgba(250, 250, 250, 0.5) !important;
}

svg.absolute.right-2.top-1\.5.cursor-pointer {
    width: 25px !important;
    height: auto !important;
}

.bg-\[\#fff\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -25px !important;
}

h3.m-3.cursor-pointer.select-none {
    color: rgb(250, 250, 250) !important;
}

.pb-4.w-\[270px\].bg-\[\#fff\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.5) !important;
}

.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}



/*--------------2nd Update--------------*/

/* Comment Box */
.css-qiux0o {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.87) !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 2rem;
    padding: 1rem 2rem;
}

/* Post Button */

.css-cda2fy.Mui-disabled {
    color: rgba(250, 250, 250, 1.0);
}

.css-cda2fy {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(40, 158, 49);
    background-color: rgb(70, 211, 49) !important;
    border-radius: 3rem;
}

/* Add Friend Button */
.css-ozo1r7 {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    color: rgb(250, 250, 250) !important;
}

.css-11gap41 {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    border-radius: 50%;
    overflow: visible;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(70, 211, 0) !important;
    padding: 0.6rem;
}

/* Posted Content Blocks */

.css-1l1ah9e {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 0.75rem;
}

body {
    color: rgb(250, 250, 250) !important;
}

.css-1ug174f {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.top-8 {
    top: 0 !important;
}

.text-\[\#3a24db\] {
    --tw-text-opacity: 1;
    color: rgb(58 36 219/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.border-\[\#3a24db\] {
    --tw-border-opacity: 1;
    border-color: rgb(58 36 219/var(--tw-border-opacity));
    border: none !important;
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
}

.rounded-\[5px\] {
    border-radius: 50px !important;
}

input.w-full.border.p-1.rounded-\[5px\].\!w-\[95\%\].mb-4.\38 00px\:mb-0 {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: none !important;
    padding: 10px !important;
}

input.w-full.border.p-1.rounded-\[5px\].\!w-\[95\%\].mb-1.\38 00px\:mb-0 {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: none !important;
    padding: 10px !important;
}

.bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
}

.text-\[red\] {
    --tw-text-opacity: 1;
    color: rgb(255 0 0/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    font-weight: 500 !important;
}

.css-1rt5dsz {
    margin: 0px 0px 1.5rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgba(250, 250, 250, 0.7) !important;
    font-weight: 500;
}

label.block.pb-2 {
    color: rgba(250, 250, 250, 0.7) !important;
}



/*---------UPDATE 3--------*/

.css-1fzwgfs {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.7) !important;
}

.css-76kev2 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.7) !important;
}

.w-\[35px\] {
    width: 35px;
    height: auto;
}




/*------------Update 4----------------*/

.w-\[90\%\].\38 00px\:w-\[60\%\].h-\[90vh\].overflow-y-scroll.\38 00px\:h-\[75vh\].bg-white.rounded-md.shadow-sm.relative.p-4 {
    background-color: rgba(0,0,0,0.8) !important;
    padding: 25px !important;
    color: rgb(250, 250, 250) !important;
}

.w-full.\38 00px\:w-\[50\%\].pt-5.pl-\[5px\].pr-\[5px\] {
    padding: 25px !important;
}

aside.sticky.top-24.hidden.w-44.shrink-0.lg\:block.bg-\[\#f5f5f5\].p-2.rounded-lg {
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    background-color: rgba(0, 0, 0, 0.8) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-10yzw17 {
    gap: 0.25rem;
    display: inline-block !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: 22%;
}

.css-cda2fy.Mui-disabled {
    color: rgba(250, 250, 250, 1.0) !important;
}

.css-1sb2j9v {
    gap: 1rem;
    display: inline-block;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.css-11gap41 {
    display: inline-block;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    border-radius: 50%;
    overflow: visible;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(27, 242, 73);
    padding: 0.6rem;
    position: relative;
    width: 45px !important;
    height: 45px !important;
    float: right;
}

.css-1sb2j9v {
    gap: 1rem;
    display: inline-block;
    position: relative !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    /* float: left; */
}

.css-1g4v7g4 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(250, 250, 250) !important;
    font-weight: 500;
}

.border-\[2px\] {
    border-width: 1px !important;
}

.css-qiux0o {
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-ei7csf {
    width: 45px !important;
    height: auto !important;
    color: rgb(250, 250, 250) !important;
    padding-top: 15px !important;
}

hr.MuiDivider-root.MuiDivider-fullWidth.css-1drwh4k {
    margin-top: 25px !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
}


/*-------Update 5----------*/

button.text-gray-500.border-transparent.hover\:text-gray-700.whitespace-nowrap.py-4.px-1.border-b-2.font-medium.text-sm {
    text-align: center !important;
    margin: auto !important;
}

input.MuiInputBase-input.css-mnn31 {
    background: transparent !important;
}

.MuiBox-root.css-dlj3h8 {
    display: inline-block !important;
}

.md\:flex.md\:justify-between.md\:items-center.sm\:px-12.px-4.bg-green-700.py-7 {
    border-radius: 0 !important;
}

.md\:flex.md\:justify-between.md\:items-center.sm\:px-12.px-4.bg-green-700.py-7 {
    border-radius: 0 !important;
    background-color: rgba(250, 250, 250, 0.3) !important;
}

.bg-\[\#56d879\] {
    --tw-bg-opacity: 1;
    background-color: rgb(86 216 121/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

.text-\[\#56d879\] {
    --tw-text-opacity: 1;
    color: rgb(86 216 121/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
}

input.text-gray-800.sm\:w-72.w-full.sm\:mr-5.mr-1.lg\:mb-0.mb-4.py-2\.5.rounded.px-2.focus\:outline-none {
    border-radius: 50px !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding-left: 25px !important;
}

input.text-gray-800.sm\:w-72.w-full.sm\:mr-5.mr-1.lg\:mb-0.mb-4.py-2\.5.rounded.px-2.focus\:outline-none {
    border-radius: 50px !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding-left: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

button.bg-\[\#56d879\].hover\:bg-teal-500.duration-300.px-5.py-2\.5.rounded-md.text-whie.md\:w-auto.w-full {
    border-radius: 50px !important;
}

input.h-\[40px\].w-full.px-2.border-\[\#3957db\].border-\[2px\].rounded-md {
    padding-left: 25px !important;
}

h5.pt-3.text-\[15px\].text-blue-400.pb-3 {
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
    margin-top: 15px !important;
}

.bg-\[\#f5f6fb\].px-3.\38 00px\:px-10.py-2.rounded {
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
    border-radius: 15px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.text-\[\#000\] {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

svg.absolute.right-2.top-1\.5.cursor-pointer {
    width: 25px !important;
    height: auto !important;
    margin-right: 10px !important;
}

.pb-4.w-\[270px\].bg-\[\#fff\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}

.bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61/var(--tw-bg-opacity));
    border-radius: 50px !important;
}

button.bg-gradient-to-r.from-teal-400.to-teal-500.text-white.font-bold.rounded-l.px-4.py-2.shadow-lg.hover\:opacity-75.transition.duration-300.ease-in-out {
    border-radius: 0px !important;
    /* background-color: rgb(0,0,0,0.7) !important; */
    border: 1px solid rgb(70, 211, 0) !important;
}

.text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
    font-size: 20px !important;
    text-transform: capitalize !important;
}

.css-5vij8j {
    color: rgb(250, 250, 250) !important;
}


/*------Update 6----------*/

.bg-white {
    --tw-bg-opacity: .2;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgb(250, 250, 250, 0.2) !important;
    color: #ffffff !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -29px !important;
}

.MuiBox-root.css-1ug174f {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.w-\[50\%\].relative {
    width: 25% !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-ei7csf {
    width: 25px !important;
    height: auto !important;
    color: rgb(250, 250, 250) !important;
    padding-top: 15px !important;
    text-align: center !important;
}

aside.sticky.top-24.hidden.w-44.shrink-0.lg\:block.bg-\[\#f5f5f5\].p-2.rounded-lg {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-1l1ah9e {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-gray-900 {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-left: 0px !important;
}

.share-button {
    align-items: center;
    border-radius: 50px;
    display: flex;
    font-size: 18px !important;
    padding: 20px;
    text-decoration: none;
    transition: background-color .3s ease;
    width: 45px !important;
    height: 45px !important;
    text-align: center !important;
    margin-top: 25px !important;
}

h4.text-\[\#000000a6\] {
    text-transform: capitalize !important;
}

.md\:justify-between {
    justify-content: space-evenly !important;
}

h1.lg\:text-4xl.text-3xl.md\:mb-0.mb-6.lg\:leading-normal.font-semibold.md\:w-2\/5 {
    float: right !important;
    display: inline-block !important;
    position: relative !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
    text-align: right !important;
}

/*Profile Pic*/
img.w-\[150px\].h-\[150px\].object-cover.rounded-full {
    border: 2px solid rgba(2500, 250, 250, 1.0) !important;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-11gap41 {
    display: none !important;
}

/*.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10.sm\:pl-16 {
    top: 100px !important;
}*/

.bg-\[\#E3E9EE\] {
    --tw-bg-opacity: .7 !important;
    background-color: rgb(0 0 0/var(--tw-bg-opacity)) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -29px!important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.w-\[42\%\] {
    width: 25%;
}
.pb-4.pt-16.w-\[270px\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}

button.h-full.w-full.flex.justify-between.items-center.pl-10.bg-white.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    z-index: 1000000 !important;
}

.border-\[\#3957db\], svg.absolute.right-2.top-4.cursor-pointer {
    color: #fafafa!important;
    z-index: 100000000 !important;
}

/*All Paths Z-Index*/
path {
    color: inherit !important;
    z-index: 2000000 !important;
}

path, svg.Posts_absolute__OyiG3.Posts_right-2__Y4Tfe.Posts_top-4__rhvX1.Posts_cursor-pointer__pcF7\+ {
    color: inherit !important;
}

.border-\[\#36d300\] {
    --tw-border-opacity: 1;
    border-color: rgb(70 211 0/var(--tw-border-opacity));
    border-color: rgba(250, 250, 250, 0.3) !important;
}

.py-5 {
    padding-bottom: 3.25rem !important;
    padding-top: 3.25rem !important;
}

.border.cursor-pointer {
    margin-top: 25px !important;
    border: 1px solid #ffffff !important;
    padding: 15px !important;
}

.mt-3 {
    margin-top: 0rem;
}
.mr-3 {
    margin-right: 0rem;
}

.border.cursor-pointer {
    margin-top: 45px !important;
    margin-right: 15px !important;
    border: 1px solid #ffffff !important;
    padding-top: 0px !important;
    padding: 15px !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -25px!important;
    margin-left: 15px!important;
    margin-right: 15px!important;
    border-bottom: none !important;
}

/*Add Prod Button*/



.h-\[200px\] {
    height: 100px !important;
}

.w-\[150px\].bg-green-700.h-\[50px\].my-3.flex.items-center.justify-center.rounded-xl.cursor-pointer.bg-\[\#6443d1\].mt-4.\!rounded.\!h-11 {
    background-color: #777777 !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

button.bg-gradient-to-r.from-teal-400.to-teal-500.text-white.font-bold.rounded-l.px-4.py-2.shadow-lg.hover\:opacity-75.transition.duration-300.ease-in-out {
    border-radius: 24px;
    margin-left: 15px !important;
    margin-right: 15px !important;
    font-size: 16px !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    background-image: none !important;
    border-color: rgba(250, 250, 250, 0.2) !important;
    border: 2px solid rgba(250, 250, 250, 0.2) !important;
}

.ml-1 {
    margin-left: 0.25rem;
    font-size: 25px !important;
}

path {
    color: rgba(250, 250, 250, 0.8) !important;
}

.bg-\[\#e44343\] {
    --tw-bg-opacity: 1;
    background-color: rgb(228 67 67/var(--tw-bg-opacity));
    background-color: rgb(70,211,0) !important;
}

.text-\[16px\] {
    font-size: 16px;
    color: #ffffff !important;
    text-transform: capitalize !important;
}

.bg-\[\#f63b60\] {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity));
}

.\!bg-\[\#FDE1E6\] {
    --tw-bg-opacity: .5 !important;
    background-color: rgb(225 225 225/var(--tw-bg-opacity))!important;
}

.\!text-\[\#f63b60\] {
    --tw-text-opacity: 1!important;
    color: rgb(225 225 225/var(--tw-text-opacity))!important;
}

input.text-gray-800.sm\:w-72.w-full.sm\:mr-5.mr-1.lg\:mb-0.mb-4.py-2\.5.rounded.px-2.focus\:outline-none {
    background-color: rgba(0,0,0,.5)!important;
    border: 1px solid hsla(0,0%,98%,.3)!important;
    border-radius: 50px!important;
    padding-left: 25px!important;
    color: #ffffff !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 path {
    color: rgb(70, 211, 0) !important;
    border-bottom: none !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 > path {
    border-bottom: none!important;
    color: #36d300!important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgba(250, 250, 250, 0.5) !important;
    font-weight: 500;
}

/*-----Dashboard-------*/

/*.w-full.h-\[80px\].bg-white.shadow.sticky.top-0.left-0.z-30.flex.items-center.justify-between.px-4 div a > img {
    width: 37% !important;
    height: auto !important;
}*/

.text-\[\#555\] {
    --tw-text-opacity: 1;
    color: rgb(85 85 85/var(--tw-text-opacity));
    color: rgba(250, 250, 250, 0.9) !important;
}

.text-\[crimson\] {
    --tw-text-opacity: 1;
    color: rgb(220 20 60/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
}

/*Ellipsis*/

/*.css-mh3zap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: var(--unstable_DataGrid-headWeight);
}*/

.MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-78d1bb {
    color: #ffffff !important;
}

.css-78d1bb {
    --unstable_DataGrid-radius: 4px;
    --unstable_DataGrid-headWeight: 500;
    --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
    --DataGrid-cellOffsetMultiplier: 2;
    flex: 1 1 0%;
    box-sizing: border-box;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    border-radius: var(--unstable_DataGrid-radius);
    color: rgba(250, 250, 250, 1.0) !important;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    height: 100%;
    display: flex;
    min-width: 0px;
    min-height: 0px;
    flex-direction: column;
    overflow-anchor: none;
}

p#\:r4p\: {
    color: rgba(250, 250, 250, 0.9) !important;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
    margin: 0;
    color: rgba(250, 250, 250, 0.9) !important;
}

.css-78d1bb.MuiDataGrid-autoHeight {
    height: auto;
}
.css-78d1bb {
    --unstable_DataGrid-radius: 4px;
    --unstable_DataGrid-headWeight: 500;
    --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
    --DataGrid-cellOffsetMultiplier: 2;
    flex: 1 1 0%;
    box-sizing: border-box;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    border-radius: var(--unstable_DataGrid-radius);
    color: rgba(250, 250, 250, 1.0) !important;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    height: 100%;
    display: flex;
    min-width: 0px;
    min-height: 0px;
    flex-direction: column;
    overflow-anchor: none;
    padding: 25px !important;
}

.w-full.mx-8.pt-1.mt-10.bg-white {
    padding: 25px !important;
}

.w-full.min-h-\[45vh\].bg-white.rounded {
    padding: 25px !important;
}

.share-buttons {
    display: flex;
    justify-content: space-evenly !important;
}

.share-button {
    align-items: center;
    border-radius: 50px !important;
    display: flex;
    font-size: 18px !important;
    padding: 15px !important;
    text-decoration: none;
    transition: background-color .3s ease;
}

.inner-container {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.1) !important;
}

.outer-container {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.relative.min-h-\[70vh\].\38 00px\:min-h-\[80vh\].w-full.bg-no-repeat.flex.items-center {
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.w-full.h-\[420px\].rounded-lg.shadow-sm.p-3.relative.cursor-pointer {
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    padding-top: 25px !important;
}


.inner-container {
background-color: rgba(250, 250, 250, 0.0) !important;
	border: 0px !important;
}

input.w-full.border.p-1.rounded-\[5px\] {
    background-color: rgb(45,45,45) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.3) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}


/*------Messaging------*/
/*.text-\[\#fff\], .text-\[\#ffffff\] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: block !important;
    position: relative !important;
    padding: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
}

.text-\[\#fff\], .text-\[\#ffffff\] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: block !important;
    position: relative !important;
    padding: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border-radius: 14px !important;
}*/

.w-full.flex.p-3.px-3.bg-\[\#00000010\].cursor-pointer {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.w-full.flex.p-3.px-3.bg-transparent.cursor-pointer {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

aside.outer-container div .p-3 {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

aside.outer-container div .p-3 h5 {
    font-size: 12px !important;
}

h5.font-\[600\] {
    font-size: .2em !important;
	color: rgba(250, 250, 250, 0.4) !important;
}

.profile-dropdown {
    z-index: 1000000 !important;
    overflow: inherit !important;
}

.hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity)) !important;
}

div#headlessui-menu-items-\:rq\: {
    display: block;
    position: fixed !important;
    margin-top: 25px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: rgb(250, 250, 250) !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

a#headlessui-menu-item-\:rr\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rs\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rt\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

/*body {
    background: rgba(14, 17, 22, 0.7) !important;
}*/
/*----rgba(14, 17, 22, 0.7)-----*/

.css-1r1fc2u {
    background-color: rgba(250, 250, 250, 0.7) !important;
}

.css-1r1fc2u {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(74, 74, 74);
    border-radius: 0.75rem;
}

.css-elpoka {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: 0.75rem;
}


/*-----NUMBER SOLD---------*/
span.font-\[400\].text-\[17px\].text-\[\#68d284\] {
    display: none !important;
}


/*-----Cart Titles---------*/
h4.pb-3.font-\[500\].items-center {
    text-transform: capitalize !important;
}

.md\:flex.md\:justify-between.md\:items-center.sm\:px-12.px-4.bg-green-700.py-7 {
    background-color: rgba(14, 17, 22, 0.7) !important;
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
    /* border-bottom: rgba(250, 250, 250, 0.2) !important; */
}

.grid.grid-cols-1.sm\:gird-cols-3.lg\:grid-cols-4.gap-6.sm\:px-8.px-5.py-16.sm\:text-center {
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.pb-4.pt-16.w-\[270px\].absolute.z-30.rounded-b-md.shadow-sm {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
}

h1.text-\[\#fff\].text-\[18px\].font-\[600\] {
    background-color: transparent !important;
    border: 0px !important;
}

.text-\[\#475ad2\] {
    --tw-text-opacity: 1;
    color: rgb(70 211 0/var(--tw-text-opacity)) !important;
}


.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity));
}

a.bg-indigo-500.text-white.shadow-sm.hover\:bg-green-400.focus-visible\:outline-green-500.mt-6.block.rounded-md.py-2.px-3.text-center.text-sm.font-semibold.leading-6.focus-visible\:outline.focus-visible\:outline-2.focus-visible\:outline-offset-2 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
}

.ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border: 1px solid rgb(70, 211, 0) !important;
}

span.pr-3.font-\[400\].text-\[17px\].text-\[\#44a55e\] {
    display: none !important;
}

div#headlessui-menu-items-\:rb\: {
	position: fixed !important;
}

.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
    margin-bottom: 25px !important;
}

@media (min-width: 1024px) {
    .lg\:px-24 {
      padding-left: 6rem;
      padding-right: 6rem;
      padding-top: 45px !important;
    }
  }


.min-h-screen.bg-gray-50.flex.flex-col.justify-center.py-12.sm\:px-6.lg\:px-8 {
    background-color: rgba(15, 17, 22, 1.0) !important;
}


h2.mt-6.text-center.text-3xl.font-extrabold.text-gray-900 {
    text-transform: capitalize !important;
}

label.block.text-sm.font-medium.text-gray-700 {
    text-transform: capitalize !important;
}

.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    width: 100% !important;
    max-width: 50% !important;
    margin: auto !important;
}

.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(70 211 0/var(--tw-text-opacity)) !important;
}

.mt-8.sm\:mx-auto.sm\:w-full.sm\:max-w-\[35rem\] .flex.items-center.w-full {
    text-align: center !important;
    margin: auto !important;
    display: block !important;
    position: relative !important;
    margin-top: 25px !important;
    margin-bottom: 15px !important;
}

input.appearance-none.block.w-full.px-3.py-2.border.border-gray-300.rounded-md.shadow-sm.placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-blue-500.sm\:text-sm {
    background-color: rgba(250, 250, 250, 0.5) !important;
    color: rgb(14, 17, 22) !important;
    border-radius: 50px !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39/var(--tw-text-opacity));
    color: rgba(250, 250, 250, 0.7) !important;
}

.relative.inline-block {
    float: right !important;
}

div#headlessui-popover-panel-\:r6\: {
    margin-top: 35px !important;
}

.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(70 211 0/var(--tw-text-opacity)) !important;
}

button.text-green-500.border-green-500.whitespace-nowrap.py-4.px-1.border-b-2.font-medium.text-sm {
    border-bottom: 2px solid rgb(70, 211, 0) !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Mui-disabled.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Posts_css-cda2fy__xgrti.css-1ujsas3 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    color: rgb(250, 250, 250) !important;
    width: 20% !important;
}

.profile-dropdown {
    overflow: inherit!important;
    z-index: 1000000!important;
    display: inline-flex !important;
    position: fixed !important;
    margin-top: 25px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: auto !important;
}

a#headlessui-menu-item-\:r1\:,a#headlessui-menu-item-\:r2\:,a#headlessui-menu-item-\:r3\:,a#headlessui-menu-item-\:r4\: {
    background-color: rgba(0, 0, 0, 0.2) !important;
	border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a:hover#headlessui-menu-item-\:r1\:,a:hover#headlessui-menu-item-\:r2\:,a:hover#headlessui-menu-item-\:r3\:,a:hover#headlessui-menu-item-\:r4\: {
    background-color: rgba(0, 0, 0, 0.2) !important;
	border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.9rem !important;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(18, 66, 22);
    font-weight: 500;
}


/*Posts Top Title*/
h3.px-4.py-2.text-xl.font-semibold.text-gray-900 {
    display: none !important;
}


h3.text-center.py-2.text-\[20px\] {
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    display: none !important;
}


/*Profile Header*/
.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    display: none !important;
}

img.w-\[150px\].h-\[150px\].object-cover.rounded-full {
    border: 2px solid snow!important;
    display: block!important;
    height: 95px!important;
    width: 95px!important;
}

#root .profile-page img.w-full.object-cover {
    height: 450px !important;
}


/* Cart Image */

img.w-\[125px\].h-min.ml-2.mr-2.rounded-\[5px\] {
    border-radius: 0px !important;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
}

svg.cursor-pointer.\38 00px\:mb-\[\'unset\'\].\38 00px\:ml-\[\'unset\'\].mb-2.ml-2 {
    margin-left: 0 !important;
}

.w-full.\38 00px\:flex.items-center {
    width: 100% !important;
}

.product-description {
	font-size: 16px;
	color: rgba(250, 250, 250, 0.8) !important;
    text-transform: capitalize !important;
}

.content-bg {
    background-color: rgba(14,17,22,.7) !important;
}

.bg-drk {
	background-color: rgba(14,17,22,.2) !important;
}

/*Search Dropdown*/
.search-drop {
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-left: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
    width: 100% !important;
    border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-radius: 10px !important;
}


/*Categories Drop Down*/
button.h-full.w-full.flex.justify-between.items-center.pl-10.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    z-index: 2000000 !important;
}

.css-miw4ts {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: hsla(0,0%,98%,.2)!important;
    border: 1px solid hsla(0,0%,98%,.2)!important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 98px !important;
}

h3.text-xl.font-bold.text-green-500.sm\:text-2xl {
    color: #ffffff !important;
    line-height: 35px !important;
}

p.text-sm.text-green-500 {
    padding-bottom: 15px !important;
}

.profile-page .w-full {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-mnn31 {
    font: inherit;
    letter-spacing: inherit;
    color: rgb(250, 250, 250) !important;
    padding: 4px 0px 5px;
    border: 0px;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
}

.css-1r4ju6z {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: rgba(250, 250, 250, 0.1);
    border-radius: 2rem;
    padding: 1rem 2rem;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-dejdd {
    width: 55px;
    height: 55px;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
    border-radius: 100% !important;
}

img.h-10.w-10.rounded-full {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.css-1sb2j9v {
    gap: 1rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    text-align: center !important;
}

.css-10yzw17 {
    gap: 0.25rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    text-align: center !important;
}

.MuiBox-root.css-dejdd>img {
    height: 55px !important;
    width: 55px !important;
}

.user-info.px-4.py-5.sm\:px-6 {
    width: 86% !important;
    height: 100px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    margin: auto !important;
    border-radius: 10px !important;
    margin-top: 35px !important;
    vertical-align: middle !important;
}

.mx-auto.flex.w-full.items-start.gap-x-8.px-4.py-10.sm\:px-6.lg\:px-24 {
    padding-top: 25px !important;
}

button.inline-flex.items-center.rounded-md.bg-green-600.px-3.py-2.text-sm.font-semibold.text-white.shadow-sm.hover\:bg-green-500 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    margin-bottom: 0px !important;
}

textarea.block.w-full.resize-none.border-0.bg-transparent.px-1\.5.py-1\.5.text-white.placeholder\:text-white.focus\:border-green-500.focus\:ring-0.sm\:text-sm {
    width: 100% !important;
}


.h-\[200px\] {
    height: 100px!important;
    width: 100px !important;
}




/*-----MOBILE--------*/

/* hi-res laptops and desktops */ 
@media (max-width:1281px) { 
	
	.py-1 {
    display: none !important;
}
	
	.null.w-full.h-\[60px\].bg-\[\#fff\].z-50.top-0.left-0.shadow-sm.\38 00px\:hidden {
    padding-top: 10px !important;
}
	
.fixed.w-\[70\%\].bg-\[\#fff\].h-screen.top-0.left-0.z-10.overflow-y-scroll {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}	
	.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
    color-interpolation: linearRGB;
    color: rgb(250, 250, 250) !important;
}
}

/* big landscape tablets, laptops, and desktops */
@media (max-width:1025px) {  }

/* tablet, landscape iPad, lo-res laptops ands desktops */ 
@media (max-width:961px)  { 
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 14px;
    text-transform: capitalize !important;
    padding-bottom: 15px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 6.5em !important;
}
	.profile-main.-mt-12.px-4.sm\:-mt-8.sm\:flex.sm\:items-end.sm\:px-6.lg\:-mt-16.lg\:px-24 {
    text-align: center !important;
}
	.inline-flex.overflow-hidden.border-4.border-white {
    margin: auto !important;
}
	
	h3.text-xl.font-bold.text-green-500.sm\:text-2xl {
    color: #fff!important;
    line-height: 35px!important;
    text-align: center !important;
    margin: auto !important;
}
	
	.fixed.w-\[70\%\].bg-\[\#fff\].h-screen.top-0.left-0.z-10.overflow-y-scroll {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}
}

@media (min-width:800px) and (max-width:995px)  { 
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 6.0em !important;
}
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 14px;
    text-transform: capitalize !important;
    padding-bottom: 15px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	.fixed.w-\[70\%\].bg-\[\#fff\].h-screen.top-0.left-0.z-10.overflow-y-scroll {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
@media (max-width:641px)  { 
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 11px;
    text-transform: capitalize !important;
    padding-bottom: 15px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.5em !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
}


 /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
@media (max-width:481px)  {
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 10px;
    text-transform: capitalize !important;
    padding-bottom: 10px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.5em !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
}



/* hi-res iphone */ 
@media only screen and (max-width: 480px){ 

}


/* hi-res iphone */ 
@media only screen and (max-width: 386px){ 
.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.3em!important;
}
	
.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 10px !important;
    margin-bottom: 10px!important;
    margin-top: 0!important;
    padding-bottom: 15px!important;
    padding-top: 0!important;
    text-transform: capitalize!important;
}
	
	.py-1 {
    padding-bottom: 0rem !important;
    padding-top: 0rem !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
	
/*.null.w-full.h-\[60px\].bg-\[\#fff\].z-50.top-0.left-0.shadow-sm.\38 00px\:hidden {
    padding-top: 10px !important;
}*/
	
}


/* smartphones, iPhone, portrait 480x320 phones */ 
@media (max-width:320px)  { 
.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.0em!important;
}
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 10px !important;
    margin-bottom: 10px !important;
    margin-top: 0!important;
    padding-bottom: 15px!important;
    padding-top: 0!important;
    text-transform: capitalize!important;
}
	
.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 45px !important;
}
}


.profile-dropdown {
    z-index: 1000000 !important;
    overflow: inherit !important;
}

.hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity));
}

div#headlessui-menu-items-\:rq\: {
    display: block !important;
    position: fixed !important;
    margin-top: 25px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: rgb(250, 250, 250) !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

a#headlessui-menu-item-\:rr\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rs\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rt\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}


.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 0.8) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

div#headlessui-menu-items-\:rb\: {
	position: fixed !important;
}

/*---- TEMP HIDE-----*/
.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    /*display: none !important;*/
}

.css-1r1fc2u {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(74, 74, 74);
    border-radius: 0.75rem;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

/*----Profile Header--------*/
.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    /*display: none !important;*/
}

.flex.h-full.flex-col.overflow-y-scroll.bg-white.shadow-xl {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.relative.MuiBox-root.css-b8gmgq {
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.relative.MuiBox-root.css-b8gmgq>img {
    border: 2px solid rgba(0, 0, 0, 0.3);
}

.profile-page .w-full {
    border-bottom: none !important;
}

.relative.cursor-pointer.mr-\[15px\] {
    z-index: 2000000 !important;
}

/*Login-*/

button.group.relative.w-full.h-\[40px\].flex.justify-center.py-2.px-4.border.border-transparent.text-sm.font-medium.rounded-md.text-white.bg-green-600.hover\:bg-green-700 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    width: 50% !important;
    margin: auto !important;
}

.text-green-700 {
    color: rgb(70, 211, 0) !important;
    line-height: 55px !important;
    border-bottom: none !important;
}

.mt-1 input.appearance-none.block.w-full.px-3.py-2.border.border-gray-300.rounded-md.shadow-sm.placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-green-500.sm\:text-sm {
    border-radius: 50px !important;
}

.mt-8.sm\:mx-auto.sm\:w-full.sm\:max-w-md {
    border-radius: 15px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 15px !important;
}

.profile-dropdown {
    border-bottom-right-radius: 0!important;
    border-top: 0!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    display: inline-flex!important;
    margin-top: 25px!important;
    position: fixed!important;
    width: auto!important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

/*.profile-dropdown a:hover, .profile-dropdown form button#headlessui-menu-item-\:r9\: {
    border-bottom-right-radius: 0!important;
    border-top: 0!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    display: inline-flex!important;
    margin-top: 25px!important;
    position: fixed!important;
    width: auto!important;
    background-color: rgba(70, 211, 270, 1.0) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}*/

.fixed.inset-x-0.bottom-0.z-100.bg-gray-700 {
    z-index: 10000 !important;
}

button.rounded-md.bg-green-600.px-3\.5.py-2\.5.text-sm.font-semibold.text-white.shadow-sm.hover\:bg-green-500.focus-visible\:outline.focus-visible\:outline-2.focus-visible\:outline-offset-2.focus-visible\:outline-indigo-600 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

a.flex.justify-center.items-center.px-6.py-2.border.border-transparent.text-base.font-medium.rounded-md.text-white.bg-indigo-600.hover\:bg-indigo-700.md\:py-2.md\:text-lg.md\:px-10 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: 50px !important;
    max-width: 50% !important;
    margin: auto !important;
    margin-bottom: 25px !important;
}


input.mt-2.appearance-none.block.w-full.px-3.h-\[35px\].border.border-gray-300.rounded-\[3px\].placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-blue-500.sm\:text-sm {
    color: rgba(0,0,0,0.5)333 !important;
}

textarea.mt-2.appearance-none.block.w-full.pt-2.px-3.border.border-gray-300.rounded-\[3px\].placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-blue-500.sm\:text-sm {
    color: rgba(0,0,0,0.5)333 !important;
}

.bg-\[\#099910\] {
    background-color: rgba(0, 0, 0, 0.5) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

#root .profile-page img.w-full.object-cover {
    height: 450px!important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.profile-toolbar-avatar {
    height: auto;
    width: 42px;
    border-radius: 100%;
    border: 1px solid #ffffff !important;
    margin-bottom: 15px !important;
}

.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10 {
    margin-top: 100px !important;
}

.relative.flex-1.px-4.py-6.sm\:px-6 .flow-root {
    overflow: scroll !important;
}

.css-1o5bsd6 {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 16px;
}
.css-1cbcpl3 {
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    margin-top: -16px;
    width: calc(100% + 16px);
    margin-left: -8px !important;
    background-color: rgba(250, 250, 250, 0.0) !important;
}

.css-fkcv64 {
    display: flex;
    width: 100%;
    border-top: 1px solid rgba(250, 250, 250, 0.12) !important;
}

/*Shop by seller component */

.shop-list {
    display: flex;
    flex-wrap: nowrap; 
    gap: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 8%;
    margin-right: 8%;
    overflow-x: auto; 
    -webkit-overflow-scrolling: touch; 
  }
  
  .shop-card {
    width: 200px; 
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10%;
    padding: 10px;
    text-align: center;
    flex-shrink: 0; 
  }
  .shop-card img {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    object-fit: cover;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    display: block; 
    margin: 0 auto; 
  }
  
  
  
  .shop-card h3 {
    margin-top: 10px;
    color: rgba(0,0,0,0.5);
  }
  
/* ShopProfile Tabs css */

.nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; 
    gap: 10px; 
  }
  
  .tab-button {
    flex: 0 0 auto; 
    white-space: nowrap;
  }
  


/* SponsorProduct.css */
.range-slider {
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #1f9c29;
    outline: none;
    opacity: 0.7;
    -webkit-appearance: none; /* Removes default styling for WebKit browsers */
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Removes default thumb for WebKit browsers */
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    border: 3px solid #1f9c29;
}

.range-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    border: 3px solid #1f9c29;
}

/* map.css */

.map-container {
    position: relative;
    width: 100vw;
    height: 80vh;
  }

  .filters-container {
    background: #212121d4;
    border: #14b506;
    border-radius: 8px;
    box-shadow: 0 2px 4px #0003;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    padding: 20px;
    position: absolute;
    right: 60px;
    top: 55%;
    width: 300px;
    z-index: 2;
}
  
  @media (max-width: 768px) {
    .filters-container {
      width: 90%;
      top: 10px;
      right: 10px;
      max-height: calc(100vh - 20px);
    }
  }
/*----UPDATED: DUBS 07-15-2024-------*/

.friend-card-image img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    height: 120px;
    object-fit: cover;
    width: 100%;
}

.switcheroo-current-profile-avatar {
    border-radius: 100%;
    height: 42px;
    max-width: 42px;
    width: 42px;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.switcheroo-swap-icon {
    background-color: #36d300;
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
    border-radius: 50%;
    bottom: -10px;
    padding: 3px;
    position: absolute;
    right: -10px;
}

.sidebar-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    border: 1px solid rgba(250, 250, 250, 0.3);
    border-left: 0px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.MuiBox-root.css-1ug174f, .bg-white {
    background-color: #fafafa33 !important;
    border: 1px solid #fafafa33 !important;
    border-top: 0px !important;
}

.flex.h-full.flex-col.overflow-y-scroll.bg-white.shadow-xl {
    background-color: #0c1117e6 !important;
}

.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10 {
    margin-top: 99px !important;
    border-top: 0px !important;
}

.friend-card-actions button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1uch84x {
    background-color: #36d300 !important;
    border-radius: 50px !important;
}

.friend-card-actions {
    display: flex;
    justify-content: center;
    /* padding: 10px; */
    padding-bottom: 5px !important;
}

.suggested-friend-list {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    gap: 0px;
    justify-content: center;
    overflow-x: auto;
    padding-top: 25px;
    padding-bottom: 25px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.sidebar-nav-item-current {
    background-color: #36d300 !important;
    border-color: #36d300 !important;
    color: #ffffff !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    color: #fafafa !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.MuiBox-root.css-miw4ts {
    color: #ffffff !important;
    margin-left: 0px !important;
}

.css-1r4ju6z {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.87) !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: rgba(250, 250, 250, 0.1);
    border-radius: 1rem !important;
    padding: 1rem 2rem;
}

.recommended-friends-container {
    margin: 0 auto;
    max-width: 85vw;
    padding: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.friend-card {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    padding: 10px;
    transition: transform .2s, box-shadow .2s;
    width: 180px;
}

.friend-card-image img {
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-radius: 10px !important;
    height: 120px;
    object-fit: cover;
    width: 100%;
}

.shop-card {
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 10px;
    background-color: rgba(250, 250, 250, 0.1) !important;
    flex-shrink: 0;
    padding: 10px;
    text-align: center;
    width: 200px;
}

.homepage-hero-slide-button {
    background-color: #36d300 !important;
    border-radius: 50px !important;
    color: #ffffff !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.homepage-hero-slide {
    background-attachment: fixed;
    background-size: cover;
    position: absolute;
    background-position: 50% center;
    inset: 0px;
    transition: opacity 0.5s ease-in-out 0s;
    border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 20px;
}

h5.pt-3.text-\[15px\].text-blue-400.pb-3 {
    font-size: 16px !important;
    font-weight: 700;
}

h4.pb-3.font-\[500\].items-center {
    font-size: 15px !important;
}

h4.friend-card-name {
    font-size: 16px !important;
    font-weight: 500 !important;
    padding-bottom: 25px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: #0c1117 !important;
    border-bottom: 1px solid #fafafa33 !important;
}

.shop-list .shop-card h3 {
    font-weight: 600 !important;
}

.hover\:bg-green-700:hover {
    background-color: rgba(250, 250, 250, 0.3) !important;
}

.content-container .sm\:block {
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    padding: 15px !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: 10px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.profile-header {
    background-color: transparent !important;
    height: 0.3rem;
}

.css-a4r3a9 {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #36d300 !important;
    padding: 0.6rem !important;
    color: rgb(255, 255, 255);
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}


.sidebar-badge {
    align-items: center;
    background-color: #36d300 !important;
    border-radius: 9999px;
    color: #fff !important;
    display: inline-flex;
    font-size: .75rem;
    font-weight: 700;
    justify-content: center;
    padding: .25rem;
    position: absolute;
    right: -.55rem;
    top: -.25rem;
}

.sponsored-brands-widget-avatar {
    border-radius: 50%;
    height: 50px !important;
    object-fit: cover;
    width: 50px !important;
    margin: 15px !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.sponsored-brands-widget-cover {
    height: 200px;
    object-fit: cover;
    width: 100%;
    border-top: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.css-1uch84x {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #36d300 !important;
    padding-right: 25px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 25px !important;
    color: rgb(255, 255, 255);
    border-radius: 50px !important;
}

.shop-profile-data-tab-navigation {
    background-color: #fafafa1a !important;
    border: 1px solid #fafafa66 !important;
    border-radius: 10px !important;
    padding: 15px 25px !important;
    display: inline-block !important;
    overflow: scroll !important;
    width: 100% !important;
}

.shop-info-sidebar-top-row {
    align-items: center;
    background-color: rgba(250, 250, 250, 0.1) !important;
    box-shadow: 0 0 4px #00000050;
    gap: 10px;
}

.shop-info-sidebar-avatar {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;
    border: 3px solid rgba(250, 250, 250, 0.7) !important;
}

.shop-info-sidebar-container, .shop-info-sidebar-top-row {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px !important;
}

.share-buttons {
    justify-content: space-evenly !important;
    gap: 15px !important;
    margin-bottom: 15px !important;
}

.shop-info-sidebar-bottom-row {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 15px !important;
}

.xl\:gap-\[20px\] {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 10px !important;
    gap: 20px;
    padding: 15px !important;
}

.w-full.h-\[420px\].rounded-lg.shadow-sm.p-3.relative.cursor-pointer {
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    padding-top: 25px !important;
}

.shop-profile-data-tab-active {
    border-bottom-color: #22c55e;
    color: #36d300 !important;
    border-bottom: none !important;
    font-weight: 700 !important;
}

.shop-profile-data-tab-button {
    align-items: center;
    background: none;
    border: none;
    border-bottom: 2px solid #0000;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 15px !important;
}

.avatar-component-avatar {
    border: .5px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 65px !important;
    width: 65px !important;
    max-width: unset !important;
}

button.shop-profile-data-tab-inactive.shop-profile-data-tab-button {
    font-size: 11px !important;
}

.css-1fzwgfs {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 11px !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(128, 128, 128);
}

aside.w-full.lg\:w-1\/4.p-2.bg-\[\#f5f5f5\].p-2.rounded-lg.lg\:sticky.lg\:top-24 {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

.px-20 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
}

.MuiBox-root.css-miw4ts > img {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.notification-sidebar-panel-content {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.notification-feed-connector {
    background-color: transparent !important;
    height: 100%;
    left: 1.25rem;
    margin-left: -1px;
    position: absolute;
    top: 1.25rem;
    width: .125rem;
}

li strong {
    color: rgba(250, 250, 250, 1.0) !important;
}

.notification-feed-text-wrapper {
    flex: 1 1;
    min-width: 0;
    padding-left: 10px !important;
    /* width: 100% !important; */
}

.shop-info-sidebar-container {
    background-color: transparent !important;
    border: none !important;
    gap: 15px !important;
}

.shop-info-sidebar-top-row {
    align-items: center;
    background-color: transparent !important;
    box-shadow: none !important;
    gap: 10px;
    border-bottom: none !important;
}

.shop-info-sidebar-container, .shop-info-sidebar-top-row {
    border-radius: 0px !important;
    display: flex;
    flex-direction: column;
    padding: 10px !important;
}

.shop-info-sidebar-top-row {
    padding-bottom: 15px !important;
    background-color: rgba(250, 250, 250, 0.0) !important;
    border: none !important;
    border-bottom: none !important;
}

.shop-info-sidebar-bottom-row {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 25px !important;
    border-top: none;
    margin-top: 15px !important;
}

.categories-dropdown-header-item:hover {
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.categories-dropdown-header-container {
    background-color: rgba(0, 0, 0, 0.9) !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-radius: .375rem;
    border-top: none !important;
    box-shadow: 0 4px 8px #0000001a;
    margin-top: 4px !important;
    max-width: 100%;
    max-width: 1000px;
    min-width: 690px;
    position: absolute;
    top: 3.5rem;
    width: 100%;
    z-index: 50;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.categories-dropdown-header-product a:hover {
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-aoeo82 div {
    width: 75% !important;
    padding-bottom: 25px !important;
    text-align: center !important;
}

.recommended-friends-title {
    color: rgba(0,0,0,0.5);
    font-size: 24px;
    font-weight: 700 !important;
    margin-bottom: 0px;
    text-align: center;
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-aoeo82 div img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin: 10px;
    align-items: center;
    margin-left: 43% !important;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
}

.homepage-hero-slide {
    background-attachment: fixed;
    background-position: 50%;
    background-size: cover;
    border-bottom: none !important;
    border-radius: 20px;
    inset: 0;
    position: absolute;
    transition: opacity .5s ease-in-out 0s;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
}

body {
    background: rgba(12, 17, 23, 1.0) !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

/*-----------Questionable container beneath Root-------------*/
.MuiBox-root.css-1ug174f, .bg-white {
    background-color: rgba(12, 17, 23) !important;
    border-top: rgba(250, 250, 250) !important;
}

.sidebar-content {
    align-items: center;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-bottom-right-radius: 10px !important;
    border-left: 0 !important;
    border-top-right-radius: 10px !important;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
}

.sidebar-container {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: 0 .5rem .5rem 0;
    box-shadow: 2px 0 10px #0000001a;
    width: 4rem;
}

a.hidden.text-sm.font-semibold.text-green-600.hover\:text-green-500.sm\:block {
    color: #ffffff !important;
    padding: 10px !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

h2.text-2xl.font-bold.tracking-tight.text-green-600 {
    background-color: transparent !important;
}

.max-w-2xl.mx-auto.py-16.px-4.sm\:py-24.sm\:px-6.lg\:max-w-7xl.lg\:px-8 {
    background-color: transparent !important;
}

.MuiBox-root.css-1ug174f, .bg-white {
    background-color: rgba(12, 17, 23) !important;
    border-top: none !important;
    border: none !important;
}

section.border-b.border-gray-200.px-4.py-6.sm\:px-6.lg\:px-8 {
    border: none !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.5) !important;
    margin-bottom: 25px !important;
    margin-top: 25px !important;
}

h2#filter-heading {
    color: #ffffff !important;
}


/* Category Products Page */
.category-products-page__container {
    background-color: rgba(0, 0, 0, 0.9);;
  }
  
  .category-products-page__content {
    display: flex;
    justify-content: center;
  }
  
  .category-products-page__filters {
    width: 100%;
    max-width: 7xl;
  }
  
  .category-products-page__disclosure-section {
    border-bottom: 1px solid #e5e7eb;
    padding: 1.5rem 0;
  }
  
  .category-products-page__heading {
    text-align: center;
    font-size: 1.875rem;
    color: #38bdf8;
    font-weight: 500;
  }
  
  .category-products-page__disclosure-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0;
    font-size: 0.875rem;
    color: #9ca3af;
    cursor: pointer;
  }
  
  .category-products-page__disclosure-title {
    font-weight: 500;
    color: #111827;
  }
  
  .category-products-page__chevron {
    height: 1.25rem;
    width: 1.25rem;
  }
  
  .category-products-page__disclosure-panel {
    padding-top: 1rem;
  }
  
  .category-products-page__filter-options {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    gap: 1rem;
    overflow-x: auto;
  }
  
  .category-products-page__menu {
    position: relative;
    display: inline-block;
    text-align: left;
  }
  
  .category-products-page__menu-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    border-radius: 0.375rem;
    background-color: rgba(0, 0, 0, 0.9); ;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
    border: 1px solid #d1d5db;
  }
  
  .category-products-page__menu-button:hover {
    background-color: #f9fafb;
  }
  
  .category-products-page__menu-items {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    width: 14rem;
    border-radius: 0.375rem;
    background-color: rgba(0, 0, 0, 0.9);;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    outline: none;
  }
  
  .category-products-page__menu-content {
    padding: 0.25rem 0;
  }
  
  .category-products-page__menu-item {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #6b7280;
    cursor: pointer;
  }
  
  .category-products-page__menu-item--active {
    background-color: #f3f4f6;
    color: #111827;
  }
  
  .category-products-page__transition-enter {
    transition: all 0.1s ease-out;
  }
  
  .category-products-page__transition-enter-from {
    transform: scale(0.95);
    opacity: 0;
  }
  
  .category-products-page__transition-enter-to {
    transform: scale(1);
    opacity: 1;
  }
  
  .category-products-page__transition-leave {
    transition: all 0.075s ease-in;
  }
  
  .category-products-page__transition-leave-from {
    transform: scale(1);
    opacity: 1;
  }
  
  .category-products-page__transition-leave-to {
    transform: scale(0.95);
    opacity: 0;
  }
  
  .category-products-page__price-range {
    position: relative;
    display: inline-block;
    text-align: left;
  }
  
  .category-products-page__price-range-panel {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    width: 14rem;
    border-radius: 0.375rem;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    outline: none;
    padding: 1rem;
  }
  
  .category-products-page__price-range-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .category-products-page__price-label {
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827;
  }
  
  .category-products-page__price-range-input {
    width: 100%;
    height: 0.5rem;
    background-color: #e5e7eb;
    border-radius: 0.375rem;
    appearance: none;
    cursor: pointer;
  }
  
  .category-products-page__price-value {
    font-size: 0.875rem;
    color: #6b7280;
    text-align: center;
  }
  
  .category-products-page__sort-dropdown {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    max-width: 14rem;
    margin: 0 auto;
  }
  
  .category-products-page__sort-label {
    font-size: 0.875rem;
    font-weight: 600;
    color: white;
  }
  
  .category-products-page__sort-wrapper {
    position: relative;
    flex: 1;
  }
  
  .category-products-page__sort-select {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    background-color: black;
    color: white;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    appearance: none;
    cursor: pointer;
  }
  
  .category-products-page__sort-select:focus {
    outline: none;
    border: 2px solid #38bdf8;
    border-color: #38bdf8;
  }
  
  .category-products-page__active-filters-bar {
    margin: 1rem auto;
    max-width: 7xl;
    padding: 1rem;
  }
  
  .category-products-page__active-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .category-products-page__active-filter {
    display: inline-flex;
    align-items: center;
    background-color: #34d399;
    border: 1px solid #e5e7eb;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    border-radius: 9999px;
  }
  
  .category-products-page__remove-filter {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    color: #9ca3af;
    cursor: pointer;
    outline: none;
  }
  
  .category-products-page__remove-filter:focus {
    outline: 2px solid rgba(250, 250, 250, 0.3) !important;
    outline-offset: 2px;
  }
  
  .category-products-page__remove-icon {
    height: 0.75rem;
    width: 0.75rem;
  }
  
  .category-products-page__products {
    max-width: 7xl;
    margin: 4rem auto;
    padding: 1rem;
  }
  
  .category-products-page__sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  
  .category-products-page__product-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem 1.5rem;
  }
  
  @media (min-width: 640px) {
    .category-products-page__product-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .sm\:py-24 {
        padding-bottom: 25px !important;
        padding-top: 25px !important;
    }
  }
  
  @media (min-width: 1024px) {
    .category-products-page__product-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .category-products-page__no-products {
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  
  .category-products-page__trending-products {
    width: 100%;
  }


/* Product details  */
  
  .product-details-component__content-bg {
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .product-details-component__section {
    width: 90%;
  }
  
  .product-details-component__py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .product-details-component__flex-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .product-details-component__flex-left {
    width: 100%;
    flex: 1;
  }
  
  .product-details-component__flex-right {
    width: 100%;
    flex: 1;
    padding-top: 20px;
  }
  
  .product-details-component__main-image {
    width: 80%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__image-selection {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .product-details-component__thumbnail {
    height: 200px;
    width: auto;
    object-fit: cover;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .product-details-component__thumbnail:hover,
  .product-details-component__thumbnail--selected {
    border-color: #38a169;
  }
  
  .product-details-component__brand-container {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 9999px;
    padding: 0.25rem 0.75rem;
    margin-top: 16px;
  }
  
  .product-details-component__brand-label {
    background-color: #36d300;
    color: white;
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-right: 8px;
  }
  
  .product-details-component__brand-value {
    font-size: 1rem;
    font-weight: 600;
    color: #2d3748;
  }
  
  .product-details-component__brand-image {
    width: auto;
    height: auto;
  }
  
  .product-details-component__product-title {
    font-size: 1.75rem;
    font-weight: bold;
    color: #2d3748;
  }
  
  .product-details-component__product-description {
    font-size: 1rem;
    color: #4a5568;
    line-height: 1.5;
  }
  
  .product-details-component__price-container {
    display: flex;
    padding-top: 1rem;
  }
  
  .product-details-component__discount-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #38a169;
  }
  
  .product-details-component__original-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #e53e3e;
    text-decoration: line-through;
    margin-left: 1rem;
  }
  
  .product-details-component__availability-container {
    display: flex;
    gap: 0.5rem;
    margin-top: 1.5rem;
  }
  
  .product-details-component__delivery-badge {
    background-color: #38a169;
    color: white;
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__pickup-badge {
    background-color: #3182ce;
    color: white;
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__quantity-wishlist-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding-right: 0.75rem;
  }
  
  .product-details-component__quantity-container {
    display: flex;
    align-items: center;
  }
  
  .product-details-component__quantity-button {
    background-color: #2d3748;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
  }
  
  .product-details-component__quantity-button:hover {
    opacity: 0.75;
  }
  
  .product-details-component__quantity-value {
    background-color: #edf2f7;
    color: #2d3748;
    font-weight: 500;
    padding: 0.5rem 1rem;
  }
  
  .product-details-component__wishlist-icon {
    cursor: pointer;
  }
  
  .product-details-component__add-to-cart-button {
    background-color: #36d300;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    margin-top: 1.5rem;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
  }
  
  .product-details-component__add-to-cart-button:hover {
    opacity: 0.75;
  }
  
  .product-details-component__add-to-cart-text {
    display: flex;
    align-items: center;
  }
  
  .product-details-component__cart-icon {
    margin-left: 0.25rem;
  }
  
  .product-details-component__shop-info {
    display: flex;
    align-items: center;
    padding-top: 2rem;
  }
  
  .product-details-component__shop-avatar {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    margin-right: 0.5rem;
  }
  
  .product-details-component__shop-details {
    padding-right: 2rem;
  }
  
  .product-details-component__shop-name {
    font-size: 1rem;
    font-weight: bold;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  
  .product-details-component__shop-ratings {
    font-size: 0.9375rem;
    color: #4a5568;
  }
  
  .product-details-component__send-message-button {
    background-color: #6443d1;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    margin-top: 1rem;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__send-message-text {
    display: flex;
    align-items: center;
  }
  
  .product-details-component__message-icon {
    margin-left: 0.25rem;
  }
  
  .product-details-component__info-container {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.75rem 2.5rem;
    border-radius: 0.5rem;
  }
  
  .product-details-component__info-tabs {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;
    padding-top: 2.5rem;
    padding-bottom: 0.5rem;
  }
  
  .product-details-component__info-tab {
    position: relative;
    cursor: pointer;
  }
  
  .product-details-component__info-tab-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    padding-left: 0.25rem;
    line-height: 1.25rem;
  }
  
  .product-details-component__active-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: #38a169;
  }
  
  .product-details-component__product-description-text {
    padding-top: 0.5rem;
    padding-bottom: 2.5rem;
    font-size: 1.125rem;
    line-height: 2rem;
    white-space: pre-line;
  }
  
  .product-details-component__metrics-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .product-details-component__aggregate-metrics {
    flex: 1;
    min-width: 0;
    border: 1px solid #38a169;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  
  .product-details-component__metrics-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .product-details-component__metrics-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }
  
  .product-details-component__metric-card {
    padding: 1rem;
    background-color: #2d3748;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__metric-label {
    color: white;
  }
  
  .product-details-component__metric-value {
    font-size: 1.25rem;
    color: #38a169;
    font-weight: 600;
  }
  
  .product-details-component__test-results {
    flex: 1;
    min-width: 0;
    border: 1px solid #38a169;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__test-results-image {
    max-width: 12rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .product-details-component__barcode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .product-details-component__barcode {
    border: 1px solid #38a169;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__barcode-image {
    max-width: 12rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .product-details-component__barcode-string {
    margin-top: 1rem;
    text-align: center;
  }
  
  .product-details-component__barcode-value {
    color: #38a169;
    font-weight: bold;
  }
  
  .product-details-component__nutrition-info {
    margin-top: 1rem;
    border: 1px solid #38a169;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__nutrition-info-image {
    max-width: 12rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .product-details-component__reviews-container {
    width: 100%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.75rem;
    overflow-y: scroll;
  }
  
  .product-details-component__review {
    width: 100%;
    display: flex;
    margin-bottom: 0.5rem;
  }
  
  .product-details-component__review-avatar {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
  }
  
  .product-details-component__review-content {
    padding-left: 0.5rem;
  }
  
  .product-details-component__review-header {
    display: flex;
    align-items: center;
  }
  
  .product-details-component__reviewer-name {
    font-weight: 500;
    margin-right: 0.75rem;
  }
  
  .product-details-component__review-comment {
    font-size: 0.9375rem;
    color: #4a5568;
  }
  
  .product-details-component__no-reviews {
    text-align: center;
    font-size: 1rem;
  }
  
  .product-details-component__seller-info {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1.25rem;
  }
  
  .product-details-component__seller-section {
    width: 100%;
    padding: 0.75rem;
    background-color: #2d3748;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__shop-link {
    text-decoration: none;
  }
  
  .product-details-component__shop-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #2d3748;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .product-details-component__shop-container:hover {
    background-color: #4a5568;
  }
  
  .product-details-component__shop-avatar-large {
    width: 64px;
    height: 64px;
    border-radius: 9999px;
    border: 2px solid #38a169;
  }
  
  .product-details-component__shop-details-large {
    padding-left: 1rem;
  }
  
  .product-details-component__shop-name-large {
    font-size: 1.125rem;
    font-weight: 600;
    color: #e2e8f0;
    transition: color 0.3s ease;
  }
  
  .product-details-component__shop-name-large:hover {
    color: #38a169;
  }
  
  .product-details-component__shop-rating {
    font-size: 0.875rem;
    color: #a0aec0;
  }
  
  .product-details-component__shop-description {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #e2e8f0;
  }
  
  .product-details-component__shop-metrics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .product-details-component__shop-metric {
    width: 100%;
    max-width: 33%;
    padding: 1rem;
    background-color: #2d3748;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__shop-metric-title {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #e2e8f0;
  }
  
  .product-details-component__shop-metric-value {
    font-size: 1.125rem;
    color: #38a169;
    font-weight: 600;
  }
  
  .product-details-component__visit-shop {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
  
  .product-details-component__visit-shop-button {
    background-color: #38a169;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    transition: background-color 0.3s ease;
    display: inline-block;
  }
  
  .product-details-component__visit-shop-button:hover {
    background-color: #2f855a;
  }
  
  .product-details-component__visit-shop-text {
    font-size: 1.125rem;
    font-weight: bold;
    color: white;
  }

.group.relative.p-4.shadow-lg.rounded-lg.bg-black.text-center {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a.hidden.text-sm.font-semibold.text-green-600.hover\:text-indigo-500.sm\:block {
    padding: 10px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
    border-radius: 50px !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    color: #ffffff !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
    margin-bottom: 25px !important;
}

.xl\:mx-auto {
        margin-left: 4rem !important;
        margin-right: auto !important;
    }

.text-green-600 {
    --tw-text-opacity: .2;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.xl\:px-8 {
        padding-left: inherit !important;
        padding-right: 2rem;
    }

.bg-black {
    --tw-bg-opacity: 1;
    background-color: #0c1117 !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.py-16.sm\:py-24.xl\:mx-auto.xl\:max-w-7xl.xl\:px-8 {
    margin: 0px 7% !important;
}

.w-\[150px\].bg-green-700.h-\[50px\].my-3.flex.items-center.justify-center.rounded-xl.cursor-pointer.ml-4.\!rounded-\[4px\] {
    height: 45px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    width: auto !important;
    max-width: 300px !important;
    margin: auto !important;
}

.homepage-category-component-section.hidden.sm\:block {
    padding: 0px !important;
}


.sponsored-brands-widget-container {
    background-color: transparent !important;
    border-radius: 10px;
    margin: 10px auto;
    max-width: 1000px;
    padding: 20px;
    padding-bottom: 25px !important;
}

.shop-profile-data-dashboard-link {
    align-items: center;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid #0000;
    border-radius: 50px;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    color: #fff;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    padding: 10px 20px;
    text-decoration: none;
    margin: 25px 0px !important;
}

.bg-\[\#f5f5f5\], .css-miw4ts {
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div h2 {
    font-size: 11px !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div {
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
	background-color: rgba(250, 250, 250, 0.1) !important;
}

.shop-info-sidebar-avatar-edit {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    bottom: 0;
    cursor: pointer;
    display: flex;
    height: 42px;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 42px;
}

.shop-info-sidebar-action-button {
    align-items: center;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: 50px !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
}

main.flex-grow.lg\:w-1\/2.p-4.rounded-lg {
    padding-top: 0px !important;
}

.shop-profile-data-content-container {
    padding: 20px;
    padding-top: 0px !important;
}

main.flex-grow.lg\:w-1\/2.p-4.rounded-lg {
    padding: 0px !important;
}

.shop-profile-data-content-container {
    padding: 0px !important;
    padding-top: 0px !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 {
    padding: 0px !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div {
    margin-top: 0px !important;
}

.sidebar-container {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: 0 .5rem .5rem 0;
    box-shadow: 2px 0 10px #0000001a;
    width: 4rem;
}

.sidebar-content {
    align-items: center;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-bottom-right-radius: 10px !important;
    border-left: 0 !important;
    border-top-right-radius: 10px !important;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.friend-card-actions button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1uch84x {
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: 35px !important;
}

.homepage-category-component-branding {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: .375rem;
    box-shadow: 0 1px 3px #0000001a;
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
    padding: 1.25rem;
    width: 100%;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

.homepage-category-component-categories-section {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: .5rem;
    margin-bottom: 3rem;
    padding: 1.5rem;
    border: 1px solid rgba(250, 250, 250, 0.4);
}

.homepage-category-component-category-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100px;
    justify-content: space-between;
    overflow: hidden;
    transition: background-color .3s, box-shadow .3s;
    width: 100%;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    padding: 15px !important;
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.homepage-category-component-category-image {
    height: 100%;
    object-fit: cover;
    width: 120px;
    border-radius: 10px !important;
    margin-left: 10px !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.homepage-category-component-category-title {
    color: #fff;
    font-size: 14px !important;
    line-height: 1.3;
    font-weight: 700 !important;
}

.best-deals-homepage-container {
    padding: 0px !important;
    width: 100%;
}

.best-deals-homepage-section {
    margin: 0px !important;
    width: 83.33%;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-1hw9j7s {
    background-color: #36d300 !important;
    border-radius: 50px !important;
    padding: 5px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.w-full.h-\[420px\].rounded-lg.shadow-sm.p-3.relative.cursor-pointer {
    border: 1px solid #fafafa66 !important;
    padding-top: 25px !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

h5.pt-3.text-\[15px\].text-blue-400.pb-3 {
    font-size: 14px !important;
    font-weight: 700;
}

h4.pb-3.font-\[500\].items-center {
    font-size: 14px !important;
}

button.bg-\[\#56d879\].hover\:bg-teal-500.duration-300.px-5.py-2\.5.rounded-md.text-white.md\:w-auto.w-full {
    border-radius: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

input.h-\[40px\].w-full.px-8.border-\[\#46d300\].border-\[2px\].rounded-\[2rem\] {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}
.homepage-hero-slider {
    height: 50vh;
    position: relative;
    margin-top: 25px !important;
}

.profile-avatar-wrapper {
    align-items: center;
    
    margin-right: 1rem;
    margin-top: -145px !important;
}


.profile-upload-container {
  margin-left: 360px !important;
  margin-top: -20px;
  width: 42px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.7) !important; 
  border-radius: 50%;
  display: block; 
  align-items: center;
  justify-content: left;
  cursor: pointer;
  border: 1px solid #fafafa80 !important; 
  text-align: center !important; 
  padding: 12px 12px !important; 
  margin-bottom: 10px; 
}


.profile-sidebar-left {
    border-radius: .375rem;
    order: 2;
    padding: 0px !important;
    width: 25% !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

.profile-sidebar-right {
    border-radius: .375rem;
    order: 3;
    padding: 0px !important;
    width: 100%;
}

    aside.profile-sidebar-right div {
    margin-top: 0px !important;
}

.user-profile-data-tab-navigation {
    background-color: #fafafa1a !important;
    border: 1px solid #fafafa66 !important;
    border-radius: 10px !important;
    padding: 0px !important;
}

    .user-profile-data-tab-active {
    border-bottom-color: #22c55e;
    color: #36d300 !important;
    border: none !important;
    font-weight: 700 !important;
}

.user-profile-data-tab-content {
    margin-top: 0px !important;
}

.profile-sidebar-right {
    padding: 20px;
    background-color: transparent !important;
    border-radius: 10px;
    margin: 0px !important;
    max-width: 1000px;
    border: none !important;
}

.css-1uch84x {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(31, 156, 41);
    padding: 0.6rem;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    height: 35px !important;
}
  
/* All Products Admin Dashboard component */
.all-products-component {
    background-color: rgba(0,0,0,0.5)333;
    padding: 6px;
    margin-top: 20px;
    min-height: 100vh;
    border-radius: 6px;
  }
  
  .all-products-component h1 {
    font-size: 24px;
    font-weight: bold;
    color: #F7FAFC;
    margin-bottom: 20px;
  }
  
  .all-products-component .charts-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  
  .all-products-component .chart {
    flex: 1;
    min-width: 300px;
    height: 420px;
  }
  
  .all-products-component .data-grid {
    width: 100%;
  }
  
  .all-products-component .data-grid .MuiDataGrid-columnHeaders {
    background-color: #146e10;
    color: #F7FAFC;
  }
  
  .all-products-component .data-grid .MuiDataGrid-cell {
    border-bottom: 1px solid #2D3748;
    color: #F7FAFC;
  }
  
  .all-products-component .data-grid .MuiDataGrid-cell:focus {
    outline: none;
  }
  
  .all-products-component img {
    width: 125px;
    height: auto;
    border-radius: 8px;
  }
  
  /* All Products Shop component */
  .all-products-shop-component {
    background-color: rgba(0,0,0,0.5);
    padding: 6px;
    margin-top: 20px;
    min-height: 100vh;
    border-radius: 6px;
  }
  .all-products-shop-component-header{
    background-color: rgba(250, 250, 250, 0.1)
  }
  
  .all-products-shop-component h1 {
    font-size: 24px;
    font-weight: bold;
    color: #F7FAFC;
    margin-bottom: 20px;
  }
  
  .all-products-shop-component .charts-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  
  .all-products-shop-component .chart {
    flex: 1;
    min-width: 300px;
    height: 420px;
  }
  
  .all-products-shop-component {
    width: 100%;
    overflow-x: auto;
  }
  .all-products-shop-component-data-grid-container {
    width: 100%;
    overflow-x: auto;
    background-color: rgba(250, 250, 250, 0.1);
  }
  .all-products-shop-component table {
    width: 100%;
    border-collapse: collapse;
    color: #F7FAFC;
  }
  
  .all-products-shop-component th,
  .all-products-shop-component td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #2D3748;
  }
  
  .all-products-shop-component th {
    background-color: rgba(250, 250, 250, 0.1);
    color: #F7FAFC;
  }
  
  .all-products-shop-component td img {
    width: 125px;
    height: auto;
    border-radius: 8px;
  }
  
  .all-products-shop-component .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
  .all-products-shop-component .modal-style,
  .all-products-shop-component .delete-modal-style {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.5)333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    outline: none;
    width: 80%;
    max-height: 80vh;
    overflow-y: auto;
    color: #F7FAFC;
    border: 2px solid #4CAF50;
  }
  
  .all-products-shop-component .delete-modal-style {
    width: 400px;
    text-align: center;
    border-radius: 10px;
  }
  
  .all-products-shop-component .close-icon-style {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #EF4444;
    font-size: 1.5rem;
  }
  
 /* Sponsor a product component */
 .sponsor-product-component-container {
    padding: 20px;
    background-color: rgba(250, 250, 250, 0.2);
    border-radius: 15px;
    border: 1px solid rgba(250, 250, 250, 0.6);
    max-width: 100%;
    margin: auto;
    color: rgba(250, 250, 250, 1);
}

.sponsor-product-component-title {
    text-align: center;
}

.sponsor-product-component-product-selection {
    margin-bottom: 20px;
}

.sponsor-product-component-product-selection label {
    display: block;
    margin-bottom: 10px;
}

.sponsor-product-component-product-list {
    border: 1px solid rgba(250, 250, 250, 0.6);
    border-radius: 10px;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    background-color: rgba(250, 250, 250, 0.1);
}

.sponsor-product-component-product-item {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    position: relative;
}

.sponsor-product-component-product-item img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.sponsor-product-component-product-item.selected {
    background-color: #1f9c29;
    border-radius: 15px;
}

.sponsor-product-component-close-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    cursor: pointer;
}

.sponsor-product-component-ad-preview {
    margin: 20px 0;
    max-width: 300px;
    padding: 10px;
    background-color: rgba(250, 250, 250, 0.2);
    border-radius: 10px;
}

.sponsor-product-component-ad-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sponsor-product-component-ad-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.sponsor-product-component-ad-link img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.sponsor-product-component-ad-info {
    background-color: rgba(84, 82, 82, 0.5);
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sponsor-product-component-visit-store {
    font-size: 12px;
    text-decoration: none;
    color: #1f9c29;
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
}

.sponsor-product-component-slider {
    margin-bottom: 20px;
}

.sponsor-product-component-slider label {
    display: block;
    margin-bottom: 10px;
}

.sponsor-product-component-slider input[type="text"] {
    margin-bottom: 10px;
    padding: 5px;
    background-color: rgba(250, 250, 250, 0.2);
    border: 1px solid rgba(250, 250, 250, 0.6);
    border-radius: 4px;
    color: rgba(250, 250, 250, 1);
}

.sponsor-product-component-range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #3d6000;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.sponsor-product-component-range-slider:hover {
    opacity: 1;
}

.sponsor-product-component-range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #1f9c29;
    cursor: pointer;
    border-radius: 50%;
}

.sponsor-product-component-range-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #1f9c29;
    cursor: pointer;
    border-radius: 50%;
}

.sponsor-product-component-total-spend {
    margin-bottom: 20px;
}

.sponsor-product-component-display-mode {
    margin-bottom: 20px;
}

.sponsor-product-component-display-mode button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #1f9c29;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.sponsor-product-component-display-mode button:hover {
    background-color: #145d10;
}

.sponsor-product-component-predictions {
    margin-bottom: 20px;
}

.sponsor-product-component-prediction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #545252;
    border-radius: 5px;
    margin-bottom: 10px;
}

.sponsor-product-component-prediction-item label {
    font-weight: bold;
    color: #1f9c29;
}

.sponsor-product-component-prediction-item span {
    color: white;
}

.sponsor-product-component-submit-button {
    width: 100%;
    padding: 10px;
    background-color: #1f9c29;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.sponsor-product-component-submit-button:hover {
    background-color: #145d10;
}
/* Enabled state */
.sponsor-product-component-submit-button.enabled {
  background-color: #36d300;
  color: white;
}

/* Disabled state */
.sponsor-product-component-submit-button.disabled {
  background-color: #ccc; 
  color: #666; 
  cursor: not-allowed; 
}

.sponsor-product-component-null-message {
    text-align: center;
    color: #ffcc00;
    font-weight: bold;
    margin-top: 20px;
}

/* Sponsor shop component */

.sponsor-shop-component-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 10px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  max-width: 100%;
  margin: auto;
  color: rgba(250, 250, 250, 1);
}

.sponsor-shop-component-title {
  text-align: center;
}

.sponsor-shop-component-slider {
  margin-bottom: 20px;
}

.sponsor-shop-component-slider label {
  display: block;
  margin-bottom: 10px;
}

.sponsor-shop-component-input {
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 4px;
  color: rgba(250, 250, 250, 1);
}

.sponsor-shop-component-range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #3d6000;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.sponsor-shop-component-range-slider:hover {
  opacity: 1;
}

.sponsor-shop-component-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #1f9c29;
  cursor: pointer;
  border-radius: 50%;
}

.sponsor-shop-component-range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #1f9c29;
  cursor: pointer;
  border-radius: 50%;
}

.sponsor-shop-component-total-spend {
  margin-bottom: 20px;
}

.sponsor-shop-component-display-mode {
  margin-bottom: 20px;
  text-align: center;
}

.sponsor-shop-component-display-mode button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #1f9c29;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sponsor-shop-component-display-mode button:hover {
  background-color: #145d10;
}

.sponsor-shop-component-predictions {
  margin-bottom: 20px;
}

.sponsor-shop-component-prediction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #545252;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sponsor-shop-component-prediction-item label {
  font-weight: bold;
  color: #1f9c29;
}

.sponsor-shop-component-prediction-item span {
  color: white;
}

.sponsor-shop-component-ad-preview {
  margin: 20px 0;
  max-width: 100%;
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 10px;
  text-align: center;
}

.sponsor-shop-component-ad-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.sponsor-shop-component-ad-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px auto;
}

.sponsor-shop-component-ad-banner {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.sponsor-shop-component-ad-info {
  background-color: rgba(84, 82, 82, 0.5);
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sponsor-shop-component-visit-store {
  padding: 5px 15px;
  border-radius: 5px;
  background: #1f9c29;
  color: white;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.sponsor-shop-component-submit-button {
  width: 100%;
  padding: 10px;
  background-color: #1f9c29;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sponsor-shop-component-submit-button:hover {
  background-color: #145d10;
}

.sponsor-shop-component-null-message {
  text-align: center;
  color: #ffcc00;
  font-weight: bold;
  margin-top: 20px;
}

/* Sponsor brand component */

.sponsor-brand-component-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 15px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  max-width: 100%;
  margin: auto;
  color: rgba(250, 250, 250, 1);
}

.sponsor-brand-component-title {
  text-align: center;
}

.sponsor-brand-component-product-selection {
  margin-bottom: 20px;
}

.sponsor-brand-component-product-selection label {
  display: block;
  margin-bottom: 10px;
}

.sponsor-brand-component-product-list {
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 5px;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  background-color: rgba(250, 250, 250, 0.1);
}

.sponsor-brand-component-product-item {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  position: relative;
}

.sponsor-brand-component-product-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.sponsor-brand-component-product-item.selected {
  background-color: rgba(250, 250, 250, 0.3);
}

.sponsor-brand-component-close-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  cursor: pointer;
}

.sponsor-brand-component-ad-preview {
  margin: 20px 0;
  max-width: 420px;
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 10px;
}

.sponsor-brand-component-ad-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsor-brand-component-ad-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.sponsor-brand-component-ad-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px auto;
}

.sponsor-brand-component-ad-info {
  background-color: rgba(84, 82, 82, 0.5);
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sponsor-brand-component-visit-store {
  padding: 5px 10px;
  border-radius: 5px;
  background: #1f9c29;
  color: white;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.sponsor-brand-component-slider {
  margin-bottom: 20px;
}

.sponsor-brand-component-slider label {
  display: block;
  margin-bottom: 10px;
}

.sponsor-brand-component-input {
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 4px;
  color: rgba(250, 250, 250, 1);
}

.sponsor-brand-component-range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #3d6000;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.sponsor-brand-component-range-slider:hover {
  opacity: 1;
}

.sponsor-brand-component-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #1f9c29;
  cursor: pointer;
  border-radius: 50%;
}

.sponsor-brand-component-range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #1f9c29;
  cursor: pointer;
  border-radius: 50%;
}

.sponsor-brand-component-total-spend {
  margin-bottom: 20px;
}

.sponsor-brand-component-display-mode {
  margin-bottom: 20px;
  text-align: center;
}

.sponsor-brand-component-display-mode button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #1f9c29;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sponsor-brand-component-display-mode button:hover {
  background-color: #145d10;
}

.sponsor-brand-component-predictions {
  margin-bottom: 20px;
}

.sponsor-brand-component-prediction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #545252;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sponsor-brand-component-prediction-item label {
  font-weight: bold;
  color: #1f9c29;
}

.sponsor-brand-component-prediction-item span {
  color: white;
}

.sponsor-brand-component-null-message {
  text-align: center;
  color: #ffcc00;
  font-weight: bold;
  margin-top: 20px;
}

.sponsor-brand-component-submit-button {
  width: 100%;
  padding: 10px;
  background-color: #1f9c29;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sponsor-brand-component-submit-button:hover {
  background-color: #145d10;
}

/* Sponsored Dashboard */

.sponsored-dashboard {
  color: #fff;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 15px;
}

.dashboard-title {
  text-align: center;
  margin-bottom: 25px;
}

.sponsored-table {
  width: 100%;
  border-collapse: collapse;
}

.sponsored-table th, .sponsored-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.6);
}

.sponsored-table th {
  background-color: rgba(250, 250, 250, 0.1);
}

.sponsored-table tr:hover {
  background-color: rgba(250, 250, 250, 0.1);
}

.view-total-button {
  background-color: #36d300; 
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

.view-total-button:hover {
  background-color: #36d300; 
}

.view-total-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.5); 
}

.sponsored-dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}

.sponsored-dashboard-button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  background-color: #36d300;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sponsored-dashboard-button-all {
  background-color: #28a745;
}

.sponsored-dashboard-button:hover {
  background-color: #36d300;
}

.sponsored-dashboard-button-all:hover {
  background-color: #218838;
}

.sponsored-dashboard-buttons-row {
  display: flex;
  justify-content: space-between;
  
}

.sponsored-dashboard-filter-row {
  display: flex;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
  
}

.sponsored-dashboard-filter-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #6c757d;
  border-radius: 10px;
  width: 100px;
}

.sponsored-dashboard-filter-item.active {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
  border: 2px solid #28a745;
}

.sponsored-dashboard-filter-item:hover {
  background-color: rgba(40, 167, 69, 0.05);
  color: #28a745;
}

.sponsored-dashboard-filter-icon {
  font-size: 1.8rem;
  margin-bottom: 8px;
  color: inherit;
}

.sponsored-dashboard-filter-item span {
  font-size: 1rem;
  font-weight: 600;
}


.sponsored-dashboard-time-buttons-row {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 1px solid rgba(250, 250, 250, 0.4);
}
.sponsored-dashboard-time-filter-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #6c757d;
  border-radius: 10px;
}

.sponsored-dashboard-time-filter-item.active {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
  border: 2px solid #28a745;
}

.sponsored-dashboard-time-filter-item:hover {
  background-color: rgba(40, 167, 69, 0.05);
  color: #28a745;
}

.sponsored-dashboard-time-filter-icon {
  font-size: 1.8rem;
  margin-bottom: 8px;
  color: inherit;
}

.sponsored-dashboard-time-filter-item span {
  font-size: 1rem;
  font-weight: 600;
}
.sponsored-dashboard-filter-time-row {
  display: flex;
  justify-content: space-between; 
  gap: 50px;
}

/* Sponsored product AD carousel */
.sponsored-product-carousel {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  margin: 20px 0;
}

.sponsored-product-carousel-paper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.sponsored-product-carousel-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
}

.sponsored-product-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.sponsored-product-carousel-content {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  text-align: left;
  z-index: 1;
}

.sponsored-product-carousel-title {
  font-size: 24px;
  margin: 0;
}

.sponsored-product-carousel-price {
  font-size: 18px;
  margin: 10px 0;
}

.sponsored-product-carousel-button {
  background-color: #36d300;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sponsored-product-carousel-button:hover {
  background-color: #28a200;
}

.sponsored-carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 2;
}

.sponsored-carousel-prev {
  left: 10px;
}

.sponsored-carousel-next {
  right: 10px;
}

.sponsored-carousel-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}





/* Sponsored product AD carousel */
.sponsored-product-carousel {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  margin: 20px 0;
}

.sponsored-product-carousel-paper {
  background-position: center center;
  background-size: cover;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  border-radius: 10px;
}

.sponsored-product-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 99.9% !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsored-product-carousel-title {
  font-size: 2em;
  margin: 0;
}

.sponsored-product-carousel-price {
  font-size: 1.25em;
  margin: 10px 0;
}

.sponsored-product-carousel-button {
  background-color: #36d300 !important;
  color: white;
  padding: 5px 25px !important;
  border: none;
  border-radius: 50px !important;
  cursor: pointer;
  font-size: 16px;
  margin: 25px 25px !important;
  text-decoration: none;
  transition: background-color 0.3s;
}

.sponsored-product-carousel-button:hover {
  background-color: #36d300;
}

.sponsored-carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.sponsored-carousel-prev {
  left: 10px;
}

.sponsored-carousel-next {
  right: 10px;
}

.sponsored-carousel-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}


/* Sponsored shop carousel AD */
.sponsored-shops-carousel-container {
  border-radius: 10px;
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.sponsored-shops-carousel-loading {
  text-align: center;
  color: white;
}

.sponsored-shops-carousel-paper {
  background-position: center center;
  background-size: cover;
  height: 400px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  border-radius: 10px;
}

.sponsored-shops-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  align-content: center !important;
  border: none !important;
  border-radius: 15px !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  text-align: center !important;
  width: 99.9% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsored-shops-carousel-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px;
  border: 1px solid #fafafab3 !important;
}

.sponsored-shops-carousel-title {
  font-size: 2em;
  margin: 0;
}

.sponsored-shops-carousel-button {
  margin-top: 20px;
  color: white;
  background-color: #36d300 !important;
  border-radius: 50px !important;
  padding: 5px 25px !important;
  margin: 25px !important;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.sponsored-shops-carousel-button:hover {
  background-color: #36d300;
}
/* Quad product carousel AD */
.quad-product-ad-carousel-container {
  padding: 0px;
  border-radius: 10px;
  margin: 10px auto;
  overflow: hidden;
  position: relative;
  max-width: 1000px;
  background-color: rgba(0, 0, 0, 0.5);
}

.quad-product-ad-carousel-loading {
  text-align: center;
  color: white;
}

.quad-product-ad-carousel-quad {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.quad-product-ad-carousel-error {
  text-align: center;
  color: red;
  width: 100%;
}

.quad-product-ad-carousel-item {
  position: relative;
  flex: 1 1 48%;
  height: 250px;
  margin: 1%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-size: cover;
  background-position: center;
}

.quad-product-ad-carousel-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
}

.quad-product-ad-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.quad-product-ad-carousel-content {
  position: absolute;
  border-radius: 5px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 99.9% !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quad-product-ad-carousel-title {
  font-size: 1.5em;
  margin: 0;
}

.quad-product-ad-carousel-price {
  font-size: 1.25em;
  margin: 10px 0;
}

.quad-product-ad-carousel-button {
  background-color: #36d300;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.quad-product-ad-carousel-button:hover {
  background-color: #28a200;
}

.sponsored-carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 2;
}

/* Sponsored brand AD carousel*/
.sponsored-brand-ad-carousel {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 16px;
  padding: 20px;
}

.sponsored-brand-ad-carousel-item {
  background-position: center center;
  background-size: cover;
  height: 400px;
  width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.sponsored-brand-ad-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsored-brand-ad-carousel-title {
  font-size: 2em;
  margin: 0;
}

.sponsored-brand-ad-carousel-description {
  font-size: 1.25em;
  margin: 0;
}

.sponsored-brand-ad-carousel-button {
  margin-top: 20px;
  color: white;
  background-color: #36d300;
  border-radius: 50px;
  padding: 5px 25px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.sponsored-brand-ad-carousel-button:hover {
  background-color: #2ba600;
}


/* Dual Product ad carousel */
.dual-product-ad-carousel-container {
  padding: 0px;
  border-radius: 10px;
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.dual-product-ad-carousel-loading {
  text-align: center;
  color: white;
}

.dual-product-ad-carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.dual-product-ad-carousel-pair {
  display: flex;
  width: 100%;
}

.dual-product-ad-carousel-error {
  text-align: center;
  color: red;
  width: 100%;
}

.dual-product-ad-carousel-item {
  position: relative;
  width: 50%;
  height: 400px;
  margin: 1%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.dual-product-ad-carousel-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
}

.dual-product-ad-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dual-product-ad-carousel-content {
  position: absolute;
  border-radius: 5px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 99.9% !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dual-product-ad-carousel-title {
  font-size: 2em;
  margin: 0;
}

.dual-product-ad-carousel-price {
  font-size: 1.25em;
  margin: 10px 0;
}

.dual-product-ad-carousel-button {
  background-color: #36d300;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.dual-product-ad-carousel-button:hover {
  background-color: #28a200;
}

.sponsored-carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 2;
}

.sponsored-carousel-prev {
  left: 10px;
}

.sponsored-carousel-next {
  right: 10px;
}

.sponsored-carousel-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}


/* Banner ad for product*/
.product-banner-ad-container {
    position: fixed;
    z-index: 50;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 25px;
    height: 100px;
    margin-bottom: 20px;

}

.product-banner-ad-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(54, 54, 54, 0.75);
    background-size: cover;
    background-position: center;
    padding: 4px;
    height: 100px;
    border-radius: 8px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
}

.product-banner-ad-link {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: white;
    flex-grow: 1;
    text-decoration: none;
}

.product-banner-ad-strong {
    font-weight: 600;
}

.product-banner-ad-dismiss {
    margin: -12px;
    padding: 12px;
}

.product-banner-ad-icon {
    height: 20px;
    width: 20px;
    color: white;
}

.css-1uch84x {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(31, 156, 41);
    padding: 0.6rem;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    height: 35px !important;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-1hw9j7s {
    background-color: #36d300 !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    margin: 25px 25px !important;
}

.css-ohwg9z {
    position: relative;
    overflow: hidden;
    margin: 25px 0px !important;
}

.quad-product-ad-carousel-container {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 10px;
    margin: 10px auto;
    padding: 20px;
    max-width: 100% !important;
}

.w-10\/12.mx-auto {
    width: 100% !important;
}

.best-deals-homepage-heading {
    color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 27px;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: left !important;
}

.recommended-friends-title {
    color: #ffffff !important;
    font-size: 24px;
    font-weight: 700 !important;
    margin-bottom: 0;
    text-align: left !important;
}

.suggested-friend-list {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    gap: 0;
    justify-content: left;
    overflow-x: auto;
    padding-bottom: 25px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 25px;
}

.css-aoeo82 {
    /*background-color: rgba(250, 250, 250, 0.1) !important;*/
    background-color: transparent !important;
    color: #ffffff !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15px;
    /*border: 1px solid rgba(250, 250, 250, 0.4) !important;*/
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-bottom: none !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
    bottom: 0;
    margin-bottom: -25px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-aoeo82 div {
    padding-bottom: 25px !important;
    text-align: center !important;
    width: 99.9% !important;
    height: 99.9% !important;
    align-content: center !important;
    border: none !important;
    border-radius: 15px !important;
}

.sponsored-product-carousel-title {
    font-size: 24px !important;
    margin: 0;
    font-weight: 700 !important;
    letter-spacing: 2px !important;
}

.w-full.px-12.py-8.lg\:px-16 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.shop-list {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    margin: 25px 0px !important;
    overflow-x: auto;
}

.profile-sidebar-right {
    background-color: transparent !important;
    border: none !important;
    border-radius: 10px;
    margin: 0 !important;
    max-width: 25% !important;
    padding: 0px !important;
}

.sponsored-products-component-container {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: 10px;
    margin: 10px auto;
    max-width: 1000px;
    padding: 20px;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

/* Sponsored product AD */
.sponsored-products-component-container {
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 10px;
  margin: 10px auto;
  max-width: 1000px;
}

.sponsored-products-component-title {
  text-align: center;
  margin-bottom: 20px;
  color: rgba(250, 250, 250, 0.8) !important;
}

.sponsored-products-component-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsored-products-component-item {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid rgba(250, 250, 250, .03) !important;
  border-radius: 10px;
  overflow: hidden;
  position: relative; 
}

.sponsored-products-component-link {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
}

.sponsored-products-component-image-wrapper {
  width: 100%;
  height: 300px; 
  overflow: hidden;
  position: relative; 
  background-color: #f0f0f0; 
}

.sponsored-products-component-image {
  position: absolute; 
  top: 0;
  left: 0;
  transform: translate(
    var(--x-offset, 0px), 
    var(--y-offset, 0px)
  ); 
  object-fit: cover;
}

.sponsored-products-component-info {
  background-color: rgba(84, 82, 82, 0.5);
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sponsored-products-component-product-name {
  font-size: 16px;
  margin: 0;
}

.sponsored-products-component-product-price {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
}

.sponsored-products-component-visit-store {
  font-size: 12px;
  text-decoration: none;
  color: #1f9c29;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
}


.sponsored-brands-widget-button {
    background: #36d300 !important;
    border: none;
    border-radius: 50px !important;
    color: #fff;
    cursor: pointer;
    padding: 5px 25px !important;
    text-decoration: none;
    transition: background .3s ease, transform .3s ease;
}

.sponsored-brands-widget-item {
    align-items: center;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: transform .3s ease, box-shadow .3s ease;
    width: 100% !important;
}


.sponsored-brands-widget-title {
    color: #777777 !important;
    font-size: 12px !important;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-align: center;
}

.profile-handle {
    color: #36d300 !important;
    font-size: 12px !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important;
    margin-top: 0px !important;
}

.profile-name {
    color: #ffffff !important;
    font-size: 25px !important;
    font-weight: 700 !important;
}

.profile-name-wrapper {
    margin-bottom: 0px !important;
    display: contents;
}

.sponsored-products-component-title {
    font-size: 12px !important;
    margin-bottom: 15px !important;
    text-align: center;
}

.user-profile-data-content-container {
    padding: 20px;
    padding-top: 0px !important;
}


.profile-sidebar-left {
    background-color: #fafafa1a !important;
    border: 1px solid #fafafa66 !important;
    border-radius: .375rem;
    order: 2;
    padding: 0 !important;
    width: 25% !important;
    height: 50% !important;
    /* max-height: 500px !important; */
    /*display: block !important;
    position: relative important;*/
}

.sponsored-brands-widget-details {
    align-items: center;
    background-color: rgba(250, 250, 250, 0.1) !important;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
}

.sponsored-brands-widget-container {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border-radius: 10px;
    margin: 0px !important;
    max-width: 1000px;
    padding: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 15px !important;
    margin-bottom: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

.css-miw4ts {
    padding: 15px !important;
    border-radius: 10px !important;
}

.sponsored-brands-widget-item {
    align-items: center;
    background-color: transparent !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: transform .3s ease, box-shadow .3s ease;
    width: 100% !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    background-color: #fafafa33 !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.sponsored-brands-widget-container {
    background-color: rgba(250, 250, 250, 0.0) !important;
    border-radius: 10px;
    margin: 0px !important;
    max-width: 1000px;
    padding: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 15px !important;
    margin-bottom: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.0) !important;
}

.sponsored-products-component-container {
    background-color: transparent !important;
    border: none !important;
    border-radius: 10px;
    margin: 0px !important;
    max-width: 1000px;
    padding: 0px !important;
}

main.main-feed-page-main-content.p-2.w-full.lg\:order-2.main-feed-page-scrollable {
    padding-top: 0px !important;
}

/* Marketplace hero component */
.marketplace-hero-slider {
    position: relative;
    height: 300px;
  }
  
  .marketplace-hero-slide {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    transition: opacity 0.5s ease-in-out;
  }
  
  .marketplace-hero-slide-active {
    opacity: 1;
  }
  
  .marketplace-hero-slide-inactive {
    opacity: 0;
  }
  
  .marketplace-hero-slide-content {
    height: 100%;
    padding: 6em;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .marketplace-hero-slide-title {
    font-size: 4rem;
    font-weight: bold;
  }
  
  .marketplace-hero-slide-button {
    margin-top: 1rem;
    display: inline-block;
    background-color: #22c55e;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .marketplace-hero-slide-button:hover {
    background-color: #15803d;
  }
  
  .marketplace-hero-slider-button {
    position: absolute;
    top: 50%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 0.75rem;
    cursor: pointer;
    transform: translateY(-50%);
  }
  
  .marketplace-hero-slider-button-left {
    left: 1rem;
  }
  
  .marketplace-hero-slider-button-right {
    right: 1rem;
  }
  
  .marketplace-hero-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  

  .marketplace-page-content {
    padding-left: 120px;
    padding-right: 120px;
  }
  
  @media (min-width: 768px) {
    .marketplace-page-content {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  
  @media (min-width: 1024px) {
    .marketplace-page-content {
      padding-left: 120px;
      padding-right: 120px;
    }
  }

  /* Retail marketplace category section */

  .category-marketplace-section-component-bg-black {
    border-bottom: 1px solid #fafafa4d !important;
}

  .category-marketplace-section-component-section-wrapper {
    padding-top: 4rem;
    padding-bottom: 6rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
  }
  
  .category-marketplace-section-component-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .category-marketplace-section-component-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1a1a1a;
  }
  
  .category-marketplace-section-component-browse-link {
    display: none;
    font-size: 0.875rem;
    font-weight: 600;
    transition: color 0.3s ease;
    background-color: #fafafa1a !important;
    border: 1px solid #fafafa66 !important;
    border-radius: 50px !important;
    color: #fff !important;
    margin-bottom: 25px !important;
    padding: 10px 25px !important;
  }
  
  .category-marketplace-section-component-browse-link:hover {
    color: #4f46e5;
  }
  
  @media (min-width: 640px) {
    .category-marketplace-section-component-browse-link {
      display: block;
    }
  }
  
  .category-marketplace-section-component-categories-wrapper {
    margin-top: 1rem;
    overflow: hidden;
  }
  
  .category-marketplace-section-component-categories-inner-wrapper {
    margin-top: -0.5rem;
  }
  
  .category-marketplace-section-component-categories-list {
    position: relative;
    height: 22rem;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .category-marketplace-section-component-categories-list-inner {
    position: absolute;
    display: flex;
    gap: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .category-marketplace-section-component-category-item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 20rem;
    width: 14rem;
    padding: 1.5rem;
    border-radius: 0.75rem;
    transition: opacity 0.3s ease;
  }
  
  .category-marketplace-section-component-category-item:hover {
    opacity: 0.75;
  }
  
  .category-marketplace-section-component-category-image-wrapper {
    position: absolute;
    inset: 0;
  }
  
  .category-marketplace-section-component-category-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10%;
  }
  
  .category-marketplace-section-component-category-overlay {
    position: absolute;
    left:0;
    right:0;
    bottom: 0;
    height: 66.67%;
    background: linear-gradient(to top, #1a1a1a 0%, rgba(26, 26, 26, 0) 100%);
    opacity: 0.5;
  }
  
  .category-marketplace-section-component-category-name {
    position: relative;
    margin-top: auto;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: white;
  }
  
  .category-marketplace-section-component-browse-link-wrapper {
    margin-top: 1.5rem;
    padding-left: 1rem;
  }
  
  .category-marketplace-section-component-browse-link-sm {
    display: block;
    font-size: 0.875rem;
    font-weight: 600;
    color: #4f46e5;
    transition: color 0.3s ease;
  }
  
  .category-marketplace-section-component-browse-link-sm:hover {
    color: #4338ca;
  }
  
  @media (min-width: 640px) {
    .category-marketplace-section-component-browse-link-wrapper {
      display: none;
    }
  }
  
/* Shop dashboard hero */

.shop-dashboard-hero-header-container {
  width: 100%;
  padding: 2rem;
}

.shop-dashboard-hero-header-title {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 0.5rem;
}

.shop-dashboard-hero-header-overview {
  display: block;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 800px) {
  .shop-dashboard-hero-header-overview {
      display: flex;
  }
}

.shop-dashboard-hero-header-card {
  width: 100%;
  margin-bottom: 1rem;
  min-height: 20vh;
  background-color: rgba(250, 250, 250, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #fafafa66;
  border-radius: 0.5rem;
  padding: 2rem 1rem;
}

@media (min-width: 800px) {
  .shop-dashboard-hero-header-card {
      width: 30%;
  }
}

.shop-dashboard-hero-header-card-content {
  display: flex;
  align-items: center;
}

.shop-dashboard-hero-header-card-title {
  font-size: 18px;
  font-weight: 400;
  color: #00000085;
  line-height: 1.25rem;
}

.shop-dashboard-hero-header-card-subtitle {
  font-size: 16px;
}

.shop-dashboard-hero-header-card-value {
  padding-top: 0.5rem;
  padding-left: 2.25rem;
  font-size: 22px;
  font-weight: 500;
}

.shop-dashboard-hero-header-card-link {
  padding-top: 1rem;
  padding-left: 0.5rem;
  color: #077f9c;
  text-decoration: none;
}

.shop-dashboard-hero-header-latest-orders {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 0.5rem;
}

.shop-dashboard-hero-header-orders-container {
  width: 100%;
  min-height: 45vh;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
}

.shop-dashboard-hero-header-orders-table {
  width: 100%;
  min-height: 45vh;
}

.shop-dashboard-hero-header-data-grid {
  width: 100%;
  border-collapse: collapse;
}

.shop-dashboard-hero-header-data-grid th,
.shop-dashboard-hero-header-data-grid td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #2d3748;
}

.shop-dashboard-hero-header-data-grid th {
  background-color: rgba(250, 250, 250, 0.1);
  color: #f7fafc;
}

.shop-dashboard-hero-header-data-grid td {
  color: #f7fafc;
}

.shop-dashboard-hero-header-data-grid td button {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

.shop-dashboard-hero-all-orders-pending-row {
  background-color: rgba(250, 250, 250, 0.2);
}

/* Shop dashboard sidebar */
.shop-dashboard-sidebar-container {
  width: 60px; 
  height: 110vh;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow-y: scroll;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  transition: width 0.3s ease; 
}

.shop-dashboard-sidebar-container:hover {
  width: 150px;
}

.shop-dashboard-sidebar-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.shop-dashboard-sidebar-link {
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.shop-dashboard-sidebar-link h5 {
  display: none;
  padding-left: 4px;
  font-size: 12px;
  font-weight: 400;
  opacity: 0; 
  transition: opacity 0.3s ease; 
  white-space: nowrap; 
}

.shop-dashboard-sidebar-container:hover .shop-dashboard-sidebar-link h5 {
  display: block;
  opacity: 1; 
}

.shop-dashboard-sidebar-link .icon {
  font-size: 25px;
  margin-right: 0.5rem; 
  transition: none; 
}

.shop-dashboard-sidebar-link.active {
  color: crimson;
}

.shop-dashboard-sidebar-link.inactive {
  color: #555;
}

.shop-dashboard-sidebar-profile-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #026506;
}

@media (min-width: 800px) {
  .shop-dashboard-sidebar-container {
      width: 60px; 
  }

  .shop-dashboard-sidebar-container:hover {
      width: 220px; 
  }

  .shop-dashboard-sidebar-link h5 {
      display: none;
  }

  .shop-dashboard-sidebar-container:hover .shop-dashboard-sidebar-link h5 {
      display: block;
      opacity: 1;
  }
}





/* Edit product in shop dashboard component */

.edit-product-component-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.edit-product-component-section {
  border-bottom: 1px solid #2d3748;
  padding-bottom: 2rem;
}

.edit-product-component-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
}

.edit-product-component-subtitle {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #a0aec0;
}

.edit-product-component-grid {
  margin-top: 1.5rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 640px) {
  .edit-product-component-grid {
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5rem;
  }
}

.edit-product-component-col-span-1 {
  grid-column: span 1 / span 1;
}

.edit-product-component-col-span-3 {
  grid-column: span 3 / span 3;
}

.edit-product-component-col-span-4 {
  grid-column: span 4 / span 4;
}

.edit-product-component-col-span-6 {
  grid-column: span 6 / span 6;
}

.edit-product-component-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #a0aec0;
}

.edit-product-component-input,
.edit-product-component-textarea,
.edit-product-component-file-input {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  background-color: #4a5568;
  border: none;
  padding: 0.5rem 0.75rem;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.edit-product-component-input:focus,
.edit-product-component-textarea:focus,
.edit-product-component-file-input:focus {
  border-color: #5a67d8;
  box-shadow: 0 0 0 3px rgba(90, 103, 216, 0.45);
}

.edit-product-component-checkbox-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: #a0aec0;
}

.edit-product-component-checkbox {
  margin-right: 0.5rem;
  border-radius: 0.375rem;
  background-color: #4a5568;
  color: #5a67d8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.edit-product-component-file-input-container {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #a0aec0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.edit-product-component-submit-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: right;
  border: none;
  border-radius: 0.375rem;
  background-color: #38a169;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-product-component-submit-button:hover {
  background-color: #2f855a;
}

.edit-product-component-submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(56, 161, 105, 0.45);
}

.edit-product-component-image-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.edit-product-component-product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}


/* new additions*/
.sponsored-brands-widget-item {
    padding: 0px !important;
    margin: 0px !important;
}

.MuiBox-root.css-miw4ts {
    margin: 15px 10px !important;
}

.mx-auto.flex.w-full.flex-col.lg\:flex-row.items-start.gap-x-8.px-4.py-10.sm\:px-6.lg\:px-24 {
    column-gap: 20px !important;
}

.user-profile-data-tab-content .MuiBox-root.css-miw4ts {
    margin: 0px !important;
}

aside.profile-sidebar-right {
    padding: 15px !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

/* Menu avatar dropdown */
.avatar-dropdown-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  z-index: 10;
}

.avatar-dropdown-menu-popup {
  background: rgba(54, 54, 54, 0.7);
  width: 400px;
  max-width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 10%;
  padding: 20px;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
}

.avatar-dropdown-menu-close-btn-container {
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}

.avatar-dropdown-menu-close-btn {
  cursor: pointer;
}

.avatar-dropdown-menu-header {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.avatar-dropdown-menu-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  border-width: 2px;
  border-color: #fff;
  cursor: pointer;
}

.avatar-dropdown-menu-items {
  border-top: 1px solid #ddd;
}

.avatar-dropdown-menu-item {
  border-bottom: 1px solid #ddd;
  padding: 16px 0;
}

.avatar-dropdown-menu-link {
  color: #555;
  text-decoration: none;
  display: block;
  padding: 8px 16px;
}

.avatar-dropdown-menu-link:hover {
  background-color: #f1f1f1;
  color: rgba(0,0,0,0.5);
}

.avatar-dropdown-menu-link-cursor {
  cursor: pointer;
}

.avatar-dropdown-menu-subscription-icon-container {
  padding: 16px;
}

.avatar-dropdown-menu-subscription-icon {
  width: 1.5rem;
  height: 1.5rem;
}



.profile-sidebar-left {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 10px;
    height: inherit !important;
    order: 2;
    padding: 0 !important;
    width: 25% !important;
}

/*
.product-details-component__py-5 {
    padding-bottom: 20px;
    padding-top: 20px;
    margin: 25px 7% !important;
}*/

.product-details-component__content-bg {
    background-color: transparent !important;
    margin: 25px 7% !important;
}

.product-details-component__info-container {
    background-color: transparent !important;
    border-radius: .5rem;
    padding: 0px !important;
}

.p-4.w-10\/12.mx-auto {
    margin: 25px 7% !important;
    padding: 0px !important;
}

.user-profile-data-content-container {
    padding: 10px !important;
    padding-top: 0 !important;
}

.sponsored-brands-widget-container {
    background-color: #fafafa00 !important;
    border: 1px solid #fafafa00 !important;
    border-radius: 10px;
    margin: 0 0 25px !important;
    max-width: 1000px;
    padding: 10px !important;
}

.profile-page {
    background-color: rgba(250, 250, 250, 0.0) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
}

.sidebar-container {
    background-color: rgba(12, 17, 23, 0.7) !important;
    border-radius: 0 .5rem .5rem 0;
    box-shadow: 2px 0 10px #0000001a;
    width: 4rem;
}

button.h-full.w-full.flex.justify-between.items-center.pl-10.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

button.h-full.w-full.flex.justify-between.items-center.pl-10.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.1) !important;
    border-bottom: 0px !important;
}

/*Filter Button Dropdown*/
.text-gray-700 {
    --tw-text-opacity: 1;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    color: #fafafa !important;
    padding: 5px 10px !important;
    border-radius: 50px !important;
}

select#price-filter {
    color: #999999 !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    padding: 10px 25px !important;
    border-radius: 50px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div {
    background-color: transparent !important;
    border: none !important;
}

.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, 1.0) !important;
    margin-top: 15px !important;
}

a.hidden.text-sm.font-semibold.text-green-600.hover\:text-green-500.sm\:block {
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 50px !important;
    color: #fff !important;
    padding: 10px 25px !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

a.hidden.text-sm.font-semibold.text-green-600.hover\:text-indigo-500.sm\:block {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 50px !important;
    color: #fff !important;
    margin-bottom: 25px !important;
    padding: 10px 25px !important;
}

.w-10\/12.mx-auto.w-\[90\%\].\38 00px\:w-\[60\%\] {
    margin: 0px 7% !important;
}

.notification-sidebar-panel {
    background-color: rgba(12, 17, 23, 0.9) !important;
    max-width: 28rem;
    pointer-events: auto;
    width: 100%;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
}

.notification-sidebar-panel-content {
    background-color: transparent !important;
}

.notification-sidebar-panel {
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 100% !important;
}

.cart-sidebar-popup {
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 100% !important;
}

.avatar-dropdown-menu-popup {
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 400px;
}

.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 400px;
}

.notification-sidebar-panel {
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 25% !important;
}

.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10 {
    border-top: 0 !important;
    margin-top: 99px !important;
}

.flex.h-full.flex-col.overflow-y-scroll.bg-white.shadow-xl {
    background-color: transparent !important;
}

.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10.sm\:pl-16 {
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 400px;
}



.text-gray-700 {
    --tw-text-opacity: 1;
    border: none !important;
    border-radius: 0px !important;
    color: #fafafa !important;
    padding: 0px !important;
}

label.block.text-sm.font-medium.text-gray-700 {
    background-color: transparent !important;
}

elect#price-filter {
    border: 1px solid #fafafa66 !important;
    border-radius: 50px !important;
    color: #999 !important;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    padding: 10px 25px !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.shop-info-sidebar-actions {
    display: flex;
    flex-direction: column;
    gap: 25px !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-a4r3a9 {
    padding: 5px 15px !important;
    margin: 0px !important;
    margin-bottom: 0px !important;
}

.MuiBox-root.css-1ug174f, .bg-white {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 10px !important;
}

.shop-profile-data-tab-content {
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 10px !important;
    padding: 25px !important;
}

.shop-list {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    margin: 25px 7% !important;
    overflow-x: auto;
}
  
/* Search box header component*/
.search-box-component-container {
    width: 25%;
    position: relative;
  }
  
  .search-box-component-input {
    height: 40px;
    width: 100%;
    padding-left: 32px; 
    border: 1px solid rgba(250, 250, 250, 0.4) !important; 
    border-radius: 2rem;
    background-color: rgba(250, 250, 250, 0.1);
  }
  
  .search-box-component-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .search-box-component-search-icon {
    left: 8px;
  }
  
  .search-box-component-close-icon {
    right: 8px; 
  }
  
  .search-box-component-dropdown {
    position: absolute;
    min-height: 30vh;
    max-height: 60vh;
    overflow-y: auto;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 9;
    padding: 16px;
    border-radius: 8px;
  }
  
  .search-box-component-dropdown-item {
    display: flex;
    align-items: start;
    padding: 8px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-box-component-dropdown-item:hover {
    background-color: rgba(250, 250, 250, 0.2); 
    border-radius: 8px;
  }
  
  .search-box-component-item-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .search-box-component-item-name {
    font-size: 1rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .search-box-component-item-details {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }
  
  .search-box-component-item-brand,
  .search-box-component-item-shop {
    font-size: 0.75rem;
    font-weight: 600;
    padding: 2px 4px;
    border-radius: 4px;
    margin-right: 4px;
  }
  
  .search-box-component-item-brand {
    background-color: rgba(34, 197, 94, 0.2); 
    color: rgba(34, 197, 94, 1); 
  }
  
  .search-box-component-item-shop {
    background-color: rgba(34, 197, 94, 0.2);
    color: rgba(34, 197, 94, 1); 
  }
  
  /* My post widget */

.my-post-widget-wrapper {
  color: #fff !important;
  border: 1px solid #fafafa66 !important;
  border-radius: 10px !important;
  padding: 15px !important;
  background-color: #fafafa1a !important;
}

.my-post-widget-input-base {
  width: 100%;
  background-color: rgba(250, 250, 250, 0.1);
  border: 0.5px solid rgba(250, 250, 250, 0.9);
  border-radius: 1rem;
  padding: 1rem 2rem;
  color: rgba(250, 250, 250, 0.9) !important;
}

.my-post-widget-link-post-preview-container {
  display: flex;
  align-items: flex-start;
}

.my-post-widget-link-post-preview-image {
  width: 80px;
  height: 80px;
  margin-right: 1rem;
}

.my-post-widget-link-post-preview-content {
  flex: 1;
}

.my-post-widget-link-post-preview-title {
  font-weight: bold;
}

.my-post-widget-link-post-preview-description {
  color: gray;
}

.my-post-widget-link-post-preview-url {
  color: blue;
}

.my-post-widget-divider {
  border-color: #fafafa4d !important;
  margin-top: 25px !important;
  margin-bottom: 25px;
  margin: 1.25rem 0px;
  color: #fafafae6 !important;
}

.my-post-widget-image-video-container {
  margin-top: 25px !important;
}

.my-post-widget-icon {
  color: #808080;
}

.my-post-widget-typography:hover {
  cursor: pointer;
  color: #808080;
}

.my-post-widget-post-button {
  background-color: #46d300 !important;
  border-radius: 50px !important;
  color: #fafafa !important;
  width: 20% !important;
}

.my-post-widget-image-dropzone-container,
.my-post-widget-video-dropzone-container {
  border: 1px solid #808080;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 1rem;
}

.my-post-widget-dropzone-inner {
  display: flex;
  align-items: center;
}

.my-post-widget-dropzone-box {
  border: 2px dashed #1bab27;
  padding: 1rem;
  width: 100%;
}

.my-post-widget-dropzone-box:hover {
  cursor: pointer;
}

.my-post-widget-delete-icon-button {
  width: 15%;
}

.my-post-widget-image-preview,
.my-post-widget-video-preview {
  width: 100%;
  border-radius: 5px;
}

.my-post-widget-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-post-widget-add-more-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.my-post-widget-add-more-button:hover {
  background-color: #218838;
}

  
  /* Post widget CSS */

.post-widget-form {
    width: 100%;
  }
  
  .post-widget-edit-description-textarea {
    width: 100%;
    background: none;
    border: none;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    resize: none;
    outline: none;
  }
  
  .post-widget-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 0.75rem;
  }
  
  .post-widget-save-button,
  .post-widget-cancel-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    transition: background-color 0.2s ease-in-out;
  }
  
  .post-widget-save-button {
    background-color: #16a34a;
    color: white;
  }
  
  .post-widget-save-button:hover {
    background-color: #15803d;
  }
  
  .post-widget-cancel-button {
    background-color: #d1d5db;
    color: #374151;
  }
  
  .post-widget-cancel-button:hover {
    background-color: #9ca3af;
  }
  
  .post-widget-hashtag {
    color: green;
    cursor: pointer;
  }
  
  .post-widget-wrapper {
    position: relative;
    margin: 2rem 0;
    background-color: #fafafa1a !important;
    border: 1px solid #fafafa4d !important;
  }
  
  .post-widget-popover {
    position: relative;
    float: right !important;
  }
  
  .post-widget-popover-button {
    display: inline-flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827;
  }
  
  .post-widget-icon {
    height: 2.5rem;
    width: 2.5rem;
  }
  
  .post-widget-transition-enter {
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  }
  
  .post-widget-transition-enter-from {
    opacity: 0;
    transform: translateY(0.25rem);
  }
  
  .post-widget-transition-enter-to {
    opacity: 1;
    transform: translateY(0);
  }
  
  .post-widget-transition-leave {
    transition: opacity 0.15s ease-in, transform 0.15s ease-in;
  }
  
  .post-widget-transition-leave-from {
    opacity: 1;
    transform: translateY(0);
  }
  
  .post-widget-transition-leave-to {
    opacity: 0;
    transform: translateY(0.25rem);
  }
  
  .post-widget-popover-panel {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    background-color: rgba(250, 250, 250, 0.2) ;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: solid 1px;
    border-color: rgba(250, 250, 250, 0.9);
    right: 0rem;
  }
  
  .post-widget-popover-content {
    padding: 0.5rem 0;
  }
  
  .post-widget-popover-item {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: rgba(250, 250, 250, 1);
  }
  
  .post-widget-reshare {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .post-widget-reshare-header {
    display: flex;
    align-items: left;
  }
  
  .post-widget-reshare-avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .post-widget-reshare-by {
    font-weight: bold;
  }
  
  .post-widget-reshare-description {
    margin-left: 40px;
    margin-top: 5px;
  }
  
  .post-widget-reshare-container {
    display: flex;
    flex-direction: column;
  }

  
  .post-widget-reshare-textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    margin-bottom: 0.5rem;
  }
  
  .post-widget-reshare-button {
    background-color: #16a34a;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .post-widget-reshare-button:hover {
    background-color: #15803d;
  }
  
  .post-widget-cancel-button {
    background-color: #d1d5db;
    color: #374151;
  }
  
  .post-widget-cancel-button:hover {
    background-color: #9ca3af;
  }
  
  .post-widget-date {
    font-size: 10px;
    color: #6b7280;
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  
  .post-widget-media-container {
    margin-top: 0.75rem;
  }
  
  .post-widget-image-gallery {
    display: flex;
    flex-direction: column;
  }
  
  .post-widget-image {
    border-radius: 0.75rem;
    margin-top: 0.5rem;
  }
  
  .post-widget-video-gallery {
    display: flex;
    flex-direction: column;
  }
  
  .post-widget-video {
    border-radius: 0.75rem;
    margin-top: 0.5rem;
  }
  
  .post-widget-like-icon {
    width: 24px;
    height: 24px;
  }
  
  .post-widget-comment-container {
    display: flex;
    align-items: flex-start;
    padding: 10px;
  }
  
  .post-widget-comment-avatar-container {
    flex-shrink: 0;
  }
  
  .post-widget-comment-avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
  
  .post-widget-comment-content {
    margin-left: 0.5rem;
    flex-grow: 1;
  }
  
  .post-widget-comment-author {
    font-weight: bold;
  }
  
  .post-widget-comment-text-box {
    margin-top: 0.25rem;
  }
  
  .post-widget-comment-edit-input {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    padding: 0.25rem;
  }
  
  .post-widget-comment-text {
    display: block;
  }
  
  .post-widget-comment-popover {
    position: relative;
    display: inline-block;
  }
  
  .post-widget-comment-options-icon {
    cursor: pointer;
  }
  
  .post-widget-comment-options-panel {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .post-widget-comment-options-container {
    padding: 0.5rem 0;
  }
  
  .post-widget-comment-option-button {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .post-widget-comment-save-button {
    background-color: #16a34a;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .post-widget-comment-save-button:hover {
    background-color: #15803d;
  }
  
  .post-widget-comment-input-container {
    margin-top: 1.5rem;
  }
  
  .post-widget-comment-input-flex {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .post-widget-comment-input-flex-1 {
    flex: 1;
  }
  
  .post-widget-comment-input-form {
    position: relative;
  }
  
  .post-widget-comment-input {
    width: 100%;
    resize: none;
    border: none;
    background: transparent;
    padding: 0.375rem 0.5rem;
    color: white;
    font-size: 0.875rem;
  }
  
  .post-widget-comment-submit-container {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
   
  }
  
  .post-widget-comment-submit-button {
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    background-color: #46d300 !important;
    border-radius: 50px !important;
    margin-bottom: 0 !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    font-size: 1rem !important;
    line-height: 0.75rem;
  }
  
  .post-widget-comment-submit-button:hover {
    background-color: #15803d;
  }

  .post-widget-comment-options {
    position: relative;
  }
  
  .post-widget-report-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.5);
    padding: 2rem;
    border: 2px solid #000;
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    width: 400px;
  }
  
  .post-widget-report-modal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .post-widget-report-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .post-widget-report-submit-button {
    background-color: #46d300;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
  }
  
  .post-widget-report-cancel-button {
    background-color: #d1d5db;
    color: #374151;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
  }
  
  /* Following/follower panel*/

  .followers-following-panel-container {
    position: relative;
    z-index: 10;
    background-color: #0c1117e6 !important;
    border-left: 1px solid #fafafa66 !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 25% !important;
  }
  
  .followers-following-panel-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .followers-following-panel-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
  }
  
  .followers-following-panel-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  
  .followers-following-panel-content {
    pointer-events: none;
    display: flex;
    max-width: 100%;
    padding-left: 10px;
    border: 1px solid #fafafa66 !important;
    border-radius: 10px !important;
    background-color: #0c1117e6 !important;
    border-left: 1px solid #fafafa66 !important;
    box-shadow: none !important;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 400px;
  }
  
  .followers-following-panel-enter {
    transition: transform 0.5s ease-in-out;
  }
  
  .followers-following-panel-enter-from {
    transform: translateX(100%);
  }
  
  .followers-following-panel-enter-to {
    transform: translateX(0);
  }
  
  .followers-following-panel-leave {
    transition: transform 0.5s ease-in-out;
  }
  
  .followers-following-panel-leave-from {
    transform: translateX(0);
  }
  
  .followers-following-panel-leave-to {
    transform: translateX(100%);
  }
  
  .followers-following-panel-panel {
    pointer-events: auto;
    width: 100vw;
    max-width: 28rem;
    border: 1px solid #fafafa66 !important;
    border-radius: 10px !important;
    background-color: #0c1117e6 !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
  }
  
  .followers-following-panel-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .followers-following-panel-header {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .followers-following-panel-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #1a202c;
  }
  
  .followers-following-panel-close-button-wrapper {
    margin-left: 0.75rem;
    display: flex;
    height: 1.75rem;
    align-items: center;
  }
  
  .followers-following-panel-close-button {
    border-radius: 0.375rem;
    color: #718096;
    transition: color 0.3s ease;
  }
  
  .followers-following-panel-close-button:hover {
    color: rgba(250, 250, 250, 1);
  }
  
  .followers-following-panel-close-button:focus {
    outline: 2px solid #36D300;
    outline-offset: 2px;
  }
  
  .followers-following-panel-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  
  .followers-following-panel-tabs {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .followers-following-panel-tabs-nav {
    display: flex;
    gap: 2rem;
  }
  
  .followers-following-panel-tab {
    padding: 1rem 0.25rem;
    border-bottom: 2px solid transparent;
    font-size: 0.875rem;
    font-weight: 500;
    color: #a0aec0;
    transition: color 0.3s ease, border-color 0.3s ease;
  }
  
  .followers-following-panel-tab-active {
    border-color: #38a169;
    color: #36d300;
  }
  
  .followers-following-panel-tab-inactive:hover {
    color: #2d3748;
    border-color: #e2e8f0;
  }
  
  /* 4 slides carousel component*/

  .carousel-4-slides-component-paper {
    background-position: center center;
    background-size: cover;
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    
}

.carousel-4-slides-component-content {
    background: rgba(0, 0, 0, 0.5); 
    padding: 20px;
    align-content: center !important;
    border: none !important;
    border-radius: 15px !important;
    height: 99.9% !important;
    padding-bottom: 25px !important;
    text-align: center !important;
    width: 99.9% !important;
}

.carousel-4-slides-component-content h2 {
    font-size: 2em;
}

.carousel-4-slides-component-content p {
    font-size: 1.25em;
}
.carousel-4-slides-component-title {
    font-size: 2em;
}

.carousel-4-slides-component-description {
    font-size: 1.25em;
    margin-bottom: 1rem;
}

.carousel-4-slides-component-button {
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 40px !important;
    transition: background-color 0.3s ease;
    background-color: #36d300 !important;
    border-radius: 50px !important;
    padding: 5px 25px !important;
    margin: 25px 25px !important;
}

.carousel-4-slides-component-button:hover {
    background-color: #45a049;
}

/* Events page */

.events-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.events-page-container {
    display: flex;
    flex-grow: 1;
}

.events-page-sidebar {
    width: 25%;
    border-right: 1px solid #e2e8f0;
    padding: 20px;
    background-color: #f7fafc;
}

.events-page-main-content {
    width: 75%;
    padding: 20px;
    margin-left: 120px;
    margin-top: 20px;
}

.events-page-filters {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
}

.events-page-select {
    background-color: white;
    border: 1px solid #38a169;
    color: #4a5568;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.events-page-select:focus {
    outline: none;
    background-color: white;
    border-color: #38a169;
}

.events-page-events-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.events-page-aside {
    width: 25%;
    padding: 20px;
    border-left: 1px solid #e2e8f0;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100vh
}

.events-page-create-event-button-container {
    margin-bottom: 20px;
}

.events-page-create-event-button {
    background-color: #36d300;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.events-page-create-event-button:hover {
    background-color: #36d300;
}

.events-page-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.events-page-modal-box {
    background-color: rgba(250, 250, 250, 0.1);
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    align-content: center;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}

.events-page-modal-title {
    text-align: center;
    font-size: 24px;
    color: white;
    margin-bottom: 20px;
}


/* Event creation form component */


.event-creation-form-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: rgba(250, 250, 250, 0.2);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-creation-form-label {
    display: block;
    margin-bottom: 5px;
    color: #cbd5e0;
    font-size: 14px;
    font-weight: 500;
}

.event-creation-form-input,
.event-creation-form-textarea,
.event-creation-form-file-input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 7px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(250, 250, 250, 0.8);
    color: #e2e8f0;
    font-size: 16px;
    transition: border-color 0.3s, background-color 0.3s;
}

.event-creation-form-input:focus,
.event-creation-form-textarea:focus,
.event-creation-form-file-input:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.9);
    border-color: #36d300;
}

.event-creation-form-file-input {
    font-size: 14px;
    padding: 8px 12px;
    cursor: pointer;
}

.event-creation-form-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #667eea;
    color: white;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
}

.event-creation-form-button:hover {
    background-color: #5a67d8;
}

.event-creation-form-date-time-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.event-creation-form-date-time-row > div {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* Events card component */

.event-card-component-card {
  position: relative;
  margin: 20px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card-component-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.event-card-component-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.event-card-component-card:hover .event-card-component-image {
  transform: scale(1.05);
}

.event-card-component-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 20%, transparent 100%);
  color: white;
  padding: 20px;
  transition: background 0.5s ease;
}

.event-card-component-title {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
}

.event-card-component-description {
  font-size: 16px;
  margin: 10px 0;
}

.event-card-component-datetime {
  margin: 10px 0;
}

.event-card-component-date,
.event-card-component-time,
.event-card-component-location {
  margin: 5px 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
}

.event-card-icon {
  margin-right: 8px;
}

.event-card-component-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.event-card-component-button {
  background-color: #ffb511;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.event-card-component-button:hover {
  background-color: #ffa733;
}

.event-card-component-cancel-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.event-card-component-cancel-button:hover {
  background-color: #c82333;
}

.event-card-component-calendar-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  background: rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  padding: 10px;
  
}

.event-card-component-calendar-buttons > * {
  flex: 1;
}



/* Events creation calendar */

.custom-calendar {
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    background-color: rgba(0,0,0,0.8);
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.calendar-select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
}

.calendar-year-input {
    width: 80px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: rgba(0, 0, 0, 0.9);
    font-size: 16px;
    text-align: center;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.calendar-day-header {
    font-weight: bold;
    text-align: center;
}

.calendar-day {
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

.calendar-day.selected {
    background-color: #36d300;
    color: white;
    border-radius: 4px;
}

.calendar-empty {
    visibility: hidden;
}


/* Admin all orders*/

.admin-all-orders-container {
    width: 100%;
    display: flex;
  }
  
  .admin-all-orders-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .admin-all-orders-sidebar {
    width: 80px;
  }
  
  @media (min-width: 800px) {
    .admin-all-orders-sidebar {
      width: 325px;
    }
  }
  
  .admin-all-orders-main {
    width: 100%;
    min-height: 45vh;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
  }
  
  .admin-all-orders-table-container {
    width: 97%;
    display: flex;
    justify-content: center;
  }
  
  .admin-all-orders-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .admin-all-orders-thead th,
  .admin-all-orders-tbody td {
    border: 1px solid rgba(250, 250, 250, 0.4);
    padding: 12px;
    text-align: left;
  }
  
  .admin-all-orders-thead {
    background-color: rgba(250, 250, 250, 0.2);
    font-weight: bold;
  }
  
  .greenColor {
    color: #3d6000;
  }
  
  .redColor {
    color: red;
  }
  
  
  /* Brand Page Shop Dashboard*/

  .brand-page-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .brand-page-main-container {
    max-width: 1200px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .brand-page-grid-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  
  .brand-page-button {
    background-color: #36d300;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .brand-page-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background-color: black;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.5);
    padding: 32px;
    z-index: 1000;
  }
  
  .brand-page-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .brand-page-modal-title {
    color: white;
    font-size: 24px;
  }
  
  .brand-page-modal-close-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
  }
  
  .brand-page-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  /* Owned brand */
  .owned-brand-component-container {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: rgba(250, 250, 250, 0.2);
  }
  
  .owned-brand-component-cover-image {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    position: relative;
    text-align: center;
    overflow: hidden;
  }
  
  .owned-brand-component-avatar-container {
    position: relative;
    text-align: center;
    margin-top: -25px;
  }
  
  .owned-brand-component-avatar-image {
    width: 100px;
    height: 100px;
    border: 3px solid white;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .owned-brand-component-camera-icon-container {
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    z-index: 20;
    padding: 1px;
  }
  
  .owned-brand-component-brand-name {
    font-size: 24px;
    color: #000000;
    font-weight: bold;
    margin: 10px 0 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
  }
  
  .owned-brand-component-brand-description {
    font-size: 16px;
    color: #000000;
    margin: 0 auto;
    max-width: 80%;
    line-height: 1.5;
    text-align: center;
  }
  
  .owned-brand-component-textarea {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    background: #000000;
    color: rgba(250, 250, 250, 0.2);
  }
  
  .owned-brand-component-button {
    margin-top: 20px;
    background-color: #46d300;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .owned-brand-component-button.cancel {
    margin-left: 10px;
    background-color: grey;
  }
  
  .owned-brand-component-flex-center {
    display: flex;
    justify-content: center;
  }
  
  .owned-brand-component-add-catalog-item {
    background-color: #006400;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  
  .owned-brand-component-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .owned-brand-component-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    background-color: rgba(250, 250, 250, 0.2);
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.5);
    padding: 32px;
    z-index: 1000;
    overflow-y: auto;
    height: 900px;
  }

  /* Brand catalog itme create */

  .brand-catalog-item-create-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(250, 250, 250, 0.2);
  }
  
  .brand-catalog-item-create-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .brand-catalog-item-create-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
  }
  
  .brand-catalog-item-create-input,
  .brand-catalog-item-create-textarea,
  .brand-catalog-item-create-select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    background-color: rgba(250, 250, 250, 0.4);
    color: rgba(0, 0, 0, 1);
    font-size: 0.875rem;
  }
  
  .brand-catalog-item-create-textarea {
    resize: vertical;
  }
  
  .brand-catalog-item-create-file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 16px;
    background-color: rgba(250, 250, 250, 0.2);
    text-align: center;
  }
  
  .brand-catalog-item-create-file-upload input[type="file"] {
    display: none;
  }
  
  .brand-catalog-item-create-file-upload-label {
    display: inline-block;
    cursor: pointer;
    background-color: #e0e0e0;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 8px;
  }
  
  .brand-catalog-item-create-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;
  }
  
  .brand-catalog-item-create-preview-item {
    position: relative;
    border: 1px solid #4caf50;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .brand-catalog-item-create-preview-item img,
  .brand-catalog-item-create-preview-item video {
    width: 96px;
    height: 96px;
    object-fit: cover;
  }
  
  .brand-catalog-item-create-remove-button {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
  }
  
  .brand-catalog-item-create-checkbox-group {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  
  .brand-catalog-item-create-checkbox-group input {
    margin-right: 8px;
  }
  
  .brand-catalog-item-create-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding-top: 16px;
  }
  
  .brand-catalog-item-create-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .brand-catalog-item-create-button.cancel {
    background-color: #e0e0e0;
    color: rgba(0,0,0,0.5);
  }
  
  .brand-catalog-item-create-button.submit {
    background-color: #4caf50;
    color: #fff;
  }

  /* brand catalog component*/

  .brand-catalog-component-container {
    background-color: rgba(0, 0, 0, 0.8);
    display: inline-block; 
    padding: 20px; 
    border-radius: 10px; 
}
  
  .brand-catalog-component-inner {
    max-width: 1280px;
    margin: 0 auto;
    padding: 64px 16px;
  }
  
  .brand-catalog-component-title {
    font-size: 2rem;
    font-weight: bold;
    color: rgba(0,0,0,0.5)333;
    text-align: center;
  }
  
  .brand-catalog-component-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px 16px;
  }
  
  @media (min-width: 640px) {
    .brand-catalog-component-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .brand-catalog-component-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 48px;
    }
  }
  
  .brand-catalog-component-item {
    position: relative;
    cursor: pointer;
  }
  
  .brand-catalog-component-item-image-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 0.5rem;
  }
  
  .brand-catalog-component-item-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .brand-catalog-component-item-details {
    margin-top: 16px;
    text-align: center;
  }
  
  .brand-catalog-component-item-name {
    font-size: 1rem;
    font-weight: medium;
    color: rgba(0,0,0,0.5)333;
  }
  
  .brand-catalog-component-item-category {
    margin-top: 4px;
    font-size: 0.875rem;
    color: #666666;
  }
  
  .brand-catalog-component-item-price {
    margin-top: 8px;
    font-size: 1rem;
    font-weight: bold;
    color: #4caf50;
  }
  
  /* Modal styles */
  .brand-catalog-component-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .brand-catalog-component-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1024px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 32px;
    overflow-y: auto;
  }
  
  .brand-catalog-component-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .brand-catalog-component-modal-close {
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  
  .brand-catalog-component-modal-content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }

  .brand-catalog-component-edit-form {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .brand-catalog-component-modal-content {
      flex-direction: row;
      gap: 32px;
    }
  }
  
  .brand-catalog-component-modal-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .brand-catalog-component-modal-main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  
  .brand-catalog-component-modal-thumbnails {
    display: flex;
    gap: 8px;
    overflow-x: auto;
  }
  
  .brand-catalog-component-modal-thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 2px solid transparent;
  }
  
  .brand-catalog-component-modal-thumbnail.selected {
    border-color: #4caf50;
  }
  
  .brand-catalog-component-modal-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .brand-catalog-component-modal-title {
    font-size: 2rem;
    font-weight: bold;
    color: rgba(0,0,0,0.5)333;
  }
  
  .brand-catalog-component-modal-description {
    font-size: 1rem;
    color: #666666;
  }
  
  .brand-catalog-component-modal-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #4caf50;
  }
  
  .brand-catalog-component-modal-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .brand-catalog-component-modal-details-item {
    font-size: 1rem;
    color: rgba(0,0,0,0.5)333;
  }
  
  .brand-catalog-component-modal-gallery {
    margin-top: 16px;
  }
  
  .brand-catalog-component-modal-gallery-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: rgba(0,0,0,0.5)333;
  }
  
  .brand-catalog-component-modal-gallery-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .brand-catalog-component-modal-gallery-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .brand-catalog-current-images-container {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .brand-catalog-current-image-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  
  .brand-catalog-component-modal-actions {
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: flex;
    gap: 8px;
  }
  
  .brand-catalog-component-modal-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .brand-catalog-component-modal-button.close {
    background-color: #e0e0e0;
    color: rgba(0,0,0,0.5);
  }
  
  .brand-catalog-component-modal-button.edit {
    background-color: #4caf50;
    color: #fff;
  }
  
  .brand-catalog-component-edit-label {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 8px;
  }

  
.brand-catalog-component-edit-input,
.brand-catalog-component-edit-textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.8);
}

.brand-catalog-component-edit-textarea {
  resize: vertical;
}

.brand-catalog-component-edit-checkbox-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.brand-catalog-component-edit-checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.brand-catalog-component-edit-checkbox-label {
  font-size: 14px;
  color: #fff;
}


.brand-catalog-component-modal-button.save,
.brand-catalog-component-modal-button.edit {
  background-color: #4caf50;
  color: #fff;
}

/* Brand create component */

.brand-create-component-container {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 20px;
  border-radius: 8px;
  max-height: 80vh; 
  overflow-y: auto;
}

.brand-create-component-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.brand-create-component-form-group {
  display: flex;
  flex-direction: column;
}

.brand-create-component-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 4px;
}

.brand-create-component-input,
.brand-create-component-textarea,
.brand-create-component-file-input {
  padding: 8px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.brand-create-component-file-input {
  padding: 4px;
  background-color: #444;
  color: #bbb;
}

.brand-create-component-submit-button {
  align-self: flex-start;
  padding: 10px 16px;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background-color: #38a169;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.brand-create-component-submit-button:hover {
  background-color: #2f855a;
}

.brand-create-component-image-preview {
  margin-top: 10px;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

/* Flex between */

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Widget wrapper */

.widget-wrapper {
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.75rem;
}


/* Friend component */

.friend-name {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
}

.friend-name:hover {
  color: #1f9c29;
}

.friend-subtitle {
  color: #1f9c29;
  font-size: 0.75rem;
  margin: 0;
}


/* Admin all products */

.admin-all-products-component {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
}

.admin-all-products-charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.admin-all-products-chart {
  flex: 1 1 45%;
  min-width: 300px;
  height: 400px;
}

.admin-all-products-data-grid {
  margin-top: 20px;
}

.admin-all-products-data-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(250, 250, 250, 0.2);
}

.admin-all-products-data-table th,
.admin-all-products-data-table td {
  padding: 12px 15px;
  text-align: center;
}

.admin-all-products-data-table th {
  background-color: #146e10;
  color: #F7FAFC;
  text-transform: uppercase;
  font-size: 14px;
}

.admin-all-products-data-table td {
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}


.admin-all-products-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.admin-all-products-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #146e10;
  transition: color 0.3s ease;
}

.admin-all-products-icon-button:hover {
  color: rgba(0, 0, 0, 0.8);
}

.admin-all-products-icon-button:focus {
  outline: none;
}

.admin-all-products-icon {
  width: 20px;
  height: 20px;
}

/* Admin all events component */

.admin-all-events-component-container {
  width: 100%;
  margin: 40px auto;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-all-events-component-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.admin-all-events-component-table th,
.admin-all-events-component-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.admin-all-events-component-table th {
  background-color: rgba(250, 250, 250, 0.2);
  font-weight: bold;
}

.admin-all-events-component-preview-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #36d300;
  transition: color 0.3s ease;
}

.admin-all-events-component-preview-btn:hover {
  color: #36d300;
}


/* Owned brands component*/

.owned-brands-component {
  padding: 20px;
  background-color: #f8f9fa;
}

.owned-brands-component__title {
  text-align: center;
  margin: 20px 0;
}

.owned-brands-component__add-button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 0;
}

.owned-brands-component__add-button:hover {
  background-color: darkgreen;
}

.owned-brands-component__modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.owned-brands-component__modal-content {
  background-color: #323232;
  padding: 20px;
  border-radius: 10px;
  width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.owned-brands-component__modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.owned-brands-component__brand-card {
  width: 800px;
  margin: 0 auto 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fff;
}

.owned-brands-component__brand-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.owned-brands-component__brand-header {
  text-align: center;
}

.owned-brands-component__cover-image {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
}

.owned-brands-component__avatar-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  top: -25px;
  border: 3px solid white;
}

.owned-brands-component__submit-button,
.owned-brands-component__cancel-button,
.owned-brands-component__edit-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.owned-brands-component__submit-button:hover,
.owned-brands-component__cancel-button:hover,
.owned-brands-component__edit-button:hover {
  background-color: darkblue;
}


/* Add inventory component */

.add-inventory-component-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,0.5)333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  color: #F7FAFC;
  z-index: 1000;
  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
}

.add-inventory-component-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #F7FAFC;
}

.add-inventory-component-form {
  background: rgba(0, 0, 0, 0.8);
}

.add-inventory-component-form-group {
  margin-bottom: 1rem;
}

.add-inventory-component-form label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #a0aec0;
}

.add-inventory-component-form input {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  background-color: #4a5568;
  border: none;
  padding: 0.5rem 0.75rem;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.add-inventory-component-form input:focus {
  border-color: #5a67d8;
  box-shadow: 0 0 0 3px rgba(90, 103, 216, 0.45);
}

.add-inventory-component-submit-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.375rem;
  background-color: #36d300;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-inventory-component-submit-button:hover {
  background-color: #2f855a;
}

.add-inventory-component-submit-button:disabled {
  background-color: #68d391;
}

/* Inventory Log */

.inventory-log-component {
  margin-top: 20px;
  background-color: rgba(0,0,0,0.5)333;
  padding: 16px;
  border-radius: 6px;
  color: #F7FAFC;
}

.inventory-log-component h2 {
  font-size: 20px;
  margin-bottom: 16px;
}

.inventory-log-timeline {
  list-style-type: none;
  padding: 0;
}

.inventory-log-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #444;
}

.inventory-log-date {
  font-weight: bold;
}

.inventory-log-details {
  display: flex;
  gap: 10px;
}

.inventory-log-batch,
.inventory-log-quantity {
  background-color: #444;
  padding: 5px 10px;
  border-radius: 4px;
}

/* Shop Switcheroo */

.switcheroo-shop-component {
  position: relative;
}

.switcheroo-shop-component-avatar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  border-radius: 50%;
  border: 1px solid rgba(250, 250, 250, 0.4);
  width: 42px;
  height: 42px;
  position: relative;
  cursor: pointer;
}

.switcheroo-shop-component-avatar {
  height: 42px;
  width: 42px;
  max-width: 42px;
  border-radius: 100%;
}

.switcheroo-shop-component-swap-icon {
  position: absolute;
  right: -10px;
  bottom: -10px;
  background-color: green;
  border-radius: 50%;
  border: 1px solid rgba(250, 250, 250, 0.4);
  padding: 3px;
}

.switcheroo-shop-component-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  z-index: 10;
}

.switcheroo-shop-component-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 420px;
  max-width: 90%;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
  padding: 16px;
  border-radius: 24px;
  overflow: hidden;
  border: 2px solid green;
}

.switcheroo-shop-component-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.switcheroo-shop-component-modal-title {
  color: white;
  margin-bottom: 16px;
}

.switcheroo-shop-component-modal-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switcheroo-shop-component-modal-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.switcheroo-shop-component-modal-item-image {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.switcheroo-shop-component-check-icon {
  height: 24px;
  width: 24px;
  margin-left: auto;
  color: green;
}

/* Admin dashboard main */

.admin-dashboard-main-container {
  width: 100%;
  padding: 2rem;
}

.admin-dashboard-main-title {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 0.5rem;
}

.admin-dashboard-main-overview {
  display: block;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 800px) {
  .admin-dashboard-main-overview {
      display: flex;
  }
}

.admin-dashboard-main-card {
  width: 100%;
  margin-bottom: 1rem;
  min-height: 20vh;
  background-color: rgba(0,0,0,0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #fafafa66 !important;
  border-radius: 0.5rem;
  padding: 2rem 1rem;
}

@media (min-width: 800px) {
  .admin-dashboard-main-card {
      width: 30%;
  }
}

.admin-dashboard-main-card-content {
  display: flex;
  align-items: center;
}

.admin-dashboard-main-card-title {
  font-size: 18px;
  font-weight: 400;
  color: #00000085;
  line-height: 1.25rem;
}

.admin-dashboard-main-card-value {
  padding-top: 0.5rem;
  padding-left: 36px;
  font-size: 22px;
  font-weight: 500;
}

.admin-dashboard-main-card-link {
  padding-top: 1rem;
  padding-left: 2px;
  color: #077f9c;
  text-decoration: none;
}

.admin-dashboard-main-subtitle {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 0.5rem;
}

.admin-dashboard-main-orders-container {
  width: 100%;
  min-height: 45vh;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 0.5rem;
}

.admin-dashboard-main-orders-table {
  width: 100%;
  min-height: 45vh;
  border-collapse: collapse;
}

.admin-dashboard-main-orders-table th,
.admin-dashboard-main-orders-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.admin-dashboard-main-orders-table th {
  background-color: rgba(0, 0, 0, 0.8);
}

.admin-dashboard-main-orders-table tr.admin-dashboard-main-pending-row {
  background-color: #fff3cd;
}

.admin-dashboard-main-icon {
  margin-right: 10px;
}

/* Follower list */

.follower-list-widget-wrapper {
  padding: 1rem;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.follower-list-widget-title {
  color: #2c4a2e;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.follower-list-widget-search {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 4px;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.2)
}

.follower-list-widget-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.follower-list-widget-content p {
  margin: 0;
}

/* Following list widget */

.following-list-widget-wrapper {
  padding: 1rem;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.following-list-widget-title {
  color: #2c4a2e;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.following-list-widget-search {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.2)
}

.following-list-widget-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.following-list-widget-content p {
  margin: 0;
}

/* Admin all users component */

.admin-all-users-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.admin-all-users-chart-section {
  width: 100%;
  padding: 16px;
  margin-bottom: 20px;
}

.admin-all-users-title {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 8px;
}

.admin-all-users-timeframe-button {
  color: white;
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
}

.admin-all-users-timeframe-button-daily {
  background-color: #4CAF50;
}

.admin-all-users-timeframe-button-monthly {
  background-color: #008CBA;
}

.admin-all-users-chart-container {
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  min-height: 420px;
}

.admin-all-users-grid-section {
  width: 100%;
  padding: 16px;
}

.admin-all-users-grid-container {
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-top: 32px;
}

.admin-all-users-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-all-users-table th,
.admin-all-users-table td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.admin-all-users-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.admin-all-users-view-profile-button {
  text-decoration: none;
  color: white;
  background-color: #4CAF50;
  padding: 5px 10px;
  border-radius: 4px;
}

.admin-all-users-delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.admin-all-users-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.39);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.admin-all-users-modal-content {
  width: 95%;
  max-width: 500px;
  min-height: 20vh;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 16px;
  position: relative;
}

.admin-all-users-modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.admin-all-users-modal-title {
  font-size: 25px;
  text-align: center;
  padding: 20px 0;
  font-family: 'Poppins', sans-serif;
  color: #000000cb;
}

.admin-all-users-modal-actions {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.admin-all-users-modal-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

/* Connections widget */
.connections-widget {
  position: relative;
  z-index: 10;
}

.connections-widget-container {
  background-color: rgba(250, 250, 250, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(250, 250, 250, 0.5)
}

.connections-widget-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.connections-widget-header h1 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #1a202c;
}

.connections-widget-tabs {
  margin-bottom: 16px;
  border-bottom: 1px solid #e2e8f0;
}

.connections-widget-tabs nav {
  display: flex;
  gap: 32px;
}

.connections-widget-tab {
  padding-bottom: 16px;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  font-size: 0.875rem;
  color: #718096;
  background: none;
  border: none;
  cursor: pointer;
}

.connections-widget-tab:hover {
  color: #4a5568;
  border-color: #e2e8f0;
}

.connections-widget-tab.active {
  border-color: #38a169;
  color: #36d300;
}

/* All coupons shop dashboard */

.all-coupons-shop-component-container {
  width: 100%;
  padding: 16px;
  background-color: rgba(250, 250, 250, 0.2);
}

.all-coupons-shop-component-create-button-container {
  display: flex;
  justify-content: flex-end;
}

.all-coupons-shop-component-create-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 16px;
}

.all-coupons-shop-component-data-grid {
  width: 100%;
}

.all-coupons-shop-component-data-grid table {
  width: 100%;
  border-collapse: collapse;
}

.all-coupons-shop-component-data-grid th,
.all-coupons-shop-component-data-grid td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.all-coupons-shop-component-delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.all-coupons-shop-component-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
}

.all-coupons-shop-component-modal-content {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
}

.all-coupons-shop-component-modal-header {
  display: flex;
  justify-content: flex-end;
}

.all-coupons-shop-component-modal-close {
  cursor: pointer;
}

.all-coupons-shop-component-modal-title {
  font-size: 25px;
  text-align: center;
}

.all-coupons-shop-component-form {
  width: 100%;
}

.all-coupons-shop-component-input-group {
  margin-bottom: 16px;
}

.all-coupons-shop-component-input-group label {
  display: block;
  margin-bottom: 8px;
}

.all-coupons-shop-component-input-group input,
.all-coupons-shop-component-input-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);
}

.all-coupons-shop-component-error {
  color: red;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.all-coupons-shop-component-success {
  color: green;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.all-coupons-shop-component-required {
  color: red;
}

.all-coupons-shop-component-icon {
  margin-right: 4px;
}
.all-coupons-shop-component-multi-select {
  height: 150px; 
  overflow-y: auto;
}

.all-coupons-shop-component-selected-products {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.all-coupons-shop-component-product-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
}

.all-coupons-shop-component-product-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}

/* Other users followers list widget */

.other-followers-list-widget-wrapper {
  padding: 1rem;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.other-followers-list-widget-title {
  color: #2c4a2e;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.other-followers-list-widget-search {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 4px;
  background-color: rgba(250, 250, 250, 0.2);
}

.other-followers-list-widget-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.other-followers-list-widget-content p {
  margin: 0;
}
/* Other following list widget */

.other-following-list-widget-wrapper {
  padding: 1rem;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.other-following-list-widget-title {
  color: #2c4a2e;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.other-following-list-widget-search {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 4px;
  background-color: rgba(250, 250, 250, 0.2);
}

.other-following-list-widget-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.other-following-list-widget-content p {
  margin: 0;
}

/* Bulk product upload component */

.bulk-product-upload-button {
  background-color: #36d300;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bulk-product-upload-button:hover {
  background-color: #2f855a;
}

.bulk-product-upload-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.bulk-product-upload-modal {
  background: rgba(0, 0, 0, 0.8);
  padding: 24px;
  border-radius: 15px;
  max-width: 600px;
  width: 60%;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  outline: none;
  overflow-y: auto;
}

.bulk-product-upload-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3748;
  margin-bottom: 1rem;
}

.bulk-product-upload-close-button {
  margin-top: 1rem;
  background-color: #e53e3e;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bulk-product-upload-close-button:hover {
  background-color: #c53030;
}

/* CSV product upload component */

.CSV-upload-component-container {
  padding: 1rem;
}

.CSV-upload-component-file-input {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 4px;
  font-size: 1rem;
}

.CSV-upload-component-upload-button,
.CSV-upload-component-submit-button {
  background-color: #38a169;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
  display: inline-block;
}

.CSV-upload-component-upload-button:hover,
.CSV-upload-component-submit-button:hover {
  background-color: #2f855a;
}

.CSV-upload-component-product {
  margin-top: 2rem;
}

.CSV-upload-component-input-group {
  margin-bottom: 1rem;
}

.CSV-upload-component-input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.CSV-upload-component-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 4px;
  font-size: 1rem;
}

.CSV-upload-component-error {
  color: red;
  font-size: 0.875rem;
}

/* USPS rate calculator */

.USPS-rate-calculator-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 10px;
  background-color: rgba(250, 250, 250, 0.2);
}

.USPS-rate-calculator-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.USPS-rate-calculator-form-group {
  margin-bottom: 15px;
}

.USPS-rate-calculator-form-group label {
  display: block;
  margin-bottom: 5px;
}

.USPS-rate-calculator-form-group input {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.8);
}

.USPS-rate-calculator-form-group input[type="number"] {
  width: calc(33% - 22px);
  display: inline-block;
}

.USPS-rate-calculator-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #36d300;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.USPS-rate-calculator-button:hover {
  background-color: #36d300;
}

.USPS-rate-calculator-rate-result {
  text-align: center;
  margin-top: 20px;
}

.USPS-rate-calculator-rate-result h3 {
  margin-bottom: 10px;
}

.USPS-rate-calculator-rate-result p {
  font-size: 24px;
  color: #28a745;
}

/* Shipping rules component */

.shipping-rules-component {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.shipping-rules-component-add-rule-btn {
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #36d300;
  color: white;
  border: none;
  cursor: pointer;
}

.shipping-rules-component-add-rule-btn:hover {
  background-color: #3d6000;
}

.shipping-rules-component-table {
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(250, 250, 250, 0.2);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 10px;
  margin-bottom: 20px;
}

.shipping-rules-component-table th, .shipping-rules-component-table td {
  border: 1px solid rgba(250, 250, 250, 0.6);
  padding: 8px;
  text-align: left;
}

.shipping-rules-component-table th {
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.6);
  color: rgba(250, 250, 250, 1);
}

.shipping-rules-component-table tr:hover {
  background-color: rgba(250, 250, 250, 0.2);
}

.shipping-rules-component-table button {
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #36D300;
  border-radius: 5px;
  color: white;
  border: none;
  cursor: pointer;
}

.shipping-rules-component-table button:hover {
  background-color: #10560b;
}

/* Packing slip form */

.packing-slip-form-modal-content {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.packing-slip-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.packing-slip-form-close-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #999;
}

.packing-slip-form {
  display: flex;
  flex-direction: column;
}

.packing-slip-form-group {
  margin-bottom: 15px;
}

.packing-slip-form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.packing-slip-form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 4px;
}

.packing-slip-form-add-item-btn, 
.packing-slip-form-submit-btn {
  background: #146e10;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.packing-slip-form-add-item-btn:hover, 
.packing-slip-form-submit-btn:hover {
  background: #10560b;
}

.packing-slip-form-item-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.packing-slip-form-item-list li {
  background: rgba(250, 250, 250, 0.2);
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Create product component */

.create-product-component-wrapper {
  width: 90%;
  background-color: rgba(250, 250, 250, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1.5rem;
  overflow-y: auto;
  margin: 0 auto;
}

.create-product-component-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  text-align: center;
  margin-bottom: 1.5rem;
}

.create-product-component-section {
  padding-top: 1rem;
}

.create-product-component-subtitle {
  font-size: 1rem;
  font-weight: 600;
  color: rgba(250, 250, 250, 1);
  margin-bottom: 0.5rem;
}

.create-product-component-description {
  font-size: 0.875rem;
  color: rgba(250, 250, 250, 1);
  margin-bottom: 1rem;
}

.create-product-component-radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.create-product-component-radio-item {
  display: flex;
  align-items: center;
}

.create-product-component-radio-input {
  width: 1rem;
  height: 1rem;
  border-color: #d2d6dc;
  color: #48bb78;
  margin-right: 0.5rem;
}

.create-product-component-radio-label {
  font-size: 0.875rem;
  color: rgba(250, 250, 250, 1);
}

.create-product-component-select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 0.375rem;
  background-color: rgba(250, 250, 250, 0.1);
  color: rgba(250, 250, 250, 1);
  font-size: 1rem;
}

.create-product-component-catalog-items {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;
}

@media (min-width: 640px) {
  .create-product-component-catalog-items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .create-product-component-catalog-items {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2rem;
  }
}

.create-product-component-catalog-item {
  position: relative;
  cursor: pointer;
}

.create-product-component-catalog-item-image-container {
  position: relative;
  width: 100%;
  height: 18rem;
  overflow: hidden;
  border-radius: 0.375rem;
}

.create-product-component-catalog-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.create-product-component-catalog-item-info {
  position: relative;
  margin-top: 1rem;
}

.create-product-component-catalog-item-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1a202c;
}

.create-product-component-catalog-item-category {
  font-size: 0.875rem;
  color: #718096;
  margin-top: 0.25rem;
}

.create-product-component-catalog-item-price {
  font-size: 0.875rem;
  font-weight: 600;
  color: #4a5568;
  margin-top: 0.5rem;
}

.create-product-component-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.create-product-component-form-section {
  margin-top: 1.5rem;
}

.create-product-component-form-group {
  display: flex;
  flex-direction: column;
}

.create-product-component-form-group-row {
  display: flex;
  gap: 1rem;
}

.create-product-component-form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(250, 250, 250, 1);
  margin-bottom: 0.5rem;
}

.create-product-component-form-required {
  color: #48bb78;
}

.create-product-component-form-input,
.create-product-component-form-textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  background-color: rgba(250, 250, 250, 0.1);
  color: white;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out;
}

.create-product-component-form-input:focus,
.create-product-component-form-textarea:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(72, 187, 120, 0.5);
  outline: none;
}

.create-product-component-upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.create-product-component-upload-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(250, 250, 250, 1);
  margin-bottom: 0.75rem;
}

.create-product-component-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  width: 100%;
  border: 2px dashed rgba(250, 250, 250, 0.2);
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out;
}

.create-product-component-dropzone-active {
  border-color: rgba(250, 250, 250, 0.6);
}

.create-product-component-dropzone-content {
  text-align: center;
}

.create-product-component-dropzone-icon {
  width: 3rem;
  height: 3rem;
  color: #718096;
  margin-bottom: 0.75rem;
}

.create-product-component-dropzone-label {
  display: inline-block;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(250, 250, 250, 1);
  transition: color 0.15s ease-in-out;
}

.create-product-component-dropzone-label:hover {
  color: #48bb78;
}

.create-product-component-dropzone-note {
  font-size: 0.75rem;
  color: rgba(250, 250, 250, 0.6);
}

.create-product-component-image-previews,
.create-product-component-video-previews,
.create-product-component-barcode-previews,
.create-product-component-test-result-previews,
.create-product-component-nutrition-info-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.create-product-component-image-preview,
.create-product-component-video-preview,
.create-product-component-barcode-preview,
.create-product-component-test-result-preview,
.create-product-component-nutrition-info-preview {
  position: relative;
  margin: 0.5rem;
  border: 1px solid #48bb78;
  border-radius: 0.375rem;
  overflow: hidden;
}

.create-product-component-image-preview-dragging {
  opacity: 0.75;
}

.create-product-component-image-preview-img,
.create-product-component-video-preview-video,
.create-product-component-barcode-preview-img,
.create-product-component-test-result-preview-img,
.create-product-component-nutrition-info-preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.create-product-component-image-preview-badge,
.create-product-component-video-preview-badge,
.create-product-component-barcode-preview-badge,
.create-product-component-test-result-preview-badge,
.create-product-component-nutrition-info-preview-badge {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #48bb78;
  color: white;
  font-size: 0.75rem;
  padding: 0.25rem;
}

.create-product-component-image-preview-remove,
.create-product-component-video-preview-remove,
.create-product-component-barcode-preview-remove,
.create-product-component-test-result-preview-remove,
.create-product-component-nutrition-info-preview-remove {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.create-product-component-image-preview-remove:hover,
.create-product-component-video-preview-remove:hover,
.create-product-component-barcode-preview-remove:hover,
.create-product-component-test-result-preview-remove:hover,
.create-product-component-nutrition-info-preview-remove:hover {
  background-color: #c53030;
}

.create-product-component-checkbox-group {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.create-product-component-checkbox-item {
  display: flex;
  align-items: center;
}

.create-product-component-checkbox-input {
  width: 1.25rem;
  height: 1.25rem;
  color: #48bb78;
  border-color: #d2d6dc;
  margin-right: 0.5rem;
}

.create-product-component-checkbox-label {
  font-size: 0.875rem;
  color: rgba(250, 250, 250, 1);
}

.create-product-component-switch-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.create-product-component-switch {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 3rem;
  height: 1.5rem;
  background-color: #d2d6dc;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.create-product-component-switch-active {
  background-color: #48bb78;
}

.create-product-component-switch-toggle {
  position: absolute;
  top: 50%;
  left: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: rgba(250, 250, 250, 0.5);
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out;
}

.create-product-component-switch-toggle-active {
  transform: translate(1.5rem, -50%);
}

.create-product-component-switch-label {
  font-size: 0.875rem;
  color: rgba(250, 250, 250, 1);
  margin-left: 0.5rem;
}

.create-product-component-submit {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.create-product-component-submit-button {
  display: inline-flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  background-color: #36d300;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.create-product-component-submit-button:hover {
  background-color: #38a169;
}

.create-product-component-submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(72, 187, 120, 0.5);
}

/* Checkout component */

.checkout-component-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.checkout-component-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkout-component-left, .checkout-component-right {
  width: 100%;
  margin-top: 2rem;
}

.checkout-component-shipping-info {
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 0.375rem;
  padding: 1.25rem;
}

.checkout-component-section-title {
  font-size: 1.125rem;
  font-weight: 500;
}

.checkout-component-form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 0.75rem;
}

.checkout-component-form-group {
  flex: 1;
}

.checkout-component-label {
  display: block;
  padding-bottom: 0.5rem;
}

.checkout-component-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  background-color: rgba(0,0,0,0.5);
  color: white;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out;
}

.checkout-component-input:focus {
  border-color: #48bb78;
  outline: none;
}

.checkout-component-select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  background-color: rgba(0,0,0,0.5);
  color: white;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out;
}

.checkout-component-saved-address-title {
  font-size: 1.125rem;
  cursor: pointer;
  display: inline-block;
}

.checkout-component-saved-address-item {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}

.checkout-component-checkbox {
  margin-right: 0.75rem;
}

.checkout-component-saved-address-type {
  font-size: 1rem;
}

.checkout-component-cart-data {
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 0.375rem;
  padding: 1.25rem;
}

.checkout-component-cart-item {
  display: flex;
  justify-content: space-between;
}

.checkout-component-cart-item-border {
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 0.75rem;
}

.checkout-component-cart-label {
  font-size: 1rem;
  font-weight: 400;
  color: #000000a4;
}

.checkout-component-cart-value {
  font-size: 1.125rem;
  font-weight: 600;
}

.checkout-component-total-price {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: end;
  padding-top: 0.75rem;
}

.checkout-component-apply-code-button {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #f63b60;
  color: #f63b60;
  text-align: center;
  border-radius: 0.375rem;
  margin-top: 2rem;
  cursor: pointer;
}

.checkout-component-button {
  width: 150px;
  height: 40px;
  background-color: #48bb78;
  color: white;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 2rem;
}

.checkout-component-button-text {
  font-size: 1rem;
  font-weight: 500;
}

/* Checkout steps component */

.checkout-steps-component-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.checkout-steps-component-content {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (min-width: 800px) {
  .checkout-steps-component-content {
    width: 50%;
  }
}

.checkout-steps-component-flex {
  display: flex;
  align-items: center;
}

.checkout-steps-component-cart-button {
  padding: 0 20px;
  height: 38px;
  border-radius: 20px;
  background-color: #36d300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkout-steps-component-cart-button.active {
  background-color: #36d300;
}

.checkout-steps-component-cart-button.inactive {
  background-color: #3d6000;
}

.checkout-steps-component-cart-button-text {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.checkout-steps-component-cart-button-text.inactive-text {
  color: #fff;
}

.checkout-steps-component-line {
  width: 25px;
  height: 4px;
  background-color: #fde1e6;
}

.checkout-steps-component-line.active {
  background-color: #f63b60;
}

@media (min-width: 800px) {
  .checkout-steps-component-line {
    width: 70px;
  }


}

/* Cookie consent component */

.cookie-consent-component-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8); 
  min-height: 15%;
}

.cookie-consent-component-container {
  max-width: 1120px; 
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem; 
  padding-right: 1.5rem; 
  padding-top: 1rem; 
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1024px) {
  .cookie-consent-component-container {
    flex-direction: row;
    padding-left: 2rem; 
    padding-right: 2rem; 
  }
}

.cookie-consent-component-text {
  font-size: 1.125rem; 
  font-weight: bold; 
  color: #1a202c; 
  text-align: center;
}

.cookie-consent-component-link {
  font-size: 0.875rem; 
  font-weight: 600; 
  color: #48bb78; 
  margin-left: 0.5rem; 
}

.cookie-consent-component-button-container {
  margin-top: 1rem; 
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-shrink: 0;
}

@media (min-width: 1024px) {
  .cookie-consent-component-button-container {
    margin-top: 0; 
  }
}

.cookie-consent-component-button {
  border-radius: 0.375rem; 
  background-color: #36d300; 
  padding-left: 0.875rem; 
  padding-right: 0.875rem; 
  padding-top: 0.625rem; 
  padding-bottom: 0.625rem; 
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); 
  transition: background-color 0.2s;
}

.cookie-consent-component-button:hover {
  background-color: #3d6000; 
}

.cookie-consent-component-button:focus {
  outline: 2px solid #36d300; 
  outline-offset: 2px;
}

/* Dubs additions */
.shop-all-orders-container {
  background-color: rgba(250, 250, 250, 0.0) !important;
  border-radius: 0px !important;
  color: #f7fafc;
  margin-top: 20px;
  min-height: 100vh;
  padding: 16px;
  width: 80vw;
}

.css-1rf4mk1 .MuiDataGrid-columnHeaders {
  background-color: rgba(250, 250, 250, 0.1) !important;
  color: rgb(247, 250, 252);
}
.css-1rf4mk1 .MuiDataGrid-withBorderColor {
  border-color: rgba(250, 250, 250, 0.6) !important;
  background-color: rgba(250, 250, 250, 0.1) !important;
}

.css-1cccqvr.css-1cccqvr.css-1cccqvr {
  padding-right: 24px;
  min-width: 16px;
  color: #ffffff !important;
}

.css-14349d1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgba(250, 250, 250, 0.3) !important;
}

.all-products-shop-component {
  background-color: transparent !important;
  border-radius: 0px !important;
  margin-top: 20px;
  min-height: 100vh;
  padding: 25px !important;
  width: 100% !important;
}

.shop-dashboard-header-card {
  background-color: rgba(250, 250, 250, 0.1) !important;
  border: rgba(250, 250, 250, 0.3) !important;
  border-radius: .55rem;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  margin-bottom: 1rem;
  min-height: 20vh;
  padding: 2rem 1rem;
  width: 100%;
}

.switcheroo-swap-icon {
  background-color: #36d300;
  border: 1px solid #00000080 !important;
  border-radius: 50%;
  bottom: -10px;
  padding: 0px !important;
  padding-bottom: 20px !important;
  position: absolute;
  right: -5px !important;
  width: 20px !important;
  height: 20px !important;
}

.avatar-dropdown-menu-popup, .cart-sidebar-popup {
  background-color: #0c1117e6 !important;
  border-left: 1px solid rgba(250, 250, 250, 0.3) !important;
  box-shadow: none !important;
  height: 100%;
  max-width: 28rem !important;
  overflow-y: auto;
  padding: 20px;
  position: fixed;
  right: 0;
  top: 10.5%;
}

.avatar-dropdown-menu-item {
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
  padding: 16px 0;
}

.avatar-dropdown-menu-link {
  color: rgba(250, 250, 250, 0.8) !important;
  display: block;
  padding: 8px 16px;
  text-decoration: none;
}

.avatar-dropdown-menu-link:hover {
  background-color: rgba(250, 250, 250, 0.1) !important;
  color: rgba(250, 250, 250, 0.8) !important;
  border-radius: 50px !important;
}

/* Seller order details */

.seller-order-details-component-container {
  padding: 1rem 0;
  min-height: 100vh;
  max-width: 90%;
  margin: 0 auto;
}

.seller-order-details-component-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.seller-order-details-component-header-left {
  display: flex;
  align-items: center;
}

.seller-order-details-component-title {
  padding-left: 0.5rem;
  font-size: 25px;
}

.seller-order-details-component-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.seller-order-details-component-info-text {
  color: #00000084;
}

.seller-order-details-component-item {
  display: flex;
  align-items: start;
  margin-bottom: 1.25rem;
}

.seller-order-details-component-item-image {
  width: 420px;
  height: 420px;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
}

.seller-order-details-component-item-details {
  width: 100%;
  padding-left: 1.5rem;
}

.seller-order-details-component-item-name {
  font-size: 24px;
}

.seller-order-details-component-item-price {
  font-size: 20px;
  color: #00000091;
}

.seller-order-details-component-review-button {
  background-color: #f63b60;
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.seller-order-details-component-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.seller-order-details-component-modal-content {
  width: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  padding: 1rem;
}

.seller-order-details-component-modal-close {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.seller-order-details-component-close-icon {
  cursor: pointer;
}

.seller-order-details-component-modal-title {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}

.seller-order-details-component-modal-body {
  display: flex;
}

.seller-order-details-component-modal-image {
  width: 80px;
  height: 80px;
  border-radius: 0.375rem;
}

.seller-order-details-component-modal-item-name {
  padding-left: 0.75rem;
  font-size: 20px;
}

.seller-order-details-component-modal-item-price {
  padding-left: 0.75rem;
  font-size: 20px;
}

.seller-order-details-component-rating-label {
  padding-left: 0.75rem;
  font-size: 20px;
  font-weight: 500;
}

.seller-order-details-component-rating-required {
  color: red;
}

.seller-order-details-component-rating {
  display: flex;
  padding-left: 0.75rem;
  padding-top: 0.25rem;
}

.seller-order-details-component-star-icon {
  margin-right: 0.25rem;
  cursor: pointer;
}

.seller-order-details-component-comment {
  padding-left: 0.75rem;
}

.seller-order-details-component-comment-label {
  display: block;
  font-size: 20px;
  font-weight: 500;
}

.seller-order-details-component-comment-optional {
  font-weight: 400;
  font-size: 16px;
  color: #00000052;
}

.seller-order-details-component-textarea {
  width: 95%;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  outline: none;
}

.seller-order-details-component-submit-button {
  background-color: #f63b60;
  color: white;
  font-size: 20px;
  padding: 0.5rem 1rem;
  margin-left: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.seller-order-details-component-total {
  border-top: 1px solid #e2e8f0;
  text-align: right;
}

.seller-order-details-component-total-text {
  padding-top: 1rem;
  font-size: 18px;
}

.seller-order-details-component-shipping-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 800px) {
  .seller-order-details-component-shipping-payment {
    flex-direction: row;
  }
}

.seller-order-details-component-shipping {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 800px) {
  .seller-order-details-component-shipping {
    width: 60%;
  }
}

.seller-order-details-component-shipping-title {
  padding-top: 1rem;
  font-size: 20px;
  font-weight: 600;
}

.seller-order-details-component-shipping-address {
  padding-top: 1rem;
  font-size: 20px;
}

.seller-order-details-component-shipping-location {
  font-size: 20px;
}

.seller-order-details-component-payment {
  width: 100%;
}

@media (min-width: 800px) {
  .seller-order-details-component-payment {
    width: 40%;
  }
}

.seller-order-details-component-payment-title {
  padding-top: 1rem;
  font-size: 20px;
}

.seller-order-details-component-payment-status {
  font-size: 20px;
}

.seller-order-details-component-refund-button {
  background-color: #f63b60;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.seller-order-details-component-message-button {
  background-color: #f63b60;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  text-align: center;
}

/* Newsletter sign-up */

.newsletter-signup-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.75rem 5rem;
  background-color: rgba(250, 250, 250, 0.2);
}

@media (min-width: 768px) {
  .newsletter-signup-container {
    flex-direction: row;
    padding: 1.75rem 5rem;
  }
}

.newsletter-signup-title {
  font-size: 1.875rem; 
  line-height: 1.25; 
  font-weight: 600; 
  margin-bottom: 1.5rem;
  text-align: center;
}

@media (min-width: 1024px) {
  .newsletter-signup-title {
    font-size: 2.25rem; 
    margin-bottom: 0;
  }
}

.newsletter-signup-highlight {
  color: #56d879;
}

.newsletter-signup-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 640px) {
  .newsletter-signup-form {
    flex-direction: row;
    align-items: center;
  }
}

.newsletter-signup-input {
  color: #2d3748; 
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.625rem;
  border-radius: 0.375rem;
  border: none;
  outline: none;
}

@media (min-width: 640px) {
  .newsletter-signup-input {
    width: 18rem; 
    margin-right: 1.25rem; 
    margin-bottom: 0;
  }
}

.newsletter-signup-button {
  background-color: #56d879;
  color: white;
  padding: 0.625rem 1.25rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.newsletter-signup-button:hover {
  background-color: #38b2ac; 
}

@media (min-width: 768px) {
  .newsletter-signup-button {
    width: auto;
  }
}

/* User order details */

.user-order-details-container {
  padding: 1rem 0;
  min-height: 100vh;
  max-width: 90%;
  margin: 0 auto;
}

.user-order-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-order-details-header-left {
  display: flex;
  align-items: center;
}

.user-order-details-title {
  padding-left: 0.5rem;
  font-size: 25px;
}

.user-order-details-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.user-order-details-info-text {
  color: #00000084;
}

.user-order-details-item {
  display: flex;
  align-items: start;
  margin-bottom: 1.25rem;
}

.user-order-details-item-image {
  width: 420px;
  height: 420px;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
}

.user-order-details-item-details {
  width: 100%;
  padding-left: 1.5rem;
}

.user-order-details-item-name {
  font-size: 24px;
}

.user-order-details-item-price {
  font-size: 20px;
  color: #00000091;
}

.user-order-details-review-button {
  background-color: #f63b60;
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.user-order-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-order-details-modal-content {
  width: 50%;
  background-color: rgba(250, 250, 250, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  padding: 1rem;
}

.user-order-details-modal-close {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.user-order-details-close-icon {
  cursor: pointer;
}

.user-order-details-modal-title {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}

.user-order-details-modal-body {
  display: flex;
}

.user-order-details-modal-image {
  width: 80px;
  height: 80px;
  border-radius: 0.375rem;
}

.user-order-details-modal-item-name {
  padding-left: 0.75rem;
  font-size: 20px;
}

.user-order-details-modal-item-price {
  padding-left: 0.75rem;
  font-size: 20px;
}

.user-order-details-rating-label {
  padding-left: 0.75rem;
  font-size: 20px;
  font-weight: 500;
}

.user-order-details-rating-required {
  color: red;
}

.user-order-details-rating {
  display: flex;
  padding-left: 0.75rem;
  padding-top: 0.25rem;
}

.user-order-details-star-icon {
  margin-right: 0.25rem;
  cursor: pointer;
}

.user-order-details-comment {
  padding-left: 0.75rem;
}

.user-order-details-comment-label {
  display: block;
  font-size: 20px;
  font-weight: 500;
}

.user-order-details-comment-optional {
  font-weight: 400;
  font-size: 16px;
  color: #00000052;
}

.user-order-details-textarea {
  width: 95%;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  outline: none;
}

.user-order-details-submit-button {
  background-color: #f63b60;
  color: white;
  font-size: 20px;
  padding: 0.5rem 1rem;
  margin-left: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.user-order-details-total {
  border-top: 1px solid #e2e8f0;
  text-align: right;
}

.user-order-details-total-text {
  padding-top: 1rem;
  font-size: 18px;
}

.user-order-details-shipping-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 800px) {
  .user-order-details-shipping-payment {
    flex-direction: row;
  }
}

.user-order-details-shipping {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 800px) {
  .user-order-details-shipping {
    width: 60%;
  }
}

.user-order-details-shipping-title {
  padding-top: 1rem;
  font-size: 20px;
  font-weight: 600;
}

.user-order-details-shipping-address {
  padding-top: 1rem;
  font-size: 20px;
}

.user-order-details-shipping-location {
  font-size: 20px;
}

.user-order-details-payment {
  width: 100%;
}

@media (min-width: 800px) {
  .user-order-details-payment {
    width: 40%;
  }
}

.user-order-details-payment-title {
  padding-top: 1rem;
  font-size: 20px;
}

.user-order-details-payment-status {
  font-size: 20px;
}

.user-order-details-refund-button {
  background-color: #f63b60;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.user-order-details-message-button {
  background-color: #f63b60;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  text-align: center;
}

/* Shop by brand carousel */

.shop-by-brand-carousel-bg {
  background-color: rgba(0, 0, 0, 0.8)
}

.shop-by-brand-carousel-container {
  padding: 4rem 0;
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem; 
  padding-right: 2rem; 
}

.shop-by-brand-carousel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem; 
  padding-right: 1rem; 
}

.shop-by-brand-carousel-title {
  font-size: 1.5rem; 
  font-weight: bold;
  color: #38a169; 
  letter-spacing: -0.025em;
}

.shop-by-brand-carousel-content {
  margin-top: 2rem; 
}

.shop-by-brand-carousel-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 1rem; 
}

@media (min-width: 640px) {
  .shop-by-brand-carousel-grid {
    grid-template-columns: repeat(3, 1fr); 
  }
}

@media (min-width: 1024px) {
  .shop-by-brand-carousel-grid {
    grid-template-columns: repeat(4, 1fr); 
  }
}

.shop-by-brand-carousel-item {
  position: relative;
  padding: 1rem; 
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
  border-radius: 0.5rem; 
  background-color: black;
  text-align: center;
}

.shop-by-brand-carousel-link {
  text-decoration: none;
}

.shop-by-brand-carousel-brand {
  color: white;
  font-weight: bold;
}

.shop-by-brand-carousel-explore {
  margin-top: 0.5rem; 
  font-size: 0.875rem; 
  color: #d1d5db; 
}

/* Google calendar button */
.google-calendar-button-link {
  text-decoration: none;
}

.google-calendar-button {
  background-color: #1a73e8; 
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Google Sans", sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.google-calendar-button.hover {
  background-color: #2c83f6; 
}

/* Outlook calendar button */
.outlook-calendar-button-link {
  text-decoration: none;
}

.outlook-calendar-button {
  background-color: #0078D4; 
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Segoe UI", sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.outlook-calendar-button.hover {
  background-color: #005A9E; 
}

/* Hashtag Posts page */

.hashtag-posts-page {
  background-color: black;
}

.hashtag-posts-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.5rem 1rem;
}

@media (min-width: 1024px) {
  .hashtag-posts-container {
    flex-direction: row;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.hashtag-posts-aside {
  display: none;
}

@media (min-width: 1024px) {
  .hashtag-posts-aside {
    display: block;
    position: sticky;
    top: 6rem;
    width: 100%;
    max-width: 17rem;
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 0.5rem;
  }
}

.hashtag-posts-main {
  flex-grow: 1;
  padding: 1rem;
  width: 100%;
}

.hashtag-posts-header {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.hashtag-posts-title {
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

/* Footer component */

.footer-component-bg {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.footer-component-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  padding: 4rem 1.25rem;
  text-align: center;
}

@media (min-width: 640px) {
  .footer-component-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .footer-component-grid {
    grid-template-columns: repeat(4, 1fr);
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.footer-component-column {
  text-align: center;
}

@media (min-width: 640px) {
  .footer-component-column {
    text-align: start;
  }
}

.footer-component-logo-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-component-social-icons {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.footer-component-icon {
  cursor: pointer;
  margin-left: 1rem;
}

.footer-component-icon:first-child {
  margin-left: 0;
}

.footer-component-title {
  margin-bottom: 0.25rem;
  font-weight: 600;
}

.footer-component-link {
  color: #888;
  transition: color 0.3s ease;
  font-size: 0.875rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.footer-component-link:hover {
  color: #36d300;
}

.footer-component-bottom {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.5rem;
  text-align: center;
  padding-top: 0.5rem;
  color: #888;
  font-size: 0.875rem;
  padding-bottom: 2rem;
}

@media (min-width: 640px) {
  .footer-component-bottom {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-component-bottom span:last-child {
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .footer-component-bottom {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer-component-bottom span:last-child {
    grid-column: span 2;
    text-align: right;
  }
}

/* Log in component */

.login-component-container {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem;
}

.login-component-header {
  margin: 0 auto;
  max-width: 28rem;
  text-align: center;
}

.login-component-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.875rem;
  font-weight: 800;
  color: #047857; 
}

.login-component-form-container {
  margin-top: 2rem;
  margin: 0 auto;
  max-width: 28rem;
}

.login-component-form-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2rem 4rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.login-component-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.login-component-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
}

.login-component-input-wrapper {
  margin-top: 0.25rem;
}

.login-component-input {
  background-color: rgba(255, 255, 255, 0.2);
  appearance: none;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  outline: none;
}

.login-component-input:focus {
  border-color: #36d300; 
  box-shadow: 0 0 0 3px rgba(5, 150, 105, 0.3); 
}

.login-component-icon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
}

.login-component-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-component-remember-me {
  display: flex;
  align-items: center;
}

.login-component-checkbox {
  height: 1rem;
  width: 1rem;
  color: #3b82f6;
  border-color: #d1d5db;
  border-radius: 0.25rem;
}

.login-component-checkbox-label {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: white;
}

.login-component-forgot-password {
  font-size: 0.875rem;
}

.login-component-forgot-password-link {
  font-weight: 500;
  color: #3b82f6;
  transition: color 0.3s ease;
}

.login-component-forgot-password-link:hover {
  color: #2563eb; 
}

.login-component-submit-button {
  background-color: #36d300;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-component-submit-button:hover {
  background-color: #3d6000; 
}

.login-component-signup-link {
  display: flex;
  align-items: center;
  width: 100%;
}

.login-component-signup-text {
  color: #3b82f6;
  padding-left: 0.5rem;
}

/* Forgot password */

.forgot-password-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem;
}

.forgot-password-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin: 1rem 0;
}

.forgot-password-form-group {
  margin-bottom: 1rem;
}

.forgot-password-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 0.5rem;
}

.forgot-password-input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  outline: none;
  font-size: 0.875rem;
}

.forgot-password-input:focus {
  border-color: #5a67d8;
}

.forgot-password-button-container {
  display: flex;
  justify-content: flex-end;
}

.forgot-password-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  background-color: #5a67d8;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.forgot-password-button:hover {
  background-color: #4c51bf;
}

.forgot-password-message {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.forgot-password-message.success {
  color: #38a169;
}

.forgot-password-message.error {
  color: #e53e3e;
}

/* Forgot password reset */

.forgot-password-reset-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem;
}

.forgot-password-reset-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin: 1rem 0;
}

.forgot-password-reset-form-group {
  margin-bottom: 1rem;
}

.forgot-password-reset-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 0.5rem;
}

.forgot-password-reset-input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  outline: none;
  font-size: 0.875rem;
}

.forgot-password-reset-input:focus {
  border-color: #5a67d8;
}

.forgot-password-reset-button-container {
  display: flex;
  justify-content: flex-end;
}

.forgot-password-reset-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  background-color: #5a67d8;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.forgot-password-reset-button:hover {
  background-color: #4c51bf;
}

.forgot-password-reset-message {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.forgot-password-reset-message.success {
  color: #38a169;
}

.forgot-password-reset-message.error {
  color: #e53e3e;
}

/* Suggested product */
.suggested-product-container {
  padding: 1rem;
}

.suggested-product-heading {
  font-size: 25px;
  font-weight: 500;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1.25rem;
}

.suggested-product-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .suggested-product-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1024px) {
  .suggested-product-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1280px) {
  .suggested-product-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 25px;
  }
}

/* Trending products carousel retail */

.trending-products-carousel-bg {
  background-color: rgba(250, 250, 250, 0.2);
}

.trending-products-carousel-container {
  padding-top: 4rem;
  padding-bottom: 6rem;
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.trending-products-carousel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.trending-products-carousel-title {
  font-size: 2rem;
  font-weight: bold;
  color: #36D300;
}

.trending-products-carousel-link-hidden {
  display: none;
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
  transition: color 0.3s ease;
}

.trending-products-carousel-link-hidden:hover {
  color: #3d6000; 
}

@media (min-width: 640px) {
  .trending-products-carousel-link-hidden {
    display: block;
  }
}

.trending-products-carousel-wrapper {
  position: relative;
  margin-top: 2rem;
}

.trending-products-carousel-scroll {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.trending-products-carousel-list {
  display: inline-flex;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  list-style: none;
}

@media (min-width: 1024px) {
  .trending-products-carousel-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.trending-products-carousel-item {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  width: 16rem;
}

@media (min-width: 1024px) {
  .trending-products-carousel-item {
    width: auto;
  }
}

.trending-products-carousel-card {
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.trending-products-carousel-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: black;
}

.trending-products-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.3s ease;
}

.trending-products-carousel-info {
  background-color: black;
  padding: 1rem;
}

.trending-products-carousel-category {
  font-size: 0.875rem;
  color: #d1d5db; 
}

.trending-products-carousel-product-name {
  margin-top: 0.25rem;
  font-weight: 600;
  color: white;
}

.trending-products-carousel-price {
  margin-top: 0.25rem;
  color: #36D300; 
}

.trending-products-carousel-footer {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.trending-products-carousel-link {
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
  transition: color 0.3s ease;
}

.trending-products-carousel-link:hover {
  color: #36d300; 
}

@media (min-width: 640px) {
  .trending-products-carousel-footer {
    display: none;
  }
}

/* Trending products carousel wholesale */
.trending-products-carousel-wholesale-bg {
  background-color: rgba(250, 250, 250, 0.2);
}

.trending-products-carousel-wholesale-container {
  padding-top: 4rem;
  padding-bottom: 6rem;
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.trending-products-carousel-wholesale-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.trending-products-carousel-wholesale-title {
  font-size: 2rem;
  font-weight: bold;
  color: #36d300; 
}

.trending-products-carousel-wholesale-link-hidden {
  display: none;
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
  transition: color 0.3s ease;
}

.trending-products-carousel-wholesale-link-hidden:hover {
  color: #15803d; 
}

@media (min-width: 640px) {
  .trending-products-carousel-wholesale-link-hidden {
    display: block;
  }
}

.trending-products-carousel-wholesale-wrapper {
  position: relative;
  margin-top: 2rem;
}

.trending-products-carousel-wholesale-scroll {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.trending-products-carousel-wholesale-list {
  display: inline-flex;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  list-style: none;
}

@media (min-width: 1024px) {
  .trending-products-carousel-wholesale-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.trending-products-carousel-wholesale-item {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  width: 16rem;
}

@media (min-width: 1024px) {
  .trending-products-carousel-wholesale-item {
    width: auto;
  }
}

.trending-products-carousel-wholesale-card {
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.trending-products-carousel-wholesale-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: black;
}

.trending-products-carousel-wholesale-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.3s ease;
}

.trending-products-carousel-wholesale-info {
  background-color: black;
  padding: 1rem;
}

.trending-products-carousel-wholesale-category {
  font-size: 0.875rem;
  color: #d1d5db; 
}

.trending-products-carousel-wholesale-product-name {
  margin-top: 0.25rem;
  font-weight: 600;
  color: white;
}

.trending-products-carousel-wholesale-price {
  margin-top: 0.25rem;
  color: #36d300; 
}

.trending-products-carousel-wholesale-footer {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.trending-products-carousel-wholesale-link {
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
  transition: color 0.3s ease;
}

.trending-products-carousel-wholesale-link:hover {
  color: #36d300; 
}

@media (min-width: 640px) {
  .trending-products-carousel-wholesale-footer {
    display: none;
  }
}

/* Profile Content Component */

.profile-content-component-container {
  width: 100%;
}

.profile-content-component-avatar-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.profile-content-component-avatar-wrapper {
  position: relative;
}

.profile-content-component-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffffff;
}

.profile-content-component-avatar-overlay {
  width: 25px;
  height: 25px;
  background-color: #e3e9ee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.profile-content-component-avatar-input {
  display: none;
}

.profile-content-component-avatar-label {
  cursor: pointer;
}

.profile-content-component-form-container {
  width: 100%;
  padding: 0 20px;
}

.profile-content-component-form-row {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
}

.profile-content-component-form-group {
  width: 100%;
  padding-right: 15px;
}

.profile-content-component-form-label {
  display: block;
  padding-bottom: 10px;
}

.profile-content-component-form-input {
  width: 95%;
  padding: 8px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 5px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.8);
}

.profile-content-component-submit-btn {
  width: 250px;
  height: 40px;
  border: 1px solid #3a24db;
  text-align: center;
  color: #3a24db;
  border-radius: 3px;
  margin-top: 25px;
  cursor: pointer;
  background-color: transparent;
}

.profile-content-component-orders {
  padding-left: 20px;
  padding-top: 10px;
}

.profile-content-component-table {
  width: 100%;
  border-collapse: collapse;
}

.profile-content-component-table th,
.profile-content-component-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.profile-content-component-view-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.profile-content-component-password-change {
  width: 100%;
  padding: 0 20px;
}

.profile-content-component-title {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  color: #000000ba;
  padding-bottom: 20px;
}

.profile-content-component-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-content-component-address {
  width: 100%;
  padding: 0 20px;
}

.profile-content-component-address-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-content-component-add-btn {
  background-color: #3a24db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.profile-content-component-address-item {
  background-color: rgba(250, 250, 250, 0.5);
  padding: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.profile-content-component-no-address {
  text-align: center;
  padding-top: 20px;
  font-size: 18px;
}

.profile-content-component-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-content-component-modal-content {
  width: 35%;
  max-height: 80vh;
  background-color: rgba(250, 250, 250, 0.5);
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 20px;
}

.profile-content-component-modal-close {
  display: flex;
  justify-content: flex-end;
}

.profile-content-component-modal-title {
  font-size: 25px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.profile-content-component-modal-form-group {
  margin-bottom: 15px;
}

/* Profile Sidebar Component Styles */

.profile-sidebar-component-container {
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: 10px;
  padding: 16px;
  padding-top: 32px;
}

.profile-sidebar-component-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: 32px;
}

.profile-sidebar-component-text {
  padding-left: 12px;
  display: none;
}

.profile-sidebar-component-text.active {
  color: #36D300;
}

.profile-sidebar-component-item.active .profile-sidebar-component-text {
  color: #36D300;
}

@media (min-width: 800px) {
  .profile-sidebar-component-text {
    display: block;
  }
}

.profile-sidebar-component-button {
  margin-top: 20px;
  text-align: center;
  background-color: #36D300;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.profile-sidebar-component-button-text {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-sidebar-component-button-text h1 {
  margin-right: 8px;
}

/* Track Order Component */

.track-order-component-container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.track-order-component-status {
  font-size: 20px;
  text-align: center;
}

/* ShopCreate Component */

.shop-create-component-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem;
}

.shop-create-component-header {
  text-align: center;
}

.shop-create-component-header h2 {
  margin-top: 1.5rem;
  font-size: 1.875rem;
  font-weight: 800;
  color: #fff;
}

.shop-create-component-form-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.shop-create-component-form {
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 20px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  padding: 2rem 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 35rem;
}

.shop-create-component-form-elements {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.shop-create-component-form-group {
  display: flex;
  flex-direction: column;
}

.shop-create-component-form-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0.25rem;
}

.shop-create-component-input {
  padding: 0.5rem 0.75rem;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  color: #fff;
  outline: none;
}

.shop-create-component-input:focus {
  border-color: #36d300;
}

.shop-create-component-password-container {
  position: relative;
}

.shop-create-component-eye-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.shop-create-component-avatar-container {
  display: flex;
  align-items: center;
}

.shop-create-component-avatar-preview {
  display: inline-block;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  overflow: hidden;
}

.shop-create-component-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shop-create-component-avatar-icon {
  height: 2rem;
  width: 2rem;
}

.shop-create-component-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-left: 1.25rem;
  cursor: pointer;
}

.shop-create-component-file-label:hover {
  background-color: #36d000;
}

.shop-create-component-file-input {
  display: none;
}

.shop-create-component-submit {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  background-color: #36d300;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.shop-create-component-submit:hover {
  background-color: #3d6000;
}

.shop-create-component-signin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-create-component-link {
  color: #3b82f6;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
}

/* ShopLogin Component  */

.shop-login-component-container {
  min-height: 100vh;
  background-color: rgba(250, 250, 250, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem;
}

.shop-login-component-header {
  text-align: center;
}

.shop-login-component-header h2 {
  margin-top: 1.5rem;
  font-size: 1.875rem;
  font-weight: 800;
  color: #111827;
}

.shop-login-component-form-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.shop-login-component-form {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 2rem 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  width: 100%;
  max-width: 28rem;
}

.shop-login-component-form-elements {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.shop-login-component-form-group {
  display: flex;
  flex-direction: column;
}

.shop-login-component-form-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.25rem;
}

.shop-login-component-input {
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  color: #111827;
  outline: none;
}

.shop-login-component-input:focus {
  border-color: #3b82f6;
}

.shop-login-component-password-container {
  position: relative;
}

.shop-login-component-eye-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.shop-login-component-button {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  color: #ffffff;
  background-color: #3b82f6;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.shop-login-component-button:hover {
  background-color: #2563eb;
}

.shop-login-component-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-login-component-footer h4 {
  margin: 0;
}

.shop-login-component-footer a {
  padding-left: 0.5rem;
  color: #3b82f6;
  text-decoration: none;
}

.shop-login-component-footer a:hover {
  text-decoration: underline;
}

/* ShopSettings Component  */

.shop-settings-component-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-settings-component-content {
  width: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1.25rem 0;
}

.shop-settings-component-banner,
.shop-settings-component-avatar {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 1.25rem;
}

.shop-settings-component-banner img,
.shop-settings-component-avatar img {
  cursor: pointer;
}

.shop-settings-component-banner img {
  width: 100%;
  height: 300px;
}

.shop-settings-component-avatar img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.shop-settings-component-camera-icon-container {
  width: 25px;
  height: 25px;
  background-color: #e3e9ee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.shop-settings-component-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-settings-component-form-group {
  width: 100%;
  max-width: 50%;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-settings-component-form-group label {
  width: 100%;
  padding-left: 3%;
  margin-bottom: 0.5rem;
}

.shop-settings-component-input {
  width: 95%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: rgba(250, 250, 250, 0.2);
  outline: none;
}

.shop-settings-component-input:focus {
  border-color: #10b981;
}

.shop-settings-component-opening-hours {
  width: 100%;
  max-width: 50%;
  margin-top: 1.25rem;
}

.shop-settings-component-opening-hours-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.shop-settings-component-opening-hours-item label {
  font-weight: 500;
  color: #374151;
}

.shop-settings-component-opening-hours-input {
  width: 5rem;
  margin: 0 0.5rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #f3f4f6;
  outline: none;
}

.shop-settings-component-opening-hours-input:focus {
  border-color: #10b981;
}

.shop-settings-component-submit-button {
  width: 95%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #10b981;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #36d300;
  text-align: center;
  cursor: pointer;
}

/* SponsoredFeedProducts Component */

.sponsored-feed-products-container {
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 10px;
  margin: 10px auto;
  max-width: 1000px;
}

.sponsored-feed-products-title {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.sponsored-feed-products-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsored-feed-products-card {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.sponsored-feed-products-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.sponsored-feed-products-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.sponsored-feed-products-info {
  background-color: rgba(84, 82, 82, 0.5);
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sponsored-feed-products-name {
  font-size: 16px;
  margin: 0;
}

.sponsored-feed-products-price {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
}

.sponsored-feed-products-store-link {
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  color: #ffffff;
  background-color: #ffb511 !important;
  padding: 5px 15px;
  border-radius: 50px;
}

/* Top banner ad */

.top-banner-ad-container {
  width: 100%;
  padding: 12px 48px;
  box-sizing: border-box;
}

.top-banner-ad-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-position: center;
  padding: 15px 25px !important;
  border-radius: 50px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  background-blend-mode: darken;
}

.top-banner-ad-link {
  color: white;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  flex-grow: 1;
}

.top-banner-ad-link strong {
  font-weight: bold;
}

.top-banner-ad-dismiss {
  background: none;
  border: none;
  padding: 12px;
  cursor: pointer;
}

.top-banner-ad-icon {
  height: 20px;
  width: 20px;
  color: white;
}

/* Map product ad slider */
.map-product-ad-slider-container {
  width: 235px;
}

/* Paper component styling */
.map-product-ad-slider-paper {
  background-position: center center;
  background-size: cover;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

/* Content within each slide */
.map-product-ad-slider-content {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* Title styling */
.map-product-ad-slider-title {
  font-size: 1.2em;
  margin: 0;
}

/* Price styling */
.map-product-ad-slider-price {
  font-size: 1em;
  margin: 0;
}

/* Button styling */
.map-product-ad-slider-button {
  background-color: #4caf50 !important;
  margin-top: 20px;
  color: #fff;
}


.map-product-ad-slider-button .MuiButton-root {
  margin: 0;
}


/* Wishlist */

.wishlist-component-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.29);
  z-index: 10;
}

.wishlist-component-container {
  position: fixed;
  top: 10%;
  right: 0;
  height: 100%;
  width: 80%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 800px) {
  .wishlist-component-container {
    width: 25%;
  }
}

.wishlist-component-empty {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wishlist-component-close-button {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 40px 20px 0 0;
  position: absolute;
  top: 3px;
  right: 3px;
}

.wishlist-component-header {
  display: flex;
  align-items: center;
  padding: 20px;
}

.wishlist-component-header-title {
  padding-left: 8px;
  font-size: 20px;
  font-weight: 500;
}

.wishlist-component-items-container {
  width: 100%;
  border-top: 1px solid #e5e5e5;
}

.wishlist-component-item {
  border-bottom: 1px solid #e5e5e5;
  padding: 16px;
}

.wishlist-component-item-content {
  display: flex;
  align-items: center;
}

.wishlist-component-item-remove {
  cursor: pointer;
  margin-bottom: 8px;
  margin-left: 8px;
}

.wishlist-component-item-image {
  width: 125px;
  margin: 0 8px;
  border-radius: 5px;
}

.wishlist-component-item-details {
  padding-left: 5px;
}

.wishlist-component-item-price {
  font-weight: 600;
  padding-top: 8px;
  font-size: 17px;
  color: #d02222;
}

.wishlist-component-item-add-to-cart {
  cursor: pointer;
}

/* Shop all products page */

.shop-all-products-page-container {
  width: 100%;
}

.shop-all-products-page-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.shop-all-products-page-main {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Shop all coupons page */

.shop-all-coupons-page-container {
  width: 100%;
}

.shop-all-coupons-page-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.shop-all-coupons-page-main {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Shop settings page */

.shop-settings-page-container {
  width: 100%;
}

.shop-settings-page-content {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.shop-settings-page-main {
  flex: 1;
  padding: 16px;
}

.shop-settings-page-manage-payment {
  margin-top: 16px; 
}

.shop-settings-page-manage-payment-btn {
  background-color: #36d300;
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
}

.shop-settings-page-manage-payment-btn:hover {
  background-color: #3d6000; 
}

/* Shop dashboard page */
.shop-dashboard-page-container {
  width: 100%;
}

.shop-dashboard-page-content {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

/* Category section component */
.category-section-component-bg {
  background-color: rgba(0, 0, 0, 0.8);
}

.category-section-component-container {
  padding-top: 16px;
  padding-bottom: 24px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.category-section-component-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.category-section-component-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937; 
}

.category-section-component-browse-link {
  display: none;
  font-size: 0.875rem;
  font-weight: 600;
  color: #36D300; 
  text-decoration: none;
}

.category-section-component-browse-link:hover {
  color: #3d6000; 
}

.category-section-component-categories {
  margin-top: 16px;
}

.category-section-component-categories-inner {
  overflow-x: auto;
  height: 320px;
}

.category-section-component-categories-list {
  display: flex;
  gap: 32px;
  padding-left: 16px;
  padding-right: 16px;
}

.category-section-component-category {
  position: relative;
  flex: 0 0 auto;
  width: 224px;
  height: 320px;
  border-radius: 10%;
  padding: 24px;
  text-align: center;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
}

.category-section-component-category:hover {
  opacity: 0.75;
}

.category-section-component-category-image-wrapper {
  position: absolute;
  inset: 0;
}

.category-section-component-category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10%;
}

.category-section-component-category-gradient {
  position: absolute;
  left: 0;
  right:0;
  bottom: 0;
  height: 66%;
  background: linear-gradient(to top, rgba(31, 41, 55, 0.8), transparent); 
  opacity: 0.5;
}

.category-section-component-category-name {
  position: relative;
  margin-top: auto;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
}

.category-section-component-browse-mobile {
  margin-top: 24px;
  padding-left: 16px;
  display: block;
}

.category-section-component-browse-link-mobile {
  font-size: 0.875rem;
  font-weight: 600;
  color: #36d300; 
  text-decoration: none;
}

.category-section-component-browse-link-mobile:hover {
  color: #3d6000; 
}

@media (min-width: 640px) {
  .category-section-component-browse-link {
      display: block;
  }

  .category-section-component-browse-mobile {
      display: none;
  }
}

/* Tax code selector */

.tax-code-selector-container {
  position: relative;
  margin-top: 40px;
}

.tax-code-selector-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
}

.tax-code-selector-required {
  color: #36d300; 
}

.tax-code-selector-input {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d1d5db; 
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
}

.tax-code-selector-input:focus {
  outline: none;
  border-color: #36d300; 
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.5)
}

.tax-code-selector-dropdown {
  position: absolute;
  z-index: 10;
  margin-top: 0.25rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #d1d5db; 
  border-radius: 0.375rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 240px;
  overflow-y: auto;
}

.tax-code-selector-loading {
  padding: 8px 12px;
}

.tax-code-selector-item {
  cursor: pointer;
  padding: 8px 12px;
}

.tax-code-selector-item:hover {
  background-color: #e5e7eb; 
}

/* Shop order details */

.shop-order-details-container {
  padding-top: 16px;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
}

.shop-order-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop-order-details-header-left {
  display: flex;
  align-items: center;
}

.shop-order-details-title {
  padding-left: 8px;
  font-size: 25px;
}

.shop-order-details-button {
  background-color: #fce1e6;
  border-radius: 4px;
  color: #e94560;
  font-weight: 600;
  height: 45px;
  font-size: 18px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-order-details-info {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.shop-order-details-id,
.shop-order-details-date {
  color: #00000084;
}

.shop-order-details-customer {
  padding-top: 24px;
}

.shop-order-details-subtitle {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 8px;
}

.shop-order-details-item {
  display: flex;
  align-items: start;
  margin-bottom: 20px;
}

.shop-order-details-item-image {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 8px;
}

.shop-order-details-item-info {
  width: 100%;
}

.shop-order-details-item-name {
  padding-left: 8px;
  font-size: 20px;
}

.shop-order-details-item-price {
  padding-left: 8px;
  font-size: 20px;
  color: #00000091;
}

.shop-order-details-total {
  border-top: 1px solid #e5e7eb;
  text-align: right;
  padding-top: 16px;
}

.shop-order-details-total-price {
  font-size: 18px;
}

.shop-order-details-shipping {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.shop-order-details-shipping-address,
.shop-order-details-payment {
  width: 100%;
  margin-bottom: 16px;
}

.shop-order-details-select {
  width: 200px;
  margin-top: 8px;
  border: 1px solid #d1d5db;
  height: 35px;
  border-radius: 5px;
  padding: 4px;
}

.shop-order-details-update-button {
  background-color: #fce1e6;
  border-radius: 4px;
  color: #e94560;
  font-weight: 600;
  height: 45px;
  font-size: 18px;
  padding: 0 16px;
  display: inline-block;
  margin-top: 20px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
}

/* Product card component */

.product-card-component-container {
  background-color: rgba(250, 250, 250, 0.2);
  width: 100%;
  height: 420px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 16px;
  position: relative;
  cursor: pointer;
}

.product-card-component-image {
  width: 100%;
  height: 170px;
  object-fit: contain;
  margin-bottom: 10px;
}

.product-card-component-shop-name {
  font-weight: bold;
  margin-bottom: 8px;
}

.product-card-component-product-name {
  padding-bottom: 8px;
  font-weight: 500;
}

.product-card-component-ratings {
  display: flex;
}

.product-card-component-price-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
}

.product-card-component-price {
  display: flex;
  align-items: center;
}

.product-card-component-discount-price {
  font-weight: bold;
  color: #e94560;
}

.product-card-component-original-price {
  text-decoration: line-through;
  margin-left: 8px;
  color: #888;
}

.product-card-component-sold-out {
  font-weight: 400;
  font-size: 17px;
  color: #68d284;
}

.product-card-component-quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.product-card-component-quantity-button {
  border: 1px solid #ddd;
  padding: 4px 8px;
}

.product-card-component-quantity {
  margin: 0 8px;
}

.product-card-component-cart-icon {
  margin-left: 8px;
}

.product-card-component-wishlist-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: #36d300;
  border-radius: 50%;
  padding: 4px;
}

.product-card-component-eye-icon {
  position: absolute;
  right: 8px;
  top: 48px;
  background-color: #36d300;
  border-radius: 50%;
  padding: 4px;
}

.product-card-component-brand-badge {
  position: absolute;
  left: 8px;
  top: 8px;
}

.product-card-component-brand-image {
  width: 40px;
  height: 40px;
}

/* Shop dashboard header */
.shop-dashboard-header-container {
  width: 100%;
  height: 80px;
  background-color: rgba(0,0,0,0.8);
  border-bottom: 1px solid rgba(250, 250, 250, 0.6);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.shop-dashboard-header-logo {
  margin-top: 8px;
  cursor: pointer;
  max-height: 120px; 
}

.shop-dashboard-header-links {
  display: flex;
  align-items: center;
}

.shop-dashboard-header-icons {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.shop-dashboard-header-icon-link {
  display: none;
}

@media (min-width: 800px) {
  .shop-dashboard-header-icon-link {
    display: block;
  }
}

.shop-dashboard-header-icon {
  color: #555;
  font-size: 25px;
  margin: 0 10px;
  cursor: pointer;
}

.shop-dashboard-header-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

/* Download ICS calendar button */

.download-ICS-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.download-ICS-button:hover {
  background-color: #45a049;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Product details card */

.product-details-card-bg {
  background-color: rgba(0, 0, 0, 0.8);
}

.product-details-card-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(250, 250, 250, 0.2);
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-details-card-content {
  background-color: rgba(74, 74, 74, 0.4);
  width: 90%;
  max-width: 60%;
  height: 90vh;
  overflow-y: scroll;
  max-height: 75vh;
  border-radius: 10px;
  position: relative;
  padding: 16px;
}

.product-details-card-close-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 50;
  cursor: pointer;
}

.product-details-card-flex {
  display: flex;
  flex-direction: column;
}

.product-details-card-image-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product-details-card-shop-details {
  display: flex;
}

.product-details-card-shop-link {
  display: flex;
  align-items: center;
}

.product-details-card-shop-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 8px;
}

.product-details-card-shop-name {
  font-weight: 500;
}

.product-details-card-shop-ratings {
  padding-bottom: 8px;
  font-size: 15px;
}

.product-details-card-message-button {
  background-color: #000;
  margin-top: 16px;
  border-radius: 4px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.product-details-card-message-button-text {
  color: #fff;
  display: flex;
  align-items: center;
}

.product-details-card-sold-out {
  color: red;
  margin-top: 20px;
}

.product-details-card-info-section {
  width: 100%;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.product-details-card-title {
  font-size: 20px;
}

.product-details-card-pricing {
  display: flex;
  padding-top: 12px;
}

.product-details-card-discount-price {
  font-size: 24px;
  font-weight: 700;
  color: red;
}

.product-details-card-original-price {
  text-decoration: line-through;
  margin-left: 8px;
  font-size: 18px;
  color: gray;
}

.product-details-card-quantity-wishlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  padding-right: 12px;
}

.product-details-card-quantity-controls {
  display: flex;
}

.product-details-card-decrement,
.product-details-card-increment {
  background: linear-gradient(to right, #4caf50, #4caf50);
  color: white;
  font-weight: bold;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 4px;
}

.product-details-card-quantity {
  background-color: #e0e0e0;
  color: #424242;
  font-weight: 500;
  padding: 11px 16px;
}

.product-details-card-wishlist-icon {
  cursor: pointer;
}

.product-details-card-add-to-cart-button {
  background-color: #000;
  margin-top: 24px;
  border-radius: 4px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.product-details-card-add-to-cart-button-text {
  color: #fff;
  display: flex;
  align-items: center;
}

/* Individual shop products */

.individual-shop-products-container {
  padding: 20px;
}

.individual-shop-products-filter-container {
  padding-top: 16px;
  padding-bottom: 16px;
}

.individual-shop-products-filter-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}

.individual-shop-products-filter-button {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
  background: none;
  border: none;
  cursor: pointer;
}

.individual-shop-products-filter-icon {
  margin-right: 8px;
  height: 20px;
  width: 20px;
  color: #a0aec0;
}

.individual-shop-products-chevron-icon {
  margin-left: 8px;
  height: 20px;
  width: 20px;
  color: #718096;
}

.rotate-180 {
  transform: rotate(180deg);
}

.individual-shop-products-disclosure-panel {
  border-top: 1px solid #e2e8f0;
  padding-top: 40px;
  padding-bottom: 40px;
}

.individual-shop-products-disclosure-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding: 0 16px;
}

.individual-shop-products-disclosure-inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.individual-shop-products-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

.individual-shop-products-select {
  margin-top: 4px;
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #cbd5e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
}

.individual-shop-products-select:focus {
  border-color: #4a5568;
}

.individual-shop-products-checkbox {
  height: 16px;
  width: 16px;
  color: #4c51bf;
  border-radius: 4px;
  border: 1px solid #cbd5e0;
  outline: none;
}

.individual-shop-products-checkbox:focus {
  border-color: #4a5568;
}

.individual-shop-products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 48px;
}

/* change subscription modal */

.change-subscription-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-subscription-modal-content {
  background-color: #1a1a1a;
  padding: 40px 24px;
  border-radius: 8px;
  position: relative;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.change-subscription-close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.change-subscription-title {
  font-size: 24px;
  font-weight: 600;
  color: white;
  text-align: center;
}

.change-subscription-frequency {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.change-subscription-frequency-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
}

.change-subscription-frequency-option {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 16px;
  text-align: center;
  color: white;
  transition: background-color 0.3s ease;
}

.change-subscription-frequency-option.active {
  background-color: #38a169;
}

.change-subscription-tier-group {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.change-subscription-tier-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  background-color: #2d3748;
  cursor: pointer;
  transition: border-color 0.3s ease;
  border: 2px solid transparent;
}

.change-subscription-tier-option.active {
  border-color: #38a169;
}

.change-subscription-tier-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.change-subscription-tier-name {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.change-subscription-tier-description {
  font-size: 14px;
  color: #a0aec0;
}

.change-subscription-tier-features {
  font-size: 14px;
  color: #a0aec0;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.change-subscription-tier-price {
  font-size: 24px;
  font-weight: 700;
  color: white;
}

.change-subscription-tier-check-icon {
  height: 24px;
  width: 24px;
  color: white;
}

.change-subscription-confirmation-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.change-subscription-confirmation-content {
  background-color: rgba(250, 250, 250, 0.5);
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.change-subscription-confirmation-title {
  font-size: 20px;
  font-weight: 700;
  color: #2d3748;
}

.change-subscription-confirmation-text {
  margin-top: 16px;
  font-size: 16px;
  color: #4a5568;
}

.change-subscription-confirmation-actions {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.change-subscription-confirmation-cancel {
  background-color: #718096;
  color: white;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.change-subscription-confirmation-confirm {
  background-color: #38a169;
  color: white;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

/* Shop change subscription modal */
.shop-change-subscription-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-change-subscription-modal-content {
  background-color: rgba(0,0,0,0.5);
  padding: 20px;
  border-radius: 8px;
  color: white;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.shop-change-subscription-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.shop-change-subscription-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}


.shop-change-subscription-frequency {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.shop-change-subscription-frequency-option {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #555;
  color: white;
  transition: background-color 0.3s ease;
}

.shop-change-subscription-frequency-option.active {
  background-color: #4CAF50;
}


.shop-change-subscription-tier-group {
  margin-top: 20px;
}

.shop-change-subscription-tier-option {
  cursor: pointer;
  background-color: #444;
  border: 1px solid #666;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
  transition: background-color 0.3s ease;
}

.shop-change-subscription-tier-option.active {
  background-color: #4CAF50;
}

.shop-change-subscription-tier-content {
  display: flex;
  flex-direction: column;
}

.shop-change-subscription-tier-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.shop-change-subscription-tier-description {
  font-size: 14px;
  margin-bottom: 10px;
}

.shop-change-subscription-tier-features {
  font-size: 14px;
  margin-bottom: 10px;
}

.shop-change-subscription-tier-price {
  font-size: 18px;
  font-weight: bold;
}

.shop-change-subscription-price-suffix {
  font-size: 14px;
  color: #ccc;
  margin-left: 5px;
}

.shop-change-subscription-tier-check-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: white;
}


.shop-change-subscription-confirmation-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.shop-change-subscription-confirmation-content {
  background-color: rgba(250, 250, 250, 0.5);
  padding: 20px;
  border-radius: 8px;
  color: black;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.shop-change-subscription-confirmation-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.shop-change-subscription-confirmation-text {
  margin-bottom: 20px;
}

.shop-change-subscription-confirmation-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.shop-change-subscription-confirmation-cancel,
.shop-change-subscription-confirmation-confirm {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.shop-change-subscription-confirmation-cancel {
  background-color: #ccc;
}

.shop-change-subscription-confirmation-confirm {
  background-color: #4CAF50;
  color: white;
}

/* Subscription pricing */

.subscription-pricing-container {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 96px 0;
  color: #fff;
}

.subscription-pricing-inner {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 24px;
}

.subscription-pricing-header {
  text-align: center;
}

.subscription-pricing-subtitle {
  font-size: 16px;
  color: #68D391;
  font-weight: 600;
}

.subscription-pricing-title {
  margin-top: 16px;
  font-size: 40px;
  font-weight: 700;
}

.subscription-pricing-description {
  max-width: 640px;
  margin: 24px auto;
  text-align: center;
  font-size: 18px;
  color: #A0AEC0;
}

.subscription-pricing-frequency {
  display: flex;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 40px;
}

.subscription-pricing-frequency-option {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #2D3748;
  color: #fff;
  cursor: pointer;
  margin: 0 8px;
  transition: background-color 0.3s ease;
}

.subscription-pricing-frequency-option.active {
  background-color: #68D391;
}

.subscription-pricing-tiers {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}

@media (min-width: 1024px) {
  .subscription-pricing-tiers {
    grid-template-columns: repeat(3, 1fr);
  }
}

.subscription-pricing-tier {
  background-color: #2D3748;
  border-radius: 24px;
  padding: 32px;
  text-align: left;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.subscription-pricing-tier.most-popular {
  background-color: #1A202C;
  border: 2px solid #68D391;
  box-shadow: 0 0 10px rgba(104, 211, 145, 0.5);
}

.subscription-pricing-tier-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscription-pricing-tier-name {
  font-size: 20px;
  font-weight: 600;
}

.subscription-pricing-tier-popular {
  background-color: #68D391;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  color: #1A202C;
}

.subscription-pricing-tier-description {
  margin-top: 16px;
  font-size: 16px;
  color: #A0AEC0;
}

.subscription-pricing-tier-price {
  margin-top: 24px;
  font-size: 36px;
  font-weight: 700;
}

.subscription-pricing-tier-price-suffix {
  font-size: 16px;
  color: #A0AEC0;
}

.subscription-pricing-subscribe-btn {
  display: block;
  width: 100%;
  background-color: #68D391;
  color: #1A202C;
  border-radius: 8px;
  padding: 12px 0;
  margin-top: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscription-pricing-subscribe-btn:hover {
  background-color: #48BB78;
}

.subscription-pricing-features {
  margin-top: 32px;
  list-style: none;
  padding: 0;
}

.subscription-pricing-feature {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #A0AEC0;
  margin-bottom: 12px;
}

.subscription-pricing-feature-check {
  color: #68D391;
  margin-right: 8px;
  font-weight: 700;
}

/* shop subscription pricing */

.shop-subscription-pricing-container {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 3rem 0;
}

.shop-subscription-pricing-content {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.shop-subscription-pricing-header {
  text-align: center;
}

.shop-subscription-pricing-title {
  color: #28a745;
  font-size: 1.25rem;
  font-weight: 600;
}

.shop-subscription-pricing-subtitle {
  margin-top: 0.5rem;
  font-size: 2.25rem;
  color: white;
  font-weight: 700;
}

.shop-subscription-pricing-description {
  text-align: center;
  margin-top: 1rem;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  color: #d1d5db;
}

.shop-subscription-pricing-frequency {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.shop-subscription-pricing-frequency-option {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
  margin: 0 0.25rem;
}

.shop-subscription-pricing-frequency-option.active {
  background-color: #28a745;
}

.shop-subscription-pricing-tiers {
  display: grid;
  gap: 1.5rem;
  margin-top: 2rem;
  grid-template-columns: repeat(1, 1fr);
}

.shop-subscription-pricing-tier {
  background-color: #2d2d2d;
  padding: 2rem;
  border-radius: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.shop-subscription-pricing-tier.most-popular {
  border: 2px solid #28a745;
}

.shop-subscription-pricing-tier-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-subscription-pricing-tier-name {
  font-size: 1.125rem;
  color: white;
  font-weight: 600;
}

.shop-subscription-pricing-tier-badge {
  background-color: #28a745;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
}

.shop-subscription-pricing-tier-description {
  margin-top: 0.75rem;
  color: #d1d5db;
  font-size: 0.875rem;
}

.shop-subscription-pricing-tier-price {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  color: white;
  font-weight: 700;
}

.shop-subscription-pricing-subscribe-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  margin-top: 1rem;
  cursor: pointer;
}

.shop-subscription-pricing-features {
  margin-top: 1.5rem;
  list-style: none;
  padding: 0;
}

.shop-subscription-pricing-feature {
  color: #d1d5db;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

/* User inbox */

.user-inbox-component-container {
  width: 100%;
}

.user-inbox-component-title {
  text-align: center;
  font-size: 25px;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
}

.user-inbox-component-message-item {
  width: 100%;
  display: flex;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.user-inbox-component-message-item.active {
  background-color: #f0f0f0;
}

.user-inbox-component-avatar {
  position: relative;
}

.user-inbox-component-avatar-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-inbox-component-online-indicator,
.user-inbox-component-offline-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
}

.user-inbox-component-online-indicator {
  background-color: #38c776;
}

.user-inbox-component-offline-indicator {
  background-color: #c7b9b9;
}

.user-inbox-component-message-content {
  padding-left: 12px;
}

.user-inbox-component-message-username {
  font-size: 18px;
}

.user-inbox-component-message-text {
  font-size: 16px;
  color: rgba(0,0,0,0.5);
}

.user-inbox-component-chat-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.user-inbox-component-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

.user-inbox-component-chat-header-left {
  display: flex;
  align-items: center;
}

.user-inbox-component-chat-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.user-inbox-component-chat-user-info {
  padding-left: 12px;
}

.user-inbox-component-chat-username {
  font-size: 18px;
  font-weight: 600;
}

.user-inbox-component-chat-status {
  font-size: 14px;
  color: #555;
}

.user-inbox-component-back-icon {
  cursor: pointer;
}

.user-inbox-component-messages {
  padding: 12px;
  height: 75vh;
  overflow-y: scroll;
}

.user-inbox-component-message {
  display: flex;
  margin: 8px 0;
}

.user-inbox-component-message.sent {
  justify-content: flex-end;
}

.user-inbox-component-message.received {
  justify-content: flex-start;
}

.user-inbox-component-message-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.user-inbox-component-message-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 8px;
  margin-bottom: 8px;
}

.user-inbox-component-message-bubble {
  background-color: #38c776;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  max-width: 75%;
}

.user-inbox-component-message.sent .user-inbox-component-message-bubble {
  background-color: #000;
}

.user-inbox-component-message-time {
  font-size: 12px;
  color: rgba(0,0,0,0.5);
  padding-top: 4px;
}

.user-inbox-component-message-input-container {
  display: flex;
  align-items: center;
  padding: 12px;
}

.user-inbox-component-gallery-icon {
  width: 25px;
}

.user-inbox-component-file-input {
  display: none;
}

.user-inbox-component-message-input-box {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.user-inbox-component-message-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.user-inbox-component-send-button {
  display: none;
}

.user-inbox-component-send-icon {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

/* create sales event */
.create-sales-event-container {
  width: 90%;
  max-width: 70%;
  background-color: rgba(250, 250, 250, 0.2);
  border: 1px solid rgba(250, 250, 250, 0.6);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 80vh;
  border-radius: 4px;
  padding: 16px;
  overflow-y: scroll;
}

.create-sales-event-title {
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.create-sales-event-form-group {
  margin-bottom: 16px;
}

.create-sales-event-input,
.create-sales-event-textarea,
.create-sales-event-select {
  width: 100%;
  padding: 8px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 4px;
  font-size: 14px;
  color: rgba(250, 250, 250, 1);
}

.create-sales-event-textarea {
  resize: vertical;
}

.create-sales-event-file-input {
  display: none;
}

.create-sales-event-image-preview {
  display: flex;
  flex-wrap: wrap;
}

.create-sales-event-upload-icon {
  cursor: pointer;
  margin-top: 12px;
  color: #555;
}

.create-sales-event-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: 8px;
}

.create-sales-event-submit-button {
  width: 100%;
  padding: 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}

.create-sales-event-submit-button:hover {
  background-color: #45a049;
}

.required {
  color: red;
}

/* My images component */

.my-images-component {
  max-width: 400px;
  margin: 0 auto;
  padding: 4px;
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: 10px;
}

.my-images-component h3 {
  text-align: center;
  margin-bottom: 10px;
  color: rgba(0,0,0,0.5);
}

.my-images-component-image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.my-images-component-thumbnail {
  width: 100%;
  height: auto;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.2s;
}

.my-images-component-thumbnail:hover {
  transform: scale(1.05);
}

.my-images-component-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.my-images-component-modal-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-images-component-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.my-images-component-gallery {
  display: flex;
  align-items: center;
}

.my-images-component-gallery-image {
  max-width: 100%;
  max-height: 80vh;
  margin: 0 20px;
  border-radius: 4px;
}

.my-images-component-nav-button {
  background: none;
  border: none;
  color: rgba(0,0,0,0.5);
  font-size: 25px;
  cursor: pointer;
  user-select: none;
  padding: 10px;
}

.my-images-component-nav-button:hover {
  color: #000;
}

.my-images-component-nav-button.left {
  margin-right: 20px;
}

.my-images-component-nav-button.right {
  margin-left: 20px;
}

.my-images-component-thumbnail-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
  overflow-x: auto;
  width: 100%;
}

.my-images-component-thumbnail-small {
  width: 60px;
  height: 60px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  opacity: 0.6;
  transition: opacity 0.2s, transform 0.2s;
}

.my-images-component-thumbnail-small:hover,
.my-images-component-thumbnail-small.active {
  opacity: 1;
  transform: scale(1.1);
}

/* Product reviews */
.product-reviews-component-container {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 16px;
}

@media (min-width: 640px) {
  .product-reviews-component-container {
    padding: 24px;
  }
}

@media (min-width: 1024px) {
  .product-reviews-component-container {
    padding: 32px;
  }
}

.product-reviews-component-header {
  font-size: 24px;
  font-weight: bold;
  color: #1f2937; 
}

.product-reviews-component-average {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.product-reviews-component-average-stars {
  color: #fbbf24; 
}

.product-reviews-component-average-text {
  margin-left: 8px;
  font-size: 14px;
  color: #1f2937; 
}

.product-reviews-component-rating {
  margin-top: 24px;
}

.product-reviews-component-rating-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 4px 0;
}

.product-reviews-component-rating-text {
  width: 32px;
  text-align: right;
  font-weight: 500;
  color: #1f2937; 
}

.product-reviews-component-rating-bar-container {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.product-reviews-component-rating-star {
  color: #fbbf24;
  height: 20px;
  width: 20px;
}

.product-reviews-component-rating-bar-wrapper {
  position: relative;
  margin-left: 8px;
  width: 100%;
}

.product-reviews-component-rating-bar-background {
  height: 8px;
  border-radius: 9999px;
  background-color: #e5e7eb; 
}

.product-reviews-component-rating-bar-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  background-color: #fbbf24;
  height: 100%;
}

.product-reviews-component-review-list {
  margin-top: 32px;
}

.product-reviews-component-review-item {
  padding: 16px 0;
  border-bottom: 1px solid #e5e7eb; 
}

.product-reviews-component-review-item:last-child {
  border-bottom: 0;
}

.product-reviews-component-review-user {
  font-size: 18px;
  font-weight: 600;
}

.product-reviews-component-review-stars {
  display: flex;
  align-items: center;
  margin: 4px 0;
}

.product-reviews-component-review-comment {
  margin-top: 8px;
  color: #4b5563; 
}

/* Shop shipping page */

.shop-shipping-page-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.shop-shipping-page-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.shop-shipping-page-shipping-rules {
  margin-top: 16px;
}

.shop-shipping-page-button-open-modal {
  margin-top: 16px;
  padding: 10px 20px;
  background-color: #36d300;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.shop-shipping-page-button-open-modal:hover {
  background-color: #36d300;
}

/* Shipping Rates Selector */
.shipping-rates-selector-component {
  margin: 20px 0;
}

.shipping-rates-selector-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #fff;
}

.shipping-rates-selector-list {
  display: flex;
  flex-direction: column;
}

.shipping-rates-selector-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 8px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.shipping-rates-selector-item.selected {
  background-color: #36d300;
  border-color: #c3e6cb;
}

.shipping-rates-selector-deselect-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
  margin-left: 10px;
  transition: color 0.2s ease;
}

.shipping-rates-selector-deselect-button:hover {
  color: #c82333;
}

/* All refund orders */

.all-refund-orders-container {
  width: 100%;
  margin: 2rem auto;
  padding-top: 1rem;
  background-color: rgba(250, 250, 250, 0.2);
}

.all-refund-orders-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.all-refund-orders-table th, .all-refund-orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.all-refund-orders-table th {
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

.all-refund-orders-table tbody tr:hover {
  background-color: #f1f1f1;
}

.all-refund-orders-button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Hashtag suggestions component */

.hashtag-suggestions-component-container {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin-top: 10px;
}

.hashtag-suggestions-component-loading {
  font-size: 14px;
  color: #999;
}

.hashtag-suggestions-component-item {
  padding: 8px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hashtag-suggestions-component-item:hover {
  background-color: rgba(250, 250, 250, 0.4);
}

.hashtag-suggestions-component-text {
  font-size: 14px;
  color: rgba(0,0,0,0.5);
}

/* My event card component */

.my-event-card-component-card {
  position: relative;
  margin: 5px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.my-event-card-component-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.my-event-card-component-image {
  width: 100%;
  height: 280px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.my-event-card-component-card:hover .my-event-card-component-image {
  transform: scale(1.05);
}

.my-event-card-component-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 20%, transparent 100%);
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.my-event-card-component-title {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
}

.my-event-card-component-description {
  font-size: 16px;
  margin: 5px 0;
}

.my-event-card-component-datetime {
  margin: 10px 0;
}

.my-event-card-component-date,
.my-event-card-component-time,
.my-event-card-component-location {
  margin: 5px 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
}

.my-event-card-icon {
  margin-right: 8px; 
}

.my-event-card-component-button {
  background-color: #36d300;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.my-event-card-component-button:hover {
  background-color: #e04050;
}

.my-event-card-component-calendar-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  background: rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  padding: 10px;
}

.my-event-card-component-calendar-buttons > * {
  flex: 1;
}
.my-events-container {
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
}

.my-events-title {
  text-align: center; 
  margin-bottom: 5px;
  color: rgb(119, 119, 119) !important;
  font-size: 12px;
  font-weight: 300;
}

/* Map Page.*/

/* General Styles */
.map-page-container {
  display: flex;
  flex-direction: column;
  background-color: rgba(250, 250, 250, 0.1);
}

/* Mobile Filters */
.map-page-mobile-filter-dialog {
  position: relative;
  z-index: 40;
}

.map-page-mobile-filter-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.map-page-mobile-filter-panel {
  position: fixed;
  inset: 0;
  z-index: 40;
  display: flex;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.map-page-mobile-filter-panel-open {
  transform: translateX(0);
}

.map-page-mobile-filter-content {
  flex-direction: column;
  background-color: white;
  padding-top: 16px;
  padding-bottom: 48px;
  max-width: 320px;
  width: 100%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-page-mobile-filter-header {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.map-page-mobile-filter-close-button {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  color: rgba(0, 0, 0, 0.4);
}

.map-page-filter-form {
  margin-top: 16px;
  border-top: 1px solid rgba(200, 200, 200, 1);
}

.map-page-category-list {
  padding: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.map-page-filter-section {
  border-top: 1px solid rgba(200, 200, 200, 1);
  padding: 24px 16px;
}

.map-page-filter-section-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  padding: 16px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
}

.map-page-filter-section-label {
  color: rgba(0, 0, 0, 0.9);
}

.map-page-filter-section-content {
  padding-top: 24px;
}

.map-page-filter-option {
  display: flex;
  align-items: center;
}

.map-page-filter-option-checkbox {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  border: 1px solid rgba(200, 200, 200, 1);
  margin-right: 12px;
}

.map-page-filter-option-label {
  flex: 1;
  color: rgba(80, 80, 80, 1);
}

/* Desktop Layout */
.map-page-main {
  margin: 0 auto;
  padding: 0 16px;
}

.map-page-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(200, 200, 200, 1);
  padding: 24px 16px;
}

.map-page-title {
  font-size: 2.25rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}

.map-page-filters {
  display: flex;
  align-items: center;
  gap: 16px;
}

.map-page-listbox-button {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 8px 12px;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(80, 80, 80, 1);
  border-radius: 4px;
  border: 1px solid rgba(200, 200, 200, 1);
}

.map-page-listbox-options {
  position: absolute;
  z-index: 10;
  margin-top: 8px;
  max-height: 240px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.map-page-listbox-option {
  padding: 8px 16px;
  font-size: 1rem;
}

.map-page-listbox-option-active {
  background-color: rgba(224, 242, 255, 1);
  color: rgba(33, 150, 243, 1);
}

.map-page-listbox-option-selected {
  font-weight: 500;
}

.map-page-sort-menu {
  position: relative;
  display: inline-block;
  text-align: left;
}

.map-page-sort-menu-button {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(80, 80, 80, 1);
  border: none;
  background: none;
}

.map-page-sort-menu-options {
  position: absolute;
  right: 0;
  z-index: 10;
  margin-top: 8px;
  width: 160px;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.map-page-sort-menu-option {
  padding: 8px 16px;
  font-size: 0.875rem;
}

.map-page-sort-menu-option-active {
  background-color: rgba(240, 240, 240, 1);
}

.map-page-grid-button {
  margin-left: 20px;
  padding: 8px;
  color: rgba(100, 100, 100, 1);
  border: none;
  background: none;
}

.map-page-filters-button {
  margin-left: 20px;
  padding: 8px;
  color: rgba(100, 100, 100, 1);
  border: none;
  background: none;
}

.map-page-section {
  padding: 24px 16px;
}

.map-page-shop-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

@media (min-width: 768px) {
  .map-page-shop-list {
      grid-template-columns: 1fr 1fr;
  }
}

.map-page-shop-item {
  position: relative;
  background-color: black;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.map-page-shop-item img {
  width: 100%;
  height: 128px;
  object-fit: cover;
}

.map-page-shop-item-avatar {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
}

.map-page-shop-item-content {
  padding-top: 32px;
  padding-bottom: 16px;
  text-align: center;
}

.map-page-shop-item-title {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 8px;
}

.map-page-shop-item-description {
  font-size: 0.875rem;
  color: rgba(80, 80, 80, 1);
}

/* Map Container */
.map-page-map-container {
  width: 100%;
  height: 500px;
}






/* Google calendar component */

.google-calendar-button-link {
  display: inline-block;
}

.google-calendar-icon {
  width: 36px; 
  height: 36px; 
  cursor: pointer;
  transition: transform 0.2s ease;
}

.google-calendar-icon:hover {
  transform: scale(1.1); 
}

/* Outlook calendar button */

.outlook-calendar-button-link {
  display: inline-block;
}

.outlook-calendar-icon {
  width: 36px; 
  height: 36px; 
  cursor: pointer;
  transition: transform 0.2s ease;
}

.outlook-calendar-icon:hover {
  transform: scale(1.1); 
}

/* Download ics button */
.download-ICS-button {
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 36px;
  max-height: 36px;
}

.download-ICS-icon {
  background: #36d000;
  border-radius: 50%;
  font-size: 24px;
  color: #000; 
  transition: transform 0.2s ease, color 0.2s ease;
}

.download-ICS-button:hover .download-ICS-icon {
  transform: scale(1.1); 
  color: #007bff; 
}



/* Wallet sidebar */
.crypto-wallet-sidebar-dialog {
  position: fixed;
  inset: 0;
  z-index: 10;
  overflow: hidden;
}

.crypto-wallet-sidebar-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.crypto-wallet-sidebar-container {
  position: fixed;
  right: 0;
  max-width: 100%;
  display: flex;
  padding-left: 2.5rem;
  margin-top: 10vh;
  height: 90vh;
}

.crypto-wallet-sidebar-panel {
  position: relative;
  width: 100%;
  max-width: 28rem;
  background-color: #303335;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.crypto-wallet-sidebar-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 24rem;
}

.crypto-wallet-cover-container {
  position: relative;
  text-align: center;
}

.crypto-wallet-cover-image {
  width: 100%;
  height: 12rem;
  object-fit: cover;
}

.crypto-wallet-cover-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1; 
}

.crypto-wallet-avatar {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translateX(-50%) translateY(-50%);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 0.15rem solid #2fdbeb;
  z-index: 2;
}

.crypto-wallet-avatar-info {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(30% + 3rem);
  width: 100%;
  text-align: center;
  z-index: 2;
}

.crypto-wallet-title {
  color: white;
  font-size: 2rem;
  margin: 0;
}

.crypto-wallet-subtitle {
  color: #ccc;
  font-size: 0.5rem;
  margin: 0.25rem 0 0;
}

.crypto-wallet-close-button-container {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}

.crypto-wallet-close-button {
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
}

.crypto-wallet-close-icon {
  width: 1.5rem;
  height: 1.5rem;
}

/* Button container with titles */
.crypto-wallet-buttons-container {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  gap: 0.5rem;
  border: 1px solid rgba(250, 250, 250, 0.2);
  padding-bottom: 5px;
  align-items: center;
}

.crypto-wallet-button-wrapper {
  flex: 1;
  text-align: center;
}

.crypto-wallet-button {
  width: 60px;
  height: 60px;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Button icons */
.crypto-wallet-button-icon {
  width: 2.5rem;
  height: 2.5rem;
}

/* Individual button colors */
.crypto-wallet-button.wallet {
  background-color: #00d332; 
}

.crypto-wallet-button.buy {
  background-color: #00d1d1; 
}

.crypto-wallet-button.sell {
  background-color: #00a3fe; 
}

.crypto-wallet-button.pay {
  background-color: #0070e0; 
}

.crypto-wallet-button.trade {
  background-color: #05e5ab; 
}

.crypto-wallet-button-title {
  color: white;
  font-size: 0.75rem;
}

/* Section Title with Icon */
.crypto-wallet-section-title {
  display: flex;
  align-items: center;
  margin: 0.5rem;
}

.crypto-wallet-section-icon {
  background-color: #00d332; 
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}
.crypto-wallet-section-icon-buy {
  background-color: #00d1d1; 
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}
.crypto-wallet-section-icon-sell {
  background-color: #00a3fe; 
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}
.crypto-wallet-section-icon-pay {
  background-color: #00a3fe; 
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.crypto-wallet-section-icon-img {
  width: 1rem;
  height: 1rem;
  color: white;
}

.crypto-wallet-section-text {
  font-weight: bold;
  color: white;
  font-size: 1rem;
}

/* Form section */
.crypto-wallet-form-container {
  padding-left: 1rem;
  padding-right: 1rem;
  border-top: 1px solid rgba(250, 250, 250, 0.8);
  border-bottom: 1px solid rgba(250, 250, 250, 0.8);
}

.crypto-wallet-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.crypto-wallet-input-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

.crypto-wallet-label {
  color: white;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.crypto-wallet-input-wrapper {
  display: flex;
  align-items: center;
}

.crypto-wallet-input {
  width: 100%;
  height: 28px;
  padding: 15px;
  border-radius: 1.5rem;
  background-color: #333;
  border: 1px solid #ddd;
  outline: none;
}

.crypto-wallet-input-note {
  height: 56px;
}

/* Right-aligned content for "Amount" and "From" */
.crypto-wallet-currency,
.crypto-wallet-placeholder-balance {
  position: absolute;
  right: 1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: 1.5rem;
  margin-left: 0.5rem;
}

.crypto-wallet-placeholder-balance {
  background-color: transparent;
}

.crypto-wallet-chevron-wrapper {
  position: absolute;
  right: 1rem;
  display: flex;
  align-items: center;
}

.crypto-wallet-vertical-line {
  width: 1px;
  height: 16px;
  background-color: white;
  margin-right: 0.5rem;
}

/* Centered vertical line for "Pay With" field */
.crypto-wallet-input-group-pay-with .crypto-wallet-vertical-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.crypto-wallet-chevron-icon {
  width: 1rem;
  height: 1rem;
  color: #fff;
}

/* Summary section */
.crypto-wallet-summary {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.crypto-wallet-summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crypto-wallet-summary-item {
  color: white;
  font-size: 1rem;
}

.crypto-wallet-summary-value {
  color: white;
  font-size: 1rem;
}

.crypto-wallet-divider {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  margin: 0.5rem 0;
}
.crypto-wallet-dashboard-value-bold-part {
  font-weight: bold;
}

/* Actions section */
.crypto-wallet-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}

.crypto-wallet-action-button {
  padding: 0.5rem 1rem;
  border-radius: 1.25rem;
  width: 120px;
  cursor: pointer;
  border: none;
}

.crypto-wallet-action-button.cancel {
  background-color: #5c5c5c;
  color: white;
}

.crypto-wallet-action-button.buy {
  background-color: #36d300;
  color: white;
}
.crypto-wallet-action-button-active {
  width: 70px;
  height: 70px;
}

/* Wallet Bottom Bar Section */
.crypto-wallet-bottom-bar {
  margin-top: auto; 
  background-color: #333;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.crypto-wallet-bottom-icon {
  background-color: #00d332; 
}

.crypto-wallet-section-text { 
  font-size: 1rem;
  font-weight: bold;
}

/* Balance Container Section */
.crypto-wallet-balance-container { 
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.crypto-wallet-balance-inner-container{
  background-color: #393d3f;
  padding: 1rem;
  border-radius: 0.5rem; 
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.crypto-wallet-balance-item {
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 0.5rem 0;
  font-size: 0.875rem;
}

.crypto-wallet-balance-title {
  font-weight: bold;
}

.crypto-wallet-balance-value {
  font-weight: normal;
}


/* Pay Portal */
.crypto-wallet-pay-portal-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.crypto-wallet-pay-portal {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex: 1;
}

.crypto-wallet-pay-portal-button {
  flex: 1;
  padding: 0.75rem;
  color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: none;
}

/* Individual button colors */
.crypto-wallet-pay-portal-button.ap {
  background-color: #00a3fe; 
}

.crypto-wallet-pay-portal-button.ch {
  background-color: #0865fe; 
}

.crypto-wallet-pay-portal-button.ca {
  background-color: #00d030; 
}

.crypto-wallet-pay-portal-button.pp {
  background-color: #008cff; 
}

.crypto-wallet-pay-portal-button.x {
  background-color: #00defc; 
}

.crypto-wallet-pay-portal-button.boa {
  background-color: #ff001f; 
}

/* Chevron Icons */
.crypto-wallet-chevron-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  cursor: pointer;
}

.crypto-wallet-dashboard-item {
  flex: 1;
  background-color: #393d3f;
  padding: 1rem;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.crypto-wallet-dashboard-title {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.crypto-wallet-dashboard-value {
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.crypto-wallet-offer-button {
  padding: 1rem;
  background-color: #00d332;
}

/* Dashboard Section */
.crypto-wallet-dashboard-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  overflow-x: auto;
  padding: 0.5rem;
}

.crypto-wallet-dashboard-item {
  flex: 1;
  background-color: #393d3f;
  padding-top: 0.5rem;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.crypto-wallet-dashboard-sub-title {
  position: absolute;
  top: 1rem; 
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.875rem;
}

.crypto-wallet-dashboard-image {
  margin-top: 2rem; 
  margin-bottom: 0.5rem;
}

.crypto-wallet-dashboard-value {
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

/* Specific image heights */
.rewards-image {
  height: 40px; 
}

.convert-image {
  height: 34px; 
}

.trading-image {
  height: 40px; 
}

/* Offers Section */
.crypto-wallet-offers-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  overflow-x: auto;
  padding: 0.5rem;
}

.crypto-wallet-offer-item {
  flex: 1;
  background-color: #393d3f;
  padding: 1rem;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crypto-wallet-offer-title {
  font-size: 0.875rem;
  font-weight: bold;
}

.crypto-wallet-offer-divider {
  width: 100%;
  border: 0;
  border-top: 1px solid #fff;
  margin: 0.5rem 0;
}

.crypto-wallet-offer-accept-button {
  padding: 4px 20px;
  background-color: #00d332;
  border-radius: 15px;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
  margin-top: 0.5rem;
  width: 100%;
  font-size: 14px;
}

/* Additional Styles for the Pay Sidebar */
.crypto-wallet-transfer-buttons {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.crypto-wallet-transfer-button {
  flex: 1;
  background-color: #36d300;
  color: white;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  text-align: center;
  margin: 0 0.25rem;
  cursor: pointer;
  border: none;
  font-weight: bold;
  font-size: 12px;
}

.crypto-wallet-input-group-date-time .crypto-wallet-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.crypto-wallet-input-group-date-time .crypto-wallet-chevron-icon {
  margin-left: 0.5rem;
}

.crypto-wallet-input-group-upload {
  position: relative;
}

.crypto-wallet-input-upload {
  padding-right: 2.5rem;
}

.crypto-wallet-upload-note {
  display: block;
  font-size: 0.75rem;
  color: #888;
  margin-top: 0.5rem;
}

.crypto-wallet-section-icon-pay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00aaff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.crypto-wallet-input-group-date-time-wrapper {
  display: flex;
  gap: 1rem; 
}

.crypto-wallet-input-group-date-time {
  flex: 1;
}

.crypto-wallet-payment-options {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding-left: 10px;
  padding-right: 10px;
}

.crypto-wallet-option-button {
  flex: 1;
  background-color: #2d2f31;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 2px 14px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.crypto-wallet-option-button:hover {
  background-color: #3c3f42;
}

.crypto-wallet-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #333;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  z-index: 10;
  overflow: hidden;
}

.crypto-wallet-dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: white;
  cursor: pointer;
}

.crypto-wallet-dropdown-item:hover {
  background-color: #444;
}

.crypto-wallet-dropdown-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

/* Gambling page */

.gambling-page-casino-landing-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.gambling-page-casino-landing-page-wrapper {
  margin-left: 120px;
  margin-right: 120px;
}

/* Hero Slider */
.gambling-page-hero-slider {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 15px;
  height: 420px;
}

.gambling-page-slides-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  position: relative;
  width: 100%;
}


.gambling-page-slider-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.gambling-page-slide {
  position: relative;
  min-width: 100%;
  display: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.gambling-page-active {
  display: block;
  opacity: 1;
}

.gambling-page-slide-content {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%); 
  width: 690px;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  z-index: 1; 
}

.gambling-page-slide-content h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.gambling-page-slide-button {
  background-color: #36d300;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1rem;
}

.gambling-page-slide-button:hover {
  background-color: #0056b3;
}



.gambling-page-slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
}

.gambling-page-prev {
  left: 10px;
}

.gambling-page-next {
  right: 10px;
}

/* Call-to-Action Boxes */
.gambling-page-cta-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  height: 250px;
}

.gambling-page-cta-box {
  flex: 1;
  background-color: rgba(250, 250, 250, 0.2);
  padding: 20px;
  margin: 0 10px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gambling-page-cta-box h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #007bff;
}

.gambling-page-cta-box p {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
}

.gambling-page-cta-button {
  background-color: #36d300;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1rem;
}

.gambling-page-cta-button:hover {
  background-color: #0056b3;
}

/* List of Games */
.gambling-page-games-list {
  margin-bottom: 40px;
}

.gambling-page-games-list h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  text-align: center;
}

.gambling-page-games-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.gambling-page-game-card {
  background-color: #333;
  width: calc(25% - 20px);
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gambling-page-game-card h4 {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #fff;
}

.gambling-page-game-image {
  width: 100%;
  height: 200px;
  max-height: 200px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .gambling-page-cta-section {
    flex-direction: column;
  }

  .gambling-page-cta-box {
    margin-bottom: 20px;
  }

  .gambling-page-games-grid {
    flex-direction: column;
  }

  .gambling-page-game-card {
    width: 100%;
  }
}

/* Ad set modal */
.ad-set-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.ad-set-modal {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 10px;
  width: 90%;
  max-width: 100%;
  height: 90vh;
  max-height: 90vh; 
  position: relative;
  overflow-y: auto; 
}
.ad-set-modal h2 {
font-weight: bold;
font-size: 24px;
margin-bottom: 10px;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
}

.ad-format-section {
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  padding: 15px;
  background-color: rgba(250, 250, 250, 0.2);
}
.ad-format-section h3 { 
font-weight: bold;
}


.ad-format-controls {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: 10px; 
}

.ad-format-controls label {
  margin-bottom: 10px;
}

.ad-format-preview {
  margin-top: 10px;
}

.ad-format-preview img {
  border: 1px solid #ddd;
}

.customize-ad-set-button-container {
  margin-top: 20px;
  margin-bottom: 20px; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%;
}
.ad-set-modal-edit-button {
  margin-left: 15px;
  background-color: #36d300;
  color: white;
  border: none;
  width: 70px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.ad-set-modal-edit-button:hover {
  background-color: #3d6000;
}

.customize-ad-set-button {
  background-color: #36d300;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.customize-ad-set-button.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}
.customize-ad-set-button:hover {
  background-color: #36d000;
}

.save-ad-set-button {
  background-color: #36d300;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.save-ad-set-button:hover {
  background-color: #3d6000;
}

.draggable-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  cursor: grab;
}

.draggable-container:active {
  cursor: grabbing;
}

.draggable-container img {
  position: absolute;
  top: 0;
  left: 0;
  cursor: inherit;
  user-select: none;
  max-width: none; 
  max-height: none; 
}
/* Upload button */
/* Hide the actual file input */
.ad-preview-upload-input {
  display: none;
}

/* Style for the custom file upload icon */
.ad-preview-upload-icon-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

/* Style for the upload icon (default state) */
.ad-preview-upload-icon {
  font-size: 24px;
  color: #666;
  transition: color 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ad-preview-upload-icon.uploaded {
  color: #28a745; 
  transform: scale(1.2); 
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
  box-shadow: 0 0 10px 5px rgba(40, 167, 69, 0.6); 
}

/* Style for the uploaded image text */
.ad-preview-upload-text {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  margin-left: 10px; 
}


/* Top banner ad preview */

/* Top Banner Ad Container */
.ad-preview-top-banner-container {
  width: 100%;
  padding: 32px 48px;
  box-sizing: border-box;
  position: relative;
}

/* Top Banner Content */
.ad-preview-top-banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 15px 25px;
  border-radius: 50px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  background-blend-mode: darken;
}

/* Wrapper for the Image in Preview */
.ad-preview-top-banner-image-container {
  position: absolute; 
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
}

/* Draggable Image */
.ad-preview-top-banner-image {
  position: absolute;
  top: 0;
  left: 0;
  cursor: grab;
  z-index: 1; 
  object-fit: cover;
  width: 100%;
}

/* Text Content Wrapper */
.ad-preview-top-banner-text {
  z-index: 2; 
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Text Link */
.ad-preview-top-banner-ad-link {
  color: white;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  flex-grow: 1;
}

/* Dismiss Button */
.ad-preview-top-banner-ad-dismiss {
  background: none;
  border: none;
  padding: 12px;
  cursor: pointer;
  z-index: 2; 
}

.ad-preview-top-banner-ad-icon {
  height: 20px;
  width: 20px;
  color: white;
}

/* ad preview for sponsored product */

/* Sponsored product AD Preview */
.ad-preview-sponsored-products-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-left: 1px solid rgba(250, 250, 250, 0.6);
  border-right: 1px solid rgba(250, 250, 250, 0.6);
  margin: 10px auto;
  max-width: 320px;
}

.ad-preview-sponsored-products-title {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.ad-preview-sponsored-products-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ad-preview-sponsored-products-item {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.ad-preview-sponsored-products-image-container {
  position: relative;
  width: 100%;
  height: 300px; 
  overflow: hidden; 
}

.ad-preview-sponsored-products-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  cursor: grab;
  object-fit: cover;
  z-index: 1; 
}

.ad-preview-sponsored-products-info {
  background-color: rgba(84, 82, 82, 0.5);
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2; 
  position: relative;
}

.ad-preview-sponsored-products-product-name {
  font-size: 16px;
  margin: 0;
}

.ad-preview-sponsored-products-product-price {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
}

.ad-preview-sponsored-products-visit-store {
  font-size: 12px;
  text-decoration: none;
  color: #1f9c29;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
}


/* Banner ad for product Preview */
.ad-preview-banner-ad-container {
  position: relative;
  z-index: 50;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 25px;
  margin-bottom: 20px;
  overflow: hidden; 
  border-radius: 8px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.75);
}

/* Image container for draggable image */
.ad-preview-banner-ad-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden; 
}

/* Draggable Image */
.ad-preview-banner-ad-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  cursor: grab;
  z-index: 2; 
  object-fit: cover;
}

/* Content overlay on top of the image */
.ad-preview-banner-ad-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
}

.ad-preview-banner-ad-dismiss {
  margin: -12px;
  padding: 12px;
  z-index: 3;
}

.ad-preview-banner-ad-icon {
  height: 20px;
  width: 20px;
  color: white;
  z-index: 3;
}

.ad-preview-banner-ad-link{
  color: white;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  flex-grow: 1;
}

/* Dual Product ad carousel Preview */
.ad-preview-dual-product-carousel-container {
  padding: 0px;
  border-radius: 10px;
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.ad-preview-dual-product-carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; 
}

.ad-preview-dual-product-carousel-pair {
  display: flex;
  width: 100%; 
}

.ad-preview-dual-product-carousel-item {
  background-position: center center;
  background-size: cover;
  height: 400px;
  width: 50%; 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  margin: 1%;
  border-radius: 10px;
  overflow: hidden;
}

/* Container for the draggable image */
.ad-preview-dual-product-carousel-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Draggable Image */
.ad-preview-dual-product-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  cursor: grab;
  z-index: 1; 
  object-fit: cover;
  background: rgba(0, 0, 0, 0.5);
}

/* Content overlay on top of the image */
.ad-preview-dual-product-carousel-content {
  padding: 20px;
  text-align: center;
  align-content: center !important;
  border: none !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  text-align: center !important;
  width: 99.9% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ad-preview-dual-product-carousel-title {
  font-size: 2em;
  margin: 0;
  z-index: 2
}

.ad-preview-dual-product-carousel-price {
  font-size: 1.25em;
  margin: 10px 0;
  z-index: 2
}

.ad-preview-dual-product-carousel-button {
  background-color: #36d300 !important;
  color: white;
  border-radius: 50px !important;
  padding: 5px 25px !important;
  margin: 25px !important;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  z-index: 2
}

.ad-preview-dual-product-carousel-button:hover {
  background-color: #36d300;
}

.ad-preview-dual-product-carousel-item.empty-box {
  background-color: rgba(250, 250, 250, 0.2); 
  border: 2px dashed rgba(250, 250, 250, 0.6);
}

/* Quad product carousel Preview */
.ad-preview-quad-product-carousel-container {
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 10px;
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.ad-preview-quad-product-carousel-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.ad-preview-quad-product-carousel-item {
  background-position: center center;
  background-size: cover;
  flex: 1 1 48%;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  margin: 1%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

/* Container for the draggable image */
.ad-preview-quad-product-carousel-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

/* Draggable Image */
.ad-preview-quad-product-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: grab;
  z-index: 1;
  object-fit: cover;
}

/* Content overlay on top of the image */
.ad-preview-quad-product-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  align-content: center !important;
  border: none !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  text-align: center !important;
  width: 99.9% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ad-preview-quad-product-carousel-title {
  font-size: 1.5em;
  margin: 0;
  z-index: 2;
}

.ad-preview-quad-product-carousel-price {
  font-size: 1em;
  z-index: 2;
}

.ad-preview-quad-product-carousel-button {
  margin-top: 10px;
  color: white;
  background-color: #36d300 !important;
  border-radius: 50px !important;
  margin: 25px !important;
  padding: 5px 25px !important;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  z-index: 2;
}

.ad-preview-quad-product-carousel-button:hover {
  background-color: #36d300;
}

.ad-preview-quad-product-carousel-item.empty-box {
  background-color: rgba(250, 250, 250, 0.2); 
  border: 2px dashed rgba(250, 250, 250, 0.6); 
}

/* Sponsored product carousel AD Preview */
.ad-preview-sponsored-product-carousel {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  margin: 20px 0;
}

/* Image container for draggable image */
.ad-preview-sponsored-product-carousel-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

/* Draggable Image */
.ad-preview-sponsored-product-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: grab;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

/* Content overlay on top of the image */
.ad-preview-sponsored-product-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 99.9% !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  
}

.ad-preview-sponsored-product-carousel-title {
  font-size: 2em;
  margin: 0;
  z-index: 3;
}

.ad-preview-sponsored-product-carousel-price {
  font-size: 1.25em;
  margin: 10px 0;
  z-index: 3;
}

.ad-preview-sponsored-product-carousel-button {
  background-color: #36d300 !important;
  color: white;
  padding: 5px 25px !important;
  border: none;
  border-radius: 50px !important;
  cursor: pointer;
  font-size: 16px;
  margin: 25px 25px !important;
  text-decoration: none;
  transition: background-color 0.3s;
  z-index: 3;
}

.ad-preview-sponsored-product-carousel-button:hover {
  background-color: #36d300;
}

.ad-preview-sponsored-product-carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 3;
}

.ad-preview-sponsored-product-carousel-prev {
  left: 10px;
}

.ad-preview-sponsored-product-carousel-next {
  right: 10px;
}

.ad-preview-sponsored-product-carousel-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Container for the map product ad slider preview in the modal */
.ad-preview-map-product-slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

/* Wrapper to hold the slider item */
.ad-preview-map-product-slider-wrapper {
  width: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

/* Individual slider item */
.ad-preview-map-product-slider-item {
  background-position: center center;
  background-size: cover;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

/* Image container for the draggable image */
.ad-preview-map-product-slider-image-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Image inside the slider item */
.ad-preview-map-product-slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 20px;
}

/* Content overlay inside the slider item */
.ad-preview-map-product-slider-content {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
}

/* Title inside the slider item */
.ad-preview-map-product-slider-title {
  font-size: 1.2em;
  margin: 0;
}

/* Price inside the slider item */
.ad-preview-map-product-slider-price {
  font-size: 1em;
  margin: 0;
}

/* Button inside the slider item */
.ad-preview-map-product-slider-button {
  background-color: #4caf50;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.ad-preview-sponsored-shops-container {
  width: 100%;
  height: 240px;
  text-align: center;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #444;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.ad-preview-sponsored-shops-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.ad-preview-sponsored-shops-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; 
}

.ad-preview-sponsored-shops-image {
  position: absolute; 
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0; 
  object-fit: cover;
  transition: filter 0.3s ease;
}

.ad-preview-sponsored-shops-container:hover .ad-preview-sponsored-shops-image {
  filter: brightness(0.7);
}

.ad-preview-sponsored-shops-content {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: white;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.4); 
  padding: 10px;
  border-radius: 5px;
}

.ad-preview-sponsored-shops-title {
  font-size: 1.5em;
  margin-bottom: 5px;
  color: white;
}

.ad-preview-sponsored-shops-link {
  background: #36d300;
  padding: 8px 16px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.ad-preview-sponsored-shops-link:hover {
  background: #138c1f;
}

.ad-preview-sponsored-shops-dismiss {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
}



/* AdFormatIconRow  */
.ad-format-icon-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  background-color: rgba(250, 250, 250, 0.2);
}

.ad-format-icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.ad-format-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px;
}

.ad-format-label {
  font-size: 10px;
  text-align: center;
  color: rgba(250, 250, 250, 0.6);
  line-height: 14px;
}

/* New styles for the active state */
.ad-format-icon-item.active {
  transform: scale(1.1);
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 10px;
  padding: 10px;
}




/* Create custom ad component */

.create-custom-ad-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 15px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  max-width: 600px;
  margin: auto;
  color: rgba(250, 250, 250, 1);
}

.create-custom-ad-container h2 {
  text-align: center;
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
}

.form-group input[type="text"],
.form-group input[type="number"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  background-color: rgba(250, 250, 250, 0.1);
  color: rgba(250, 250, 250, 1);
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  padding: 10px;
  border-radius: 10px;
}

.product-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(250, 250, 250, 0.2);
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.product-item.selected {
  background-color: #1f9c29;
}

.product-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.create-ad-button {
  width: 100%;
  padding: 15px;
  background-color: #36d300;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.create-ad-button:hover {
  background-color: #145d10;
}

/* Container for the entire page */
.shop-create-advertisement-campaign-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden; 
}

/* Layout that includes the dashboard sidebar */
.ad-dashboard-layout {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

/* Content section with grid layout */
.shop-create-advertisement-campaign-content {
  display: grid;
  grid-template-columns: 1fr 3fr; 
  flex-grow: 1;
  max-width: 95vw;
  padding: 20px;
  overflow: hidden;
  gap: 10px; 
}

/* Sidebar container */
.shop-create-advertisement-campaign-sidebar {
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.6);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px; 
  height: 100%; 
  overflow-y: auto; 
}

/* Main content container */
.shop-create-advertisement-campaign-main {
  display: flex;
  flex-direction: column;
  
  height: 100%;
  overflow-y: auto; 
}

/* Box for creating a campaign */
.shop-create-advertisement-campaign-box {
 
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  
  align-content: center;
  padding: 20px;
}
.shop-create-advertisement-campaign-box-modal {
 width: 75%;
}

.shop-create-advertisement-campaign-box:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Campaign start button */
.shop-create-advertisement-campaign-button {
  display: block;
  padding: 15px;
  background-color: #36d300;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.shop-create-advertisement-campaign-button:hover {
  background-color: #3d6000;
}

/* Campaign title */
.shop-create-advertisement-campaign-title {
  border-top: 1px solid rgba(250, 250, 250, 0.3);
  padding-top: 10px;
  font-size: 22px;
  color: #ffffff;
  padding-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

/* Options for selecting ad types */
.shop-create-advertisement-campaign-options {
  padding: 10px 0;
  border-top: 1px solid rgba(250, 250, 250, 0.3);
}

/* Individual option item */
.shop-create-advertisement-campaign-option-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  padding: 20px;
  border-radius: 60px;
  cursor: pointer;
  border: 1px solid rgba(250, 250, 250, 0.4);
  width: 160px;
  height: 160px;
  justify-content: center;
}

.shop-create-advertisement-campaign-option-item:hover {
  background-color: rgba(250, 250, 250, 0.15);
}
.shop-create-advertisement-campaign-option-item.selected {
  box-shadow: 0 0 15px 7px rgba(54, 211, 0, 0.7); 
  transform: scale(1.05); 
}

.shop-create-advertisement-campaign-option-item input {
  margin-right: 10px;
  transform: scale(1.2);
  cursor: pointer;
}

.shop-create-advertisement-campaign-option-item label {
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
}

/* Expanded sections for selected ad types */
.shop-create-advertisement-campaign-expanded-sections {
  padding-top: 20px;
  border-top: 1px solid rgba(250, 250, 250, 0.3);
  animation: fadeIn 0.3s ease-in-out;
}

/* Responsive Design */
@media (max-width: 768px) {
  .shop-create-advertisement-campaign-content {
    grid-template-columns: 1fr; 
  }

  .shop-create-advertisement-campaign-sidebar,
  .shop-create-advertisement-campaign-main {
    width: 100%; 
  }
}

.shop-create-advertisement-campaign-options-row {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
}

.shop-create-advertisement-campaign-option-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.shop-create-advertisement-campaign-icon-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.shop-create-advertisement-campaign-option-icon {
  font-size: 40px; /* Icon size */
  color: #5a5a5a; /* Icon color */
}

.shop-create-advertisement-campaign-option-label {
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.shop-create-advertisement-campaign-toggle-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-create-advertisement-campaign-switch-input {
  position: absolute;
  opacity: 0;
}

.shop-create-advertisement-campaign-switch-paddle {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.shop-create-advertisement-campaign-switch-paddle:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  top: 2.5px;
  left: 2.5px;
  transition: left 0.2s;
}

.shop-create-advertisement-campaign-switch-input:checked + .shop-create-advertisement-campaign-switch-paddle:before {
  left: 27px; /* Move the toggle to the right */
  background-color: #4CAF50; /* Green for active state */
}



@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  
}

.switch-input {
  display: none;
}

.switch-paddle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.switch-paddle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switch-input:checked + .switch-paddle {
  background-color: #36d300;
}

.switch-input:checked + .switch-paddle:before {
  transform: translateX(26px);
}

.switch-label {
  font-size: 16px;
  color: #ffffff;
  vertical-align: middle;
}

/* Create Ad Steps component */

.create-ad-steps-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.create-ad-steps-content {
  width: 100%;
  max-width: 1200px; 
  display: flex;
  flex-wrap: wrap;
  align-items: center; 
  box-sizing: border-box;
  overflow: hidden;
}

.create-ad-flex {
  display: flex;
  align-items: center;
  flex-grow: 1; 
  justify-content: space-around; 
}

/* Gradient Background for the Step Container */
.create-ad-step {
  padding: 20px; 
  position: relative;
  overflow: hidden;
}


.create-ad-step::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(54, 211, 0, 0.8) 0%, rgba(54, 211, 0, 0) 100%);
  opacity: 0;
  transition: opacity 0.8s ease; 
  z-index: 0;
}

.create-ad-step.past::before {
  background-color: rgba(54, 211, 0, 0.8); 
  opacity: 1;
}

.create-ad-step.active::before {
  opacity: 1; 
}

.create-ad-step-button {
  padding: 0 20px;
  height: 38px;
  border-radius: 20px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  background-color: rgba(0, 0, 0, 0.2); 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0; 
  z-index: 1; 
}

.create-ad-step-text {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.create-ad-step-text.past-text {
  color: #fff;
}
.create-ad-step-text.inactive-text {
  color: rgba(250, 250, 250, 0.6); 
}

.create-ad-line {
  width: 100%;
  height: 4px;
  background-color: #fde1e6;
  flex-grow: 1; 
}

.create-ad-line.active {
  background-color: #f63b60;
}

@media (min-width: 800px) {
  .create-ad-line {
    height: 4px;
    width: auto;
    flex-grow: 1;
  }
}

.create-ad-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%; 
  box-sizing: border-box;
}

.create-ad-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  flex-grow: 1;  
}

.create-ad-start-button {
  background-color: #36d300;
  color: white;
  border-radius: 20px;
}

.create-ad-previous-button {
  background-color: #6c757d;
  color: white;
  border-radius: 20px;
}

.create-ad-next-button {
  background-color: #36d300;
  border-radius: 20px;
  color: white;
}

.create-ad-complete-button {
  background-color: #28a745;
  color: white;
  border-radius: 20px;
}



/* ad campaign preview component */
.ad-campaign-ad-preview-container {
  padding-top: 20px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 10px;
  max-width: 900px;
  margin: 0 auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.ad-campaign-ad-preview-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
}

/* Empty message styling */
.ad-campaign-ad-preview-empty-message {
  text-align: center;
  color: #999;
}

/* List styling */
.ad-campaign-ad-preview-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Item container styling */
.ad-campaign-ad-preview-item {
  background-color: rgba(250, 250, 250, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

/* Item title styling */
.ad-campaign-ad-preview-item-title {
  font-size: 20px;
  margin-bottom: 15px;
  color: #444;
}

/* Details section styling */
.ad-campaign-ad-preview-details p {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

/* Images container styling */
.ad-campaign-ad-preview-images {
  margin-top: 15px;
}

.ad-campaign-ad-preview-image-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ad-campaign-ad-preview-image-item p {
  margin-right: 10px;
  font-size: 14px;
  color: #666;
}

.ad-campaign-ad-preview-image-item img {
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Edit button styling */
.ad-campaign-ad-preview-edit-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #36d300;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ad-campaign-ad-preview-edit-button:hover {
  background-color: #3d6000;
}

.shop-create-advertisement-checkout-summary {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 10px;
  background-color: rgba(250, 250, 250, 0.1);
}

.shop-create-advertisement-checkout-summary p {
  margin: 5px 0;
  font-size: 16px;
}


/* Top performing ads component */
.top-performing-ads-container {
  background-color: rgba(250, 250, 250, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.top-performing-ads-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #fff;
  text-align: center;
}

.top-performing-ads-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.top-ad-card {
  display: flex;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease-in-out;
}

.top-ad-card:hover {
  transform: translateY(-5px);
}

.top-ad-rank {
  width: 70px;
  font-size: 32px;
  font-weight: bold;
  color: #36d300;
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-right: 2px solid #36d300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-ad-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 10px;
}

.top-ad-image img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.top-ad-details {
  flex-grow: 1;
  position: relative; 
}

.top-ad-name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.top-ad-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.top-ad-stats {
  display: flex;
  gap: 15px;
  font-size: 14px;
  color: #fff;
}

/* Ellipsis icon */
.top-ad-ellipsis {
  position: absolute;
  top: -18px;
  right: 1px;
  cursor: pointer;
  font-size: 1.5em;
  color: #fff; 
  z-index: 1; 
  padding: 5px; 
}


/* Modal styles */
.top-performing-ad-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.top-performing-ad-details-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.top-performing-ad-details-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
}

.rerun-ad-button {
  top: 10px;
  left: 10px;
  background-color: #36d300;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.rerun-ad-button:hover {
  background-color: #28a200;
}

.top-performing-ad-details-content {
  position: relative;
  background: rgba(0, 0, 0, 0.8); 
  border: 1px solid rgba(250, 250, 250, 0.3);
  padding: 20px;
  border-radius: 10px;
  z-index: 1001;
  max-width: 800px;
  width: 90%;
  color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 90%;
}

.top-performing-ad-details-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
}

.top-performing-ad-details-content h3 {
  margin-top: 30px;
  color: #f63b60; 
}

.ad-details-section {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 16px; 
  color: #ffffff; 
  margin: 20px 0; 
}

.ad-details-item {
  border-left: 2px solid rgba(255, 255, 255, 0.6); 
  padding-left: 16px; 
  display: flex;
  flex-direction: column;
  gap: 8px; 
}

.ad-details-name {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7); 
}

.ad-details-value {
  font-size: 20px;
  font-weight: 600; 
  color: #ffffff;
}


.ad-details-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.ad-details-list li {
  margin: 5px 0;
}

.ad-details-list a {
  color: #36d300; 
  text-decoration: none;
}

.ad-details-list a:hover {
  text-decoration: underline;
}

/* Graph styles */
.ad-details-graph {
  margin-top: 20px;
  background-color: rgba(250, 250, 250, 0.1);
  padding: 20px;
  border-radius: 10px;
}

/* Campaign Totals */
.campaign-totals-container {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 25%;
  margin-top: 18px;
}

.campaign-totals-header {
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.campaign-totals-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.campaign-totals-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 250, 250, 0.1);
  padding: 16px;
  border-radius: 10px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.campaign-totals-item-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #888;
  margin-bottom: 8px;
}

.campaign-totals-item-value {
  font-size: 1.75rem;
  font-weight: 600;
  color: #333;
}

.shop-create-advertisement-campaign-top {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex: 2;
}

.ad-overview-row {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 12px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  display: flex;
  align-items: center;
}

.ad-overview-content {
  display: flex;
  align-items: center;
}

.ad-overview-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ad-overview-details {
  flex-grow: 1;
}

.ad-overview-details h4 {
  margin-bottom: 8px;
  font-size: 1.2em;
  font-weight: bold;
}

.ad-overview-details p {
  margin: 4px 0;
}

.ad-overview-details button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #36d300;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ad-overview-details button:hover {
  background-color: #0056b3;
}

.profile-main-content {
    display: flex;
    gap: 15px !important;
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    padding-left: 7% !important;
    padding-right: 7% !important;
    width: 100%;
}

.w-full img.w-full.object-cover {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.sponsored-products-component-visit-store {
    background-color: #ffb511 !important;
    border-radius: 50px;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500 !important;
    padding: 5px 15px;
    text-decoration: none;
}

.shop-info-sidebar-top-row {
    background-color: #fafafa00 !important;
    border: none !important;
    /* border-bottom: 1px solid #fafafa80 !important; */
    padding-bottom: 15px !important;
    padding-top: 25px !important;
}


/*TEMP*/


.other-followers-list-widget-wrapper {
    background-color: transparent !important;
    border-radius: 8px;
    box-shadow: none !important;
    padding: 15px !important;
}

.shop-profile-data-tab-active {
    border-bottom-color: rgba(250, 250, 250, 0.4) !important;
    border-bottom: 3px solid rgba(250, 250, 250, 0.5) !important;
    color: #ffffff !important;
    font-weight: 700 !important;
}

.sponsored-products-component-item {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
}

.shop-info-sidebar-middle-row {
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 15px !important;
    padding: 15px !important;
    padding-top: 0px !important;
    background-color: rgba(250, 250, 250, 0.1) !important;
}

.other-followers-list-widget-search {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-radius: 50px !important;
    margin-bottom: 1.5rem;
    padding: .5rem;
    width: 100%;
    padding-left: 15px !important;
}


.categories-dropdown-header-product-shop {
    color: rgba(250, 250, 250, 0.9) !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}


.shop-info-sidebar-bottom-row {
    align-items: center;
    border-top: none !important;
    display: flex;
    flex-direction: column;
    gap: 0px !important;
    margin-top: 5px !important;
    padding-top: 10px !important;
}

.connections-widget-container {
    background-color: transparent !important;
    border: none !important;
    border-radius: 8px;
    box-shadow: 0 2px 10px #0000001a;
    padding: 0px !important;
}

.category-section-component-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding: 0px !important;
}

.category-section-component-bg {
    background-color: rgba(250, 250, 250, 0.0) !important;
}

.trending-products-carousel-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100% !important;
    padding: 0px !important;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
    color: rgba(250, 250, 250, 1.0) !important;
    margin: 0;
}

.carousel-4-slides-component-paper {
    align-items: center;
    background-position: 50%;
    background-size: cover;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;
    position: relative;
    border-radius: 15px !important;
}

.shop-card {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: 10px;
    flex-shrink: 0;
    padding: 10px;
    text-align: center;
    width: 200px;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-1hw9j7s {
    background-color: #ffb511 !important;
    border-radius: 50px !important;
    margin: 10px !important;
    padding: 0px 0px !important;
}

.map-page-shop-item-avatar {
    border: 2px solid #fff;
    border-radius: 50%;
    height: 88px !important;
    left: 16px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 88px !important;
}

.map-product-ad-slider-paper {
    align-items: center;
    background-position: 50%;
    background-size: cover;
    border-radius: 15px !important;
    color: #fff;
    display: flex;
    height: 200px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 200px;
}

.events-page-aside {
    background-color: rgba(250, 250, 250, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    height: 100vh;
    padding: 20px;
    width: 25%;
}

.events-page-create-event-button {
    background-color: #36d300;
    border: none;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    padding: 7px 25px !important;
    transition: background-color .3s;
}

.crypto-wallet-sidebar-panel {
    background-color: #303335;
    box-shadow: 0 .5rem 1rem #00000080;
    display: flex;
    flex-direction: column;
    max-width: 28rem;
    overflow-y: auto;
    position: relative;
    width: 100%;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
}

.map-page-shop-item {
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    border: none !important;
    border-radius: 0 !important;
    color: #fafafa !important;
    padding: 7px 15px !important;
    border-radius: 50px !important;
}

.crypto-wallet-sidebar-panel {
    background-color: rgba(0, 0, 0, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    box-shadow: 0 .5rem 1rem #00000080;
    display: flex;
    flex-direction: column;
    max-width: 28rem;
    overflow-y: auto;
    position: relative;
    width: 100%;
}

aside.main-feed-page-sticky-sidebar.hidden.lg\:block.w-full.lg\:w-1\/4.shrink-0.bg-\[\#f5f5f5\].p-2.rounded-lg.lg\:order-1.main-feed-page-scrollable {
    padding: 15px !important;
}


.avatar-dropdown-menu-popup, .cart-sidebar-popup {
  background-color: #0c1117e6 !important;
  border-left: 1px solid rgba(250, 250, 250, 0.3) !important;
  box-shadow: none !important;
  height: 100%;
  max-width: 35% !important;
  overflow-y: auto;
  padding: 20px;
  position: fixed;
  right: 0;
  width: 25% !important;
  top: 10.4% !important;
}

.event-creation-form-file-input, .event-creation-form-input, .event-creation-form-textarea {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px;
  color: rgba(250, 250, 250, 0.9) !important;
  font-size: 16px;
  margin-bottom: 7px;
  margin-top: 5px;
  padding: 8px 15px !important;
  transition: border-color .3s, background-color .3s;
  width: 100%;
}




.profile-main {
  display: grid;
  grid-template-columns: 25% 47% 25%; 
  gap: 20px; 
  margin-top: 15px !important;
  padding-left: 7%;
  padding-right: 7%;
  width: 100% !important;
  align-items: center !important;
}

.profile-info-container {
  grid-column:  2 / 3;
  z-index: 10;
}

.profile-extra-container {
  grid-column: 1 / 2; 
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 10px;
  height: 310px;
  overflow: hidden;
}

.profile-extra-image {
  max-width: 100%;
  border-radius: 10px; 
  object-fit: cover; 
}

.profile-mycontent-carousel {
  grid-column: 3 / 4; 
  display: flex;
  justify-content: flex-end;
}


/* Dubs additions */

.newsletter-signup-input {
  border: none;
  border-radius: 50px !important;
  color: #2d3748;
  margin-bottom: 1rem;
  outline: none;
  padding: .625rem;
  padding-left: 15px !important;
  width: 100%;
}

.css-dejdd {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
  border-radius: 100% !important;
  height: 60.5px !important;
  width: 60.5px !important;
}

.avatar-component-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
  border-radius: 50%;
  cursor: pointer;
  height: 65px !important;
  max-width: none !important;
  width: 65px !important;
}

.switcheroo-current-profile-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
  border-radius: 100%;
  height: 42px;
  max-width: 42px;
  width: 42px;
}

img.h-10.w-10.rounded-full {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

.post-widget-image {
  border-radius: .75rem;
  margin-top: .5rem;
  border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

p.MuiTypography-root.MuiTypography-body1.css-5vij8j span {
  margin: 1rem 5px 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #46d300 !important;
}


/* FollowersAvatarDisplay */

.followers-avatar-display-container {
  display: flex;
  align-items: center;
  gap: 16px; 
}

.followers-avatar-display-images {
  display: flex;
  position: relative;
  padding: 4px;
  margin-right: -8px; 
}

.followers-avatar-display-image {
  z-index: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: -8px; 
}

.followers-avatar-display-image:first-child {
  margin-left: 0;
}

.followers-avatar-display-count {
  font-size: 14px;
  font-weight: 600;
}

/* Main header */

.main-header-component-container {
  margin: 0 auto;
  padding: 25px 20px;
  background-color: rgba(0, 0, 0, 0.9);
  position: sticky;
  top: 0;
  z-index: 50;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
}

.main-header-component-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-left: 50px;
  
}

.main-header-component-site-logo {
  margin-top: 12px;
  cursor: pointer;
  max-width: 150px;
}

.main-header-component-categories {
  position: relative;
  height: 60px;
  margin-top: 10px;
  width: 270px;
  display: flex;
  align-items: center;
  background-color: #fafafa1a !important;
    border: 1px solid #fafafa80 !important;
    border-bottom: none !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    bottom: 0;
    margin-bottom: -25px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.main-header-component-menu-icon {
  position: absolute;
  top: 15px;
  left: 10px;
}

.main-header-component-categories-button {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
}

.main-header-component-arrow-icon {
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
}

.main-header-component-actions {
  display: flex;
  align-items: center;
}

.main-header-component-action {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.main-header-component-wishlist,
.main-header-component-cart {
  position: relative;
  cursor: pointer;
}

.main-header-component-wishlist-count,
.main-header-component-cart-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #3bc177;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  border-radius: 50%;
}

.main-header-component-avatar {
  position: relative;
  cursor: pointer;
}

.main-header-component-avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
}

.main-header-component-mobile-header {
  width: 100%;
  height: 60px;
  background-color: white;
  z-index: 50;
  top: 0;
  left: 0;
  position: fixed;
}

.main-header-component-mobile-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.main-header-component-mobile-menu-icon {
  cursor: pointer;
}

.main-header-component-mobile-logo {
  margin-top: 3px;
  cursor: pointer;
  max-width: 100px;
}

.main-header-component-mobile-cart {
  position: relative;
  cursor: pointer;
}

.main-header-component-mobile-cart-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #3bc177;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  border-radius: 50%;
}

.main-header-component-sidebar {
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
}

.main-header-component-sidebar-inner {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.main-header-component-sidebar-top {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.main-header-component-wishlist-icon {
  position: relative;
  cursor: pointer;
}

.main-header-component-close-icon {
  cursor: pointer;
}

.main-header-component-search-box {
  margin: 15px;
  position: relative;
}

.main-header-component-search-input {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 2px solid #46d300;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.main-header-component-search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.main-header-component-clear-search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.main-header-component-search-dropdown {
  position: absolute;
  top: 45px;
  width: 100%;
  background-color: black;
  padding: 10px;
  z-index: 9;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.main-header-component-search-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-header-component-search-item:hover {
  background-color: #3bc177;
}

.main-header-component-search-item-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}

.main-header-component-search-item-title {
  font-size: 16px;
  color: white;
  font-weight: 600;
}

.main-header-component-search-item-details {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.main-header-component-search-item-brand,
.main-header-component-search-item-shop {
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 2px 6px;
  border-radius: 5px;
  margin-right: 5px;
}

.main-header-component-search-item-brand {
  background-color: blue;
}

.main-header-component-search-item-shop {
  background-color: green;
}

.main-header-component-become-seller {
  margin: 20px;
  text-align: center;
}

.main-header-component-become-seller-text {
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-header-component-become-seller-icon {
  margin-left: 5px;
}

.main-header-component-mobile-user-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.main-header-component-login-link,
.main-header-component-signup-link {
  font-size: 18px;
  color: black;
  margin-right: 10px;
}

.main-header-component-avatar-menu {
  position: relative;
  display: inline-block;
}

.main-header-component-avatar-dropdown {
  position: absolute;
  right: 0;
  top: 70px;
  width: 150px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.main-header-component-avatar-dropdown-content {
  padding: 10px;
}

.main-header-component-avatar-dropdown-item {
  display: block;
  padding: 10px;
  color: black;
  font-size: 14px;
  text-decoration: none;
}

.main-header-component-avatar-dropdown-item-active {
  background-color: gray;
  color: white;
}

/* Hide the mobile header by default (visible on mobile only) */
.main-header-component-mobile-header {
  display: none; /* Hidden by default */
}

/* Show mobile header only on screens smaller than 800px */
@media (max-width: 800px) {
  .main-header-component-mobile-header {
    display: block;
  }

  .main-header-component-wrapper {
    display: none; /* Hide the desktop header on mobile */
  }
}

/* Ensure the desktop header is shown on larger screens */
@media (min-width: 800px) {
  .main-header-component-wrapper {
    display: flex; /* Show the desktop header on larger screens */
  }
}

.main-header-component-cart-count, .main-header-component-wishlist-count {
  align-items: center;
  background-color: #36d300 !important;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
}

.top-banner-ad-container {
  box-sizing: border-box;
  padding: 12px 48px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

body {
  background-image: url(https://res.cloudinary.com/dzlopmfj8/image/upload/v1721666994/banners/lbdsruo3aq4jxh4q2sle.jpg) !important;
}

.switcheroo-close-icon {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 15px;
  top: 8px;
  font-weight: 100 !important;
}

.event-card-component-button {
  background-color: #ffb511;
  border: none;
  border-radius: 50px !important;
  box-shadow: 3px 3px #0000004d;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 20px !important;
  transition: background-color .3s ease;
  margin: auto !important;
}

.event-card-component-button {
  background-color: #36d300;
  border: none;
  border-radius: 50px !important;
  box-shadow: 3px 3px #0000004d;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 20px !important;
  transition: background-color .3s ease;
  margin: auto !important;
}

.newsletter-signup-highlight {
  color: #36d300 !important;
}

.dual-product-ad-carousel-button {
  background-color: #ffb511 !important;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 20px !important;
  text-decoration: none;
  transition: background-color .3s;
}

.newsletter-signup-button {
  background-color: #36d300 !important;
  border-radius: 50px !important;
  color: #fff;
  cursor: pointer;
  padding: 10px 25px !important;
  transition: background-color .3s;
}

.newsletter-signup-input {
  border: none;
  border-radius: 50px !important;
  color: #2d3748;
  margin-bottom: 0px !important;
  outline: none;
  padding: .625rem;
  padding-left: 15px !important;
  width: 100%;
}

.newsletter-signup-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.75rem 5rem;
}

.main-header-component-categories-button {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.1) !important;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 100%;
  justify-content: space-between;
  padding-left: 40px;
  width: 100%;
}

.quad-product-ad-carousel-button {
  background-color: #ffb511 !important;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 20px !important;
  text-decoration: none;
  transition: background-color .3s;
}

.sponsored-product-carousel-button {
  background-color: #ffb511 !important;
  border: none;
  border-radius: 50px !important;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin: 25px !important;
  padding: 5px 25px !important;
  text-decoration: none;
  transition: background-color .3s;
}

.event-card-component-calendar-buttons {
  background: rgba(250, 250, 250, 0.2) !important;
  border-radius: 50px !important;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding: 10px 25px !important;
}

.event-card-component-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize !important;
}

.sponsored-carousel-nav-button {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: none;
  border-radius: 50px !important;
  color: #fff;
  cursor: pointer;
  padding: 0px !important;
  position: absolute;
  height: 35px !important;
  width: 35px !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.events-page-aside {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border-left: 1px solid rgba(250, 250, 250, 0.5) !important;
  height: 100vh;
  padding: 20px;
  width: 25%;
}

.my-event-card-component-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize !important;
}

.my-event-card-component-info {
  background: linear-gradient(90deg, #000c 20%, #0000);
  color: #fff;
  left: 0;
  padding: 10px !important;
  display: block !important;
  position: absolute;
  right: 0;
  top: 0.1px;
}

.my-event-card-component-button {
  background-color: #36d300;
  border: none;
  border-radius: 50px;
  box-shadow: 3px 3px #0000004d;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin: auto !important;
  padding: 5px 20px !important;
  transition: background-color .3s ease;
}

.my-event-card-component-date, .my-event-card-component-location, .my-event-card-component-time {
  align-items: center;
  color: #fffc;
  display: flex;
  font-size: 14px;
  margin: 5px 0;
  text-transform: capitalize !important;
}

.my-event-card-component-description {
  font-size: 16px;
  margin: 5px 0;
  text-transform: capitalize !important;
}

.my-events-title {
  color: rgba(250, 250, 250, 0.8) !important;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5px;
  text-align: center;
}

.events-page-create-event-button {
  background-color: #36d300;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  padding: 7px 25px !important;
  transition: background-color .3s;
  margin: auto;
  text-align: center;
  margin-left: 25%;
}

.my-event-card-component-button, .my-event-card-component-button a:hover {
  background-color: #36d300;
  border: none;
  border-radius: 50px;
  box-shadow: 3px 3px #0000004d;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-left: 35% !important;
  padding: 5px 20px !important;
  transition: background-color .3s ease;
}

.my-event-card-component-calendar-buttons {
  background: rgba(0, 0, 0, 0.7) !important;
  border-radius: 50px !important;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding: 5px 25px !important;
}

.events-page-select {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  color: #ffffff !important;
  font-size: 16px;
  padding: 5px 25px !important;
  transition: border-color .3s;
}

.map-page-listbox-options {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-radius: 4px;
  box-shadow: 0 4px 8px #0000001a;
  margin-top: 8px;
  max-height: 240px;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
}

.map-page-listbox-button {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  color: #ffffff !important;
  display: flex;
  font-size: .875rem;
  font-weight: 500;
  padding: 8px 12px;
}

.my-content-carousel-container {
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  height: 310px;
  width: 100%;
}

.my-content-carousel-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
}

.my-content-carousel-item {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: opacity 0.5s ease-in-out;
}

.my-content-carousel-item.active {
  opacity: 1;
}

.my-content-carousel-video-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.my-content-carousel-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.my-content-carousel-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-content-carousel-title {
  font-size: 1rem;
  margin-bottom: 5px;
}

.my-content-carousel-description {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.my-content-carousel-button {
  background-color: #ff5722;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  font-size: 0.8rem;
  width: 50%;
}

.my-content-carousel-button:hover {
  background-color: #e64a19;
}

.my-content-carousel-control {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 8px;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.my-content-carousel-control.left {
  left: 5px;
}

.my-content-carousel-control.right {
  right: 5px;
}

.my-content-carousel-control:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Meme slide container */
.meme-slide {
  background-color: rgba(250, 250, 250, 0.1);
  position: relative;
  height:100%
}

/* Meme slide title */
.meme-slide-title {
  font-size: 0.5rem;
  margin-bottom: 10px;
}

/* Meme slide button container */
.meme-slide-button-container {
 
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding: 10px 0;
}

/* Meme slide button */
.meme-create-button {
  padding: 10px 20px;
  background-color: #36d300;
  color: #fff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.meme-create-button:hover {
  background-color: #0056b3;
}

/* Modal container */
.meme-modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal content */
.meme-modal-content {
  background-color: #fff;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
}

/* Close button */
.meme-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.meme-close:hover,
.meme-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


/* Meme creator */
.meme-creator {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(250, 250, 250, 0.1);
}

.meme-creator h2 {
  text-align: center;
  margin-bottom: 20px;
}

.meme-upload-section {
  text-align: center;
  margin-bottom: 20px;
}

.meme-upload-label {
  cursor: pointer;
  padding: 10px 20px;
  background-color: rgba(250, 250, 250, 0.3);
  color: #fff;
  border-radius: 5px;
  display: inline-block;
}

.meme-text-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.meme-preview {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.meme-preview-image {
  max-width: 100%;
  border-radius: 5px;
}

.meme-text-overlay {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 24px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.meme-submit-button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.meme-submit-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.meme-error-message {
  color: red;
  margin-bottom: 20px;
  text-align: center;
}

/* Meme gallery */

.meme-gallery {
  padding: 2px;
}

.meme-gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.meme-gallery-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.meme-gallery-overlay-text {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.meme-gallery-modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.meme-gallery-modal-content {
  background-color: #fff;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 800px;
  border-radius: 8px;
}

.meme-gallery-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.meme-gallery-close:hover,
.meme-gallery-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.meme-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}


/* Modal container */
.meme-modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal content */
.meme-modal-content {
  background-color: rgba(0, 0, 0, 0.8);
  margin: 10% auto;
  padding: 20px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
}

/* Close button */
.meme-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.meme-close:hover,
.meme-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* About user */

.about-user-container {
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(250, 250, 250, 0.4);
  padding: 20px;
  margin: 0 auto;
}

.about-user-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e7eb;
}

.about-user-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}

.about-user-subtitle {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #6b7280;
}

.about-user-content {
  padding-top: 20px;
}

.about-user-details {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
}

.about-user-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 12px;
}

.about-user-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #36d300;
}

.about-user-value {
  font-size: 0.875rem;
  color: #4b5563;
}

/* Shop about */

/* Container for the entire shop about section */
.shop-about-container {
  background-color: rgba(250, 250, 250, 0.1);
  padding: 20px;
  border-radius: 15px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  max-width: 700px;
  margin: 0 auto;
}

/* Header of the about section */
.shop-about-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e7eb;
}

.shop-about-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
}

.shop-about-subtitle {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #6b7280;
}

/* Content section */
.shop-about-content {
  margin-top: 20px;
}

.shop-about-details {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  border-top: 1px solid #e5e7eb;
  padding-top: 20px;
}

.shop-about-detail-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 16px 0;
  border-bottom: 1px solid #e5e7eb;
}

.shop-about-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.shop-about-value {
  font-size: 0.875rem;
  color: #4b5563;
}

/* Actions section for the shop owner */
.shop-about-actions {
  text-align: right;
  margin-top: 20px;
}

.shop-about-action-button {
  padding: 10px 15px;
  background-color: #3b82f6;
  color: #ffffff;
  border-radius: 6px;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.shop-about-action-button:hover {
  background-color: #2563eb;
}

/*User Banner */
.user-banner-container {
  position: relative;
  width: 100%;
  height: 400px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
  overflow: hidden;
}

.user-banner-image {
  width: 100%;
  object-fit: cover;
}

.user-banner-controls {
  position: absolute;
  right: 15px;
  top: calc(100% - 3rem); /* Adjust to place the controls 3rem above the bottom of the image */
  display: flex;
  align-items: center;
  gap: 16px; /* Space between the edit icon and the save button */
}

.user-banner-edit-icon {
  width: 42px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-banner-file-input {
  display: none;
}

.user-banner-camera-icon {
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.user-banner-save-button {
  padding: 8px 16px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-banner-save-button:hover {
  background-color: #2563eb;
}

/* Shop Banner */
.shop-banner-container {
  position: relative;
  width: 100%;
  height: 400px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
  overflow: hidden;
}

.shop-banner-image {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease; 
}

.shop-banner-controls {
  position: absolute;
  right: 15px;
  top: calc(100% - 3rem); 
  display: flex;
  align-items: center;
  gap: 16px; 
}

.shop-banner-edit-icon {
  width: 42px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.shop-banner-file-input {
  display: none;
}

.shop-banner-camera-icon {
  color: white;
  font-size: 1.5rem;
}

.shop-banner-save-button {
  padding: 8px 16px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.shop-banner-save-button:hover {
  background-color: #2563eb;
}

/* My room component */

.myroom-carousel-container {
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  height: 310px;
  width: 100%;
}

.myroom-carousel-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
}

.myroom-carousel-item {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: opacity 0.5s ease-in-out;
}

.myroom-carousel-item.active {
  opacity: 1;
}

.myroom-carousel-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.myroom-carousel-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myroom-carousel-title {
  font-size: 1rem;
  margin-bottom: 5px;
}

.myroom-carousel-description {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.myroom-carousel-button {
  background-color: #ff5722;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  font-size: 0.8rem;
  width: 50%;
}

.myroom-carousel-button:hover {
  background-color: #e64a19;
}

.myroom-carousel-control {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 8px;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.myroom-carousel-control.left {
  left: 5px;
}

.myroom-carousel-control.right {
  right: 5px;
}

.myroom-carousel-control:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.profile-sidebar-left {
  border-radius: 10px;
  height: inherit !important;
  order: 2;
  padding: 0 !important;
  width: 25% !important;
  padding: 15px !important;
}

.post-widget-date {
  color: rgba(250, 250, 250, 1.0) !important;
  font-size: 10px;
  padding-left: 4.6rem !important;
  padding-right: 4.5rem !important;
}

.post-widget-comment-input {
  background: #0000;
  border: none;
  color: #fff;
  font-size: .875rem;
  padding: .375rem .5rem;
  resize: none;
  width: 100%;
  color: #ffffff !important;
}

.sidebar-badge {
  border-radius: 9999px;
  color: #fff !important;
  font-size: .75rem;
  font-weight: 700;
  padding: .25rem;
  position: absolute;
  right: -.55rem;
  top: -.25rem;
  height: 20px !important;
  width: 20px !important;
}

.main-header-component-categories {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.1) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-bottom: none !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  bottom: 0;
  display: flex;
  height: 60px;
  margin-bottom: -30px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-top: 10px;
  position: relative;
  width: 270px;
}

.post-widget-date {
  color: #fafafa !important;
  font-size: 10px;
  padding-left: 4.6rem !important;
  padding-right: 4.5rem !important;
  margin-top: -15px !important;
}

.meme-gallery-overlay-text {
  bottom: 10px;
  color: #fff;
  font-size: 1.2rem !important;
  font-weight: 700;
  left: 50%;
  position: absolute;
  top: 65% !important;
  text-shadow: 2px 2px 4px #000000b3;
  transform: translateX(-50%);
  width: 75% !important;
  text-align: center !important;
}

.profile-avatar {
  border: 4px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 3.0) !important;
  border-radius: 50%;
  height: 150px;
  margin: 0px auto auto !important;
  width: 150px;
}

.my-images-component-image-grid {
  grid-gap: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  padding: 0px !important;
  max-height: 300px !important;
  overflow: scroll !important;
}

.user-profile-data-tab-inactive {
  color: #ffffff !important;
}

span.main-header-component-cart-count {
  background-color: #ffb511 !important;
}

.trending-products-carousel-bg {
  background-color: transparent !important;
}

li.trending-products-carousel-item li {
  border-bottom: none !important;
}

.followers-following-panel-content {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  display: flex;
  max-width: 100%;
  max-width: 28rem !important;
  overflow-y: auto;
  padding: 20px;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 10%;
  width: 25% !important;
}

.followers-following-panel-panel {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  height: 100%;
  max-width: 100% !important;
  pointer-events: auto;
  width: 100vw;
}

.marketplace-hero-slide-button {
background-color: #36d300 !important;
padding: 15px 25px !important;
border-radius: 50px !important;
  cursor: pointer !important;
}

.category-section-component-browse-link {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  color: #fff !important;
  display: none;
  font-size: 16px !important;
  font-weight: 600;
  margin-bottom: 25px !important;
  padding: 10px 25px !important;
  transition: color .3s ease;
  cursor: pointer !important;
}

.trending-products-carousel-link-hidden {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  color: #fff !important;
  display: none;
  font-size: 16px !important;
  font-weight: 600;
  margin-bottom: 25px !important;
  padding: 10px 25px !important;
  transition: color .3s ease;
  cursor: pointer !important;
}

.notification-sidebar-close-button {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  color: #fff;
  position: relative;
  padding: 7px !important;
  cursor: pointer !important;
}

.followers-following-panel-close-button {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  color: #fff;
  position: relative;
  padding: 7px !important;
  cursor: pointer !important;
}

.wishlist-component-close-button {
  display: flex;
  justify-content: flex-end;
  position: absolute !important;
  right: 15px !important;
  top: 15px !important;
  width: 40px !important;
  cursor: pointer !important;
}

.cart-sidebar-close-btn-container {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  color: #fff;
  position: relative;
  padding: 7px !important;
  cursor: pointer !important;
}

.avatar-dropdown-menu-close-btn-container {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  color: #fff;
  position: relative;
  padding: 7px !important;
  cursor: pointer !important;
}

.homepage-hero-slide {
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 0px !important;
}

h1.newsletter-signup-title {
  margin-bottom: 15px !important;
}

.newsletter-signup-input {
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  color: #2d3748;
  margin-bottom: 0 !important;
  outline: none;
  padding: .625rem;
  padding-left: 15px !important;
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2) !important;
}

/*---- WALLET------*/


.crypto-wallet-action-button.buy {
  background-color: rgb(54, 211, 0);
  color: #ffffff !important;
  cursor: pointer !important;
}

.crypto-wallet-transfer-button {
  background-color: rgb(255, 181, 17) !important;
  color: #ffffff !important;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 50px;
  flex: 1 1 0%;
  margin: 0px 0.25rem;
  padding: 7px 15px !important;
  cursor: pointer !important;
}

.crypto-wallet-offer-accept-button {
  background-color: rgb(255, 181, 17) !important;
  color: #ffffff !important;
  cursor: pointer;
  font-size: 14px;
  margin-top: 0.5rem;
  text-align: center;
  width: 100%;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 15px;
  padding: 4px 20px;
  cursor: pointer !important;
}

.crypto-wallet-action-button.buy {
  background-color: rgb(255, 181, 17) !important;
  color: #ffffff !important;
  cursor: pointer !important;
}

.crypto-wallet-section-title.crypto-wallet-bottom-bar {
  display: none !important;
}

.crypto-wallet-actions {
  padding: 0px !important;
}

.cart-sidebar-checkout-btn {
  background-color: rgb(255, 181, 17) !important;
  cursor: pointer;
  text-decoration: none;
  border-radius: 50px !important;
  cursor: pointer !important;
}

/*------END WALLET CODE-----*/


.avatar-dropdown-menu-close-btn-container, .cart-sidebar-close-btn-container, .followers-following-panel-close-button, .notification-sidebar-close-button, .wishlist-component-close-button {
  border-radius: 50px !important;
  padding: 7px !important;
  position: absolute !important;
  right: 15px !important;
  top: 15px !important;
  cursor: pointer !important;
}

.follower-list-widget-search {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  box-sizing: border-box;
  margin-bottom: 15px !important;
  padding: 10px 25px !important;
  width: 100%;
}

.following-list-widget-search {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 50px !important;
  box-sizing: border-box;
  margin-bottom: 15px !important;
  padding: 10px 25px !important;
  width: 100%;
}

.my-post-widget-post-button {
  background-color: #46d300 !important;
  border-radius: 50px !important;
  color: #fafafa !important;
  padding: 2px !important;
  width: 15% !important;
  cursor: pointer !important;
}

.main-feed-page {
  background-color: rgba(0, 0, 0, 0.3) !important;
  height: 100vh;
  overflow: hidden;
}

div#root {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.product-details-component__brand-value {
  color: #ffffff !important;
  font-size: 1rem;
  font-weight: 600;
}

.product-details-component__add-to-cart-button {
  align-items: center;
  background-color: #FFB512 !important;
  border-radius: 50px !important;
  box-shadow: 0 4px 6px #0000001a;
  color: #fff;
  display: flex;
  font-weight: 700;
  height: 45px !important;
  justify-content: center;
  margin-top: 25px !important;
  transition: opacity .3s ease;
  width: 25% !important;
  cursor: pointer !important;
}

.product-details-component__delivery-badge, .product-details-component__pickup-badge {
  border-radius: 50px !important;
  box-shadow: 0 4px 6px #0000001a;
  color: #fff;
  font-size: .875rem;
  font-weight: 700;
  padding: .25rem .75rem;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
}


.product-details-component__brand-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 50px !important;
  display: flex;
  margin-top: 16px;
  padding: .25rem .75rem;
}

.product-details-component__add-to-cart-button {
  align-items: center;
  background-color: #FFB512 !important;
  border-radius: 50px !important;
  box-shadow: 0 4px 6px #0000001a;
  color: #fff;
  display: flex;
  font-weight: 700;
  height: 45px !important;
  justify-content: center;
  margin-top: 25px !important;
  transition: opacity .3s ease;
  width: 25% !important;
  cursor: pointer !important;
}

.product-details-component__product-title {
  color: #ffffff !important;
  font-size: 25px !important;
  font-weight: 700;
  margin-top: 15px !important;
}

.product-details-component__send-message-button {
  align-items: center;
  background-color: #6443d1;
  border-radius: 50px !important;
  box-shadow: 0 4px 6px #0000001a;
  color: #fff;
  display: flex;
  font-weight: 700;
  height: 25px !important;
  justify-content: center;
  margin-top: 25px !important;
  padding: 15px 25px !important;
  cursor: pointer !important;
  width: 28% !important;
}

.product-details-component__image-selection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 15px !important;
  overflow: scroll !important;
}

.product-details-component__info-tab-title {
  color: #2d3748;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 25px !important;
  padding-left: 0px !important;
}

.product-details-component__active-indicator {
  background-color: #36d300 !important;
  bottom: 0 !important;
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
}

.product-details-component__info-tab-title {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 25px !important;
  padding-left: 0px !important;
  padding-bottom: 10px !important;
}

.suggested-product-container {
  padding: 0px !important;
  max-width: 1320px !important;
  margin: auto !important;
}

.product-details-component__shop-info {
  align-items: center;
  display: flex;
  padding-top: 25px !important;
  border-top: 1px solid rgba(250, 250, 250, 0.4) !important;
  margin-top: 25px !important;
}

.product-details-component__quantity-button {
  background-color: #2d3748;
  box-shadow: 0 4px 6px #0000001a;
  color: #fff;
  font-weight: 700;
  padding: .5rem 1rem;
  transition: opacity .3s ease;
  border-radius: 50px !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.product-details-component__quantity-value {
  background-color: #edf2f7;
  color: #2d3748;
  font-weight: 500;
  padding: 10px 25px !important;
  border-radius: 50px !important;
  text-align: center !important;
}

.product-details-component__quantity-button {
  background-color: #36d300 !important;
  box-shadow: 0 4px 6px #0000001a;
  color: #fff;
  font-weight: 700;
  padding: .5rem 1rem;
  transition: opacity .3s ease;
  border-radius: 50px !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.product-card-component-container {
  background-color: rgba(250, 250, 250, 0.1) !important;
  border-radius: 8px;
  box-shadow: 0 1px 3px #0000001a;
  cursor: pointer;
  height: 420px;
  padding: 16px;
  position: relative;
  width: 100%;
  border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.product-details-component__visit-shop-button {
  background-color: #36d300 !important;
  border-radius: 50px !important;
  box-shadow: 0 4px 6px #0000001a;
  display: inline-block;
  padding: 15px 25px !important;
  text-align: center;
  transition: background-color .3s ease;
}

.newsletter-signup-input {
  border-radius: 50px !important;
  color: #ffffff !important;
  margin-bottom: 0 !important;
  outline: none;
  padding: 15px 25px !important;
  width: 100%;
}

svg.product-card-component-cart-icon {
  color: #ffffff !important;
  width: 45px !important;
  padding: 10px !important;
  height: auto !important;
  margin-left: 10px !important;
  background-color: #ffb511!important;
  border-radius: 50px !important;
}

.product-card-component-quantity-button {
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  padding: 0px !important;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50px !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.product-card-component-quantity {
  margin: 0px 8px;
  height: 35px !important;
  width: 45px !important;
  text-align: center !important;
  color: #000000 !important;
  background-color: #ffffff !important;
  border-radius: 50px !important;
  padding: 15px !important;
  text-align: center !important;
  padding-top: 5px !important;
}

.product-details-component__main-image {
  border-radius: 10px;
  box-shadow: 0 4px 6px #0000001a;
  height: auto;
  object-fit: cover;
  width: 50% !important;
  float: right !important;
  margin-right: 25px !important;
}

.product-details-component__image-selection {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll !important;
  align-items: center;
  text-align: center;
  margin: auto !important;
  margin-top: 15px !important;
  width: 55% !important;
  float: right !important;
  padding: 15px !important;
  cursor: pointer !important;
}

.product-details-component__thumbnail {
  border-radius: 8px;
  cursor: pointer;
  height: 150px !important;
  margin-bottom: 8px;
  margin-right: 8px;
  object-fit: cover;
  transition: all .3s ease;
  width: auto;
}

.post-widget-reshare-textarea {
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 10px !important;
  margin-bottom: 15px !important;
  padding: 15px !important;
  width: 100%;
}

.post-widget-reshare-button {
  background-color: #36d300;
  border: none;
  border-radius: .375rem;
  color: #fff;
  cursor: pointer;
  font-size: .875rem;
  font-weight: 600;
  padding: 10px 25px !important;
  transition: background-color .2s ease-in-out;
  width: 25% !important;
  border-radius: 50px !important;
  float: right !important;
  align-items: center;
  margin-top: 10px !important;
}

.post-widget-cancel-button {
  background-color: rgba(250, 250, 250, 0.5) !important;
  color: #374151 !important;
  float: left !important;
  margin-top: 10px !important;
}

.post-widget-reshare-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px !important;
}

.post-widget-reshare-buttons-container {
  display: flex;
  justify-content: space-between;
}

.map-page-map-container button {
  background-color: #36d300 !important;
  border-radius: 50px !important;
  cursor: pointer !important;
}

.events-page-modal-box {
  align-content: center;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  width: 80%;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

.event-creation-form-form {
  background-color: transparent !important;
  border-radius: 8px;
  box-shadow: 0 4px 8px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.my-images-component-thumbnail {
  border-radius: 4px;
  cursor: pointer;
  height: auto;
  object-fit: cover;
  transition: transform .2s;
  width: 100%;
  border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.my-images-component h3 {
  margin-bottom: 0px !important;
  text-align: center;
  margin-top: 15px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #ffffff !important;
}

button.add-friend-button-button.following {
  background-color: rgba(250, 250, 250, 0.3) !important;
}

.notification-sidebar-title {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.notification-sidebar-header {
  padding: 0px 0px !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
  padding-bottom: 10px !important;
}

/* My events component - find events button */

.find-events-button {
  background-color: #36d300; 
  color: #ffffff; 
  padding: 10px 20px; 
  border: none; 
  border-radius: 15px; 
  font-size: 16px; 
  cursor: pointer; 
  text-align: center; 
  text-decoration: none;
  display: inline-block; 
  transition: background-color 0.3s ease; 
}

.find-events-button:hover {
  background-color: #3d6000; 
}

.find-events-button:focus {
  outline: none; 
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); 
}

/*--------End---------*/

.followers-following-panel-header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
}

.wishlist-component-item-add-to-cart {
  background-color: #ffb511 !important;
  border-radius: 50px !important;
  color: #fff !important;
  height: auto !important;
  margin-left: 10px !important;
  padding: 10px !important;
  width: 45px !important;
}

.my-events-container {
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-radius: 10px;
  margin: 0 auto;
  max-width: 400px;
  padding: 15px !important;
  background-color: rgba(250, 250, 250, 0.1) !important;
}

.my-images-component h3 {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-bottom: 15px !important;
  margin-top: 0px !important;
  text-align: center;
}

.my-events-title {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-bottom: 15px !important;
  margin-top: 0px !important;
  text-align: center;
}

.find-events-button {
  background-color: #36d300;
  border: none;
  border-radius: 50px !important;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: 5px 25px !important;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease;
  font-weight: 700 !important;
}

.profile-upload-container label svg {
  cursor: pointer !important;
}

.profile-upload-container {
  display: block !important;
  position: absolute !important;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9) !important;
  border: 1px solid rgba(250, 250, 250, 0.9) !important;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  justify-content: left;
  margin-bottom: 10px;
  margin-left: 345px !important;
  margin-top: -40px !important;
  text-align: center !important;
  width: 35px !important;
  height: 35px !important;
  z-index: 40000000000000000 !important;
  float: right !important;
}

.profile-upload-container label svg {
  cursor: pointer !important;
  text-align: center !important;
  position: absolute;
  color: #fff;
  font-size: 1.5rem;
}

.my-post-widget-image-video-container {
  margin: auto !important;
  margin-top: 0px !important;
  width: 75%;
}

.my-post-widget-post-button {
  background-color: #46d300 !important;
  border-radius: 50px !important;
  color: #fafafa !important;
  font-weight: 700;
  cursor: pointer !important;
  padding: 5px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  width: 21% !important;
}

.post-widget-reshare {
  border-bottom: 1px solid #ccc;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.shop-profile-data-tab-active {
  border-bottom: none !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.individual-shop-products-filter-button {
  align-items: center;
  background: none;
  border: none;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
}

.login-component-submit-button {
  align-items: center;
  background-color: #36d300;
  border: none;
  border-radius: 50px !important;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: .875rem;
  font-weight: 500;
  height: 2.5rem;
  justify-content: center;
  transition: background-color .3s ease;
  width: 100%;
}

.login-component-input {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 50px !important;
  box-shadow: 0 1px 2px rgba(0,0,0,0.5) !important;
  font-size: 16px !important;
}

.my-images-component {
  margin-bottom: 15px !important;
}

.profile-avatar {
  margin-bottom: 15px !important;
}

.profile-upload-container label svg {
  top: 5px !important;
  right: 0 !important;
  left: 5px !important;
  bottom: 0 !important;
}

.top-banner-ad-container {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
  margin: 0 auto;
  padding: 25px 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /*z-index: 50;*/
  box-sizing: border-box;
  width: 100%;
}

/* Room creation form */

.room-creation-form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.room-creation-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.room-creation-form-group {
  margin-bottom: 15px;
}

.room-creation-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.room-creation-form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(250, 250, 250, 0.1);
}

.room-creation-form-error {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.room-creation-form-button {
  width: 100%;
  padding: 10px;
  background-color: #36d300;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
}

.room-creation-form-button:disabled {
  background-color: #6c757d;
}

.room-creation-form-banner-preview {
  margin-top: 10px;
}

.room-creation-form-banner-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


/* Rooms container */

.rooms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.rooms-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.rooms-list {
  list-style-type: none;
  padding: 0;
}

.rooms-list-item {
  padding: 15px;
  margin-bottom: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rooms-list-item:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

.room-name {
  font-size: 18px;
  font-weight: bold;
}

.room-description {
  margin-top: 5px;
  color: #6c757d;
}

/* rooms profile */

.rooms-profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.rooms-profile-create-room-button {
  background-color: #36d300;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.rooms-profile-create-room-button:hover {
  background-color: #0056b3;
}

.rooms-profile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.rooms-profile-modal-content {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 15px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  max-width: 500px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
}

.rooms-profile-close-modal-button {
  position: absolute;
  top: 16px;
  right: 24px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;
}

.rooms-profile-close-modal-button:hover {
  color: #333;
}

/* Room details */

.room-details-page {
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.room-banner {
  position: relative;
  width: 100%;
  height: 300px; 
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.room-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.room-name {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.room-description {
  font-size: 1.2em;
  color: #ddd;
  line-height: 1.6;
}

.room-details-content {
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  justify-items: center;
  align-items: center;
}

@media (max-width: 768px) {
  .room-banner {
    height: 200px;
  }

  .room-details-content {
    margin: 10px;
    padding: 15px;
  }

  .room-name {
    font-size: 1.5em;
  }

  .room-description {
    font-size: 1em;
  }
}

.room-members {
  margin-top: 10px;
  margin-left: 120px;
  margin-right: 120px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 10px;
  padding: 20px;
}

.room-members h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.room-members-container {
  display: flex;
}

.room-members-avatar-display {
  display: flex;
  align-items: center;
  gap: 16px; 
}

.room-member-avatar {
  z-index: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: -8px;
}

.room-member-avatar:first-child {
  margin-left: 0;
}

.room-members-count {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  padding: 10px;
  border-radius: 15px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  margin-left: 20px;
}


/* Add members form */

.add-member-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #36d300;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.add-member-button:hover {
  background-color: #218838;
}

.add-member-form-container {
  position: fixed; 
  top: 50%;       
  left: 50%;      
  transform: translate(-50%, -50%); 
  z-index: 1000;  
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); 
  max-width: 90%; 
  width: 500px;
}

.add-member-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-member-form-title {
  font-size: 1.5em;
  color: #333;
}

.add-member-form-close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.add-member-form-icon {
  width: 20px;
  height: 20px;
}

.add-member-form-tabs-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.add-member-form-tab {
  padding: 10px 20px;
  cursor: pointer;
}

.add-member-form-tab-active {
  border-bottom: 2px solid #000;
}

.add-member-form-tab-inactive {
  color: #999;
}

.add-member-form-user-selection {
  margin-top: 20px;
}

.add-member-form-search {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 10px;
}

.add-member-form-list {
  max-height: 200px;
  overflow-y: auto;
}

.add-member-form-user-item label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-member-form-user-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.add-member-form-user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.add-member-form-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.add-member-form-submit {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-member-form-submit:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Custom checkbox styling */
.add-member-form-user-item input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #007bff;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.add-member-form-user-item input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.add-member-form-user-item input[type="checkbox"]:checked::before {
  content: '\2713'; /* Checkmark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: white;
  font-weight: bold;
}

.add-member-form-user-item input[type="checkbox"]:hover {
  border-color: #0056b3;
}

.add-member-form-user-item input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}



/* Campaign modal */

.campaign-creator-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.campaign-creator-modal-content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  max-width: 1200px;
  width: 100%;
  max-height: 90vh; 
  overflow-y: auto; 
  position: relative;
  scroll-behavior: smooth;
  
}

.campaign-creator-close-button {
  position: absolute;
  display: flex;
    align-items: center;
    justify-content: center;
  top: 10px;
  right: 10px;
  background-color: rgba(250, 250, 250, 0.1); 
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease; 
}

.campaign-creator-close-button:hover {
  background-color: rgba(250, 250, 250, 0.3); 
  transform: scale(1.1); 
}

.campaign-creator-close-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.7); 
}


.shop-create-advertisement-campaign-inner-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px; 
  }


  .top-banner-ad-container {
    background-color: transparent !important;
    border-bottom: none !important;
}

.my-images-component {
    padding: 10px !important;
}

.my-post-widget-input-base {
    overflow: scroll !important;
}

.sponsored-brands-widget-cover {
    border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-top: none !important;
}

.rooms-container h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px !important;
    font-weight: 700 !important;
}

.rooms-profile-create-room-button:hover {
    background-color: #36d300 !important;
}
.rooms-profile-create-room-button {
    border-radius: 50px !important;
    font-weight: 700 !important;
}

.sponsored-brands-widget-button {
    font-weight: 700 !important;
}

.sponsored-products-component-visit-store {
    font-weight: 700 !important;
}

.sponsored-brands-widget-title {
    color: #ebebeb !important;
    font-size: 10px !important;
}

.css-a4r3a9, .sidebar-badge {
    background-color: red !important;
}

span.main-header-component-cart-count {
    background-color: red !important;
}

.sponsored-brands-widget-name {
    font-size: 17px !important;
    font-weight: 500 !important;
    margin-top: 0px !important;
    margin-bottom: 15px !important;
}

/*.post-widget-comment-submit-button {
    background-color: #46d300 !important;
    border-radius: 50px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    font-weight: 700;
    padding: 5px 25px !important;
    width: 21% !important;
    font-size: 17px !important;
    transition: background-color .2s ease-in-out;
}*/

span.main-header-component-cart-count {
    border-radius: 50px;
    color: #fff !important;
    font-weight: 700;
    height: 20px !important;
    width: 20px !important;
    padding: .25rem;
    position: absolute;
    align-items: center;
    background-color: red !important;
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    right: 0;
    top: 0;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.icon.css-vubbuv {
    margin-right: 7px !important;
}

.selected-options-display {
  display: flex;
  gap: 40px;
  margin-bottom: 10px;
}

.add-new-ad-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid #36d300;
  border-radius: 30px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}
.add-new-ad-button:hover {
  background-color: rgba(54, 211, 0, 0.1); 
  box-shadow: 0 0 15px 5px rgba(54, 211, 0, 0.7);
}

.create-product-component-wrapper {
  width: 100%;
  padding: 25px !important;
}

button.create-product-component-submit-button {
  border-radius: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin-top: 0px !important;
}

.create-product-component-submit {
  margin-top: 0px !important;
}

select.create-product-component-select {
  border-radius: 50px !important;
  margin-top: 25px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

h5.create-product-component-title {
  margin-bottom: 0px !important;
}

select.create-product-component-select {
  border-radius: 50px !important;
  margin-top: 25px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

select.tag-dropdown {
  border-radius: 50px !important;
  background-color: rgba(250, 250, 250, 0.2) !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

textarea#description {
  max-width: 50% !important;
}

label.create-product-component-form-label {
  top: 0 !important;
  bottom: 100px !important;
  display: block !important;
  margin-bottom: 10px !important;
}

.create-product-component-form-input, .create-product-component-form-textarea {
  border-radius: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.w-full.flex.flex-col.items-center {
  margin: auto !important;
  margin-right: 25px !important;
}

.shop-dashboard-sidebar-container {
  height: auto !important;
  overflow: scroll !important;
  border-bottom-right-radius: 14px !important;
}

textarea#description {
  max-width: 50% !important;
  border-radius: 15px !important;
}

.bulk-product-upload-modal {
  background-color: rgba(250, 250, 250, 0.1) !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

input.CSV-upload-component-file-input {
  border-radius: 50px !important;
  background-color: rgba(250, 250, 250, 0.1) !important;
  cursor: pointer !important;
}
.shop-dashboard-sidebar-profile-switch {
  background-color: rgba(250, 250, 250, 0.1) !important;
  border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.shop-dashboard-sidebar-container {
  height: auto !important;
  overflow: scroll !important;
  border-bottom-right-radius: 14px !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-left: none !important;
  margin-bottom: 50px !important;
}

img.switcheroo-shop-component-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

img.shop-dashboard-header-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

label.create-product-component-upload-label {
  cursor: pointer !important;
  font-size: 18px !important;
  padding: 15px 25px !important;
  background-color: rgba(250, 250, 250, 0.3) !important;
  border-radius: 50px !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

svg.create-product-component-dropzone-icon {
  text-align: center !important;
  margin: auto !important;
}

/* MarketplacePage.css */

/* General page layout */
.marketplace-page-content {
  padding: 0px;
  display: flex;
  flex-direction: column;
}

/* Product Grid */
.marketplace-page-product-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1024px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1280px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
  }
}

/* No products found text */
.marketplace-page-no-products-text {
  text-align: center;
  width: 100%;
  padding-bottom: 100px;
  font-size: 20px;
}

/* Grid layout for components below ShopBySeller */
.marketplace-page-grid-container {
  display: grid;
  grid-template-columns: 75% 25%; 
  margin-top: 20px;
  margin-right: 120px;
  margin-left: 120px;
}

/* Left container (75%) */
.marketplace-page-left-container {
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  margin-right: 20px;
}

/* Right container (25%) */
.marketplace-page-right-container {
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
}

/* Responsive for smaller screens */
@media (max-width: 1024px) {
  .marketplace-page-grid-container {
    grid-template-columns: 1fr; 
  }
}

/* Styling for the circular seller cards */
.circle-shop-list {
  display: flex;
  margin-left: 120px;
  margin-right: 120px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
}

.circle-shop-card {
  text-align: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
}

.circle-avatar-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
  border: 2px solid rgba(250, 250, 250, 0.4);
}

.circle-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease; /* Added transition for smooth fade */
}

/* Hide the shop name initially */
.circle-shop-name {
  opacity: 0;
  font-size: 16px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Disable pointer events on the shop name */
  transition: opacity 0.3s ease;
  z-index: 2;
}

/* Show shop name on hover */
.circle-shop-card:hover .circle-shop-name {
  opacity: 1;
}

.circle-shop-card:hover .circle-avatar {
  opacity: 0.2; /* Dim the avatar on hover to make the text more visible */
}

/* Optional: make the avatar grow slightly on hover */
.circle-shop-card:hover .circle-avatar-container {
  transform: scale(1.05);
}

.suggested-connection-list {
  display: flex;

}

/* Dubs update sept 7 */

.shop-dashboard-sidebar-profile-switch {
  background-color: rgba(250, 250, 250, 0.1) !important;
  border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.shop-dashboard-sidebar-container {
  height: auto !important;
  overflow: scroll !important;
  border-bottom-right-radius: 14px !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-left: none !important;
  margin-bottom: 50px !important;
}

img.switcheroo-shop-component-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

img.shop-dashboard-header-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

label.create-product-component-upload-label {
  cursor: pointer !important;
  font-size: 18px !important;
  padding: 15px 25px !important;
  background-color: rgba(250, 250, 250, 0.3) !important;
  border-radius: 50px !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
}

svg.create-product-component-dropzone-icon {
  text-align: center !important;
  margin: auto !important;
}

/* MarketplacePage.css */

/* General page layout */
.marketplace-page-content {
  padding: 0px;
  display: flex;
  flex-direction: column;
}

/* Product Grid */
.marketplace-page-product-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1024px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1280px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
  }
}

/* No products found text */
.marketplace-page-no-products-text {
  text-align: center;
  width: 100%;
  padding-bottom: 100px;
  font-size: 20px;
}

/* Grid layout for components below ShopBySeller */
.marketplace-page-grid-container {
  display: grid;
  grid-template-columns: 75% 25%; 
  margin-top: 20px;
  margin-right: 120px;
  margin-left: 120px;
}

/* Left container (75%) */
.marketplace-page-left-container {
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  margin-right: 20px;
}

/* Right container (25%) */
.marketplace-page-right-container {
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
}

/* Responsive for smaller screens */
@media (max-width: 1024px) {
  .marketplace-page-grid-container {
    grid-template-columns: 1fr; 
  }
}

/* Styling for the circular seller cards */
.circle-shop-list {
  display: flex;
  margin-left: 120px;
  margin-right: 120px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
}

.circle-shop-card {
  text-align: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
}

.circle-avatar-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
  border: 2px solid rgba(250, 250, 250, 0.4);
}

.circle-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease; /* Added transition for smooth fade */
}

/* Hide the shop name initially */
.circle-shop-name {
  opacity: 0;
  font-size: 16px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Disable pointer events on the shop name */
  transition: opacity 0.3s ease;
  z-index: 2;
}

/* Show shop name on hover */
.circle-shop-card:hover .circle-shop-name {
  opacity: 1;
}

.circle-shop-card:hover .circle-avatar {
  opacity: 0.2; /* Dim the avatar on hover to make the text more visible */
}

/* Optional: make the avatar grow slightly on hover */
.circle-shop-card:hover .circle-avatar-container {
  transform: scale(1.05);
}

.forgot-password-input {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-radius: 50px !important;
    font-size: 16px !important;
    padding: 15px 25px !important;
    color: #ffffff !important;
}

.forgot-password-button {
    background-color: #36d300 !important;
    border-radius: 50px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    padding: 15px 25px !important;
}


.circle-shop-list {
    width: 85% !important;
    margin: auto !important;
    margin-top: 25px !important;
    padding: 15px !important;
    overflow: scroll !important;
    max-width: 1300px !important;
}

.circle-shop-name {
    color: #fff;
    font-size: 14px;
    font-weight: 700 !important;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .3s ease;
    z-index: 2;
    cursor: pointer !important;
}

.circle-shop-name {
    color: #fff;
    font-size: 14px;
    font-weight: 700 !important;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .3s ease;
    z-index: 2;
    cursor: pointer !important;
}

.sponsored-shops-widget-button {
    border-radius: 50px !important;
    padding: 5px 25px !important;
}

.marketplace-page-left-container, .marketplace-page-right-container {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

/*------BACKGROUND CHANGE------*/
.bg-black {
    --tw-bg-opacity: 1;
    background-color: transparent !important;
    border-bottom: none !important;
}

.css-ohwg9z {
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-radius: 15px !important;
}

.sponsored-brand-ad-carousel-content {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.sponsored-product-carousel-image-wrapper {
    background-color: transparent !important;
    border-radius: 15px !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.circle-avatar-container {
    width: 100px !important;
    height: 100px !important;
}

.sponsored-dashboard-filter-row {
    padding: 10px !important;
}

.sponsored-dashboard-time-buttons-row {
    padding: 10px !important;
}

.sponsored-dashboard-filter-item span {
    font-size: 12px !important;
    color: #ffffff !important;
}

.sponsored-dashboard-filter-time-row {
    gap: 25px !important;
    height: 100px !important;
    width: auto !important;
}

.sponsored-dashboard-filter-item.active {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 2px solid rgba(250, 250, 250, 0.5) !important;
    color: #ffffff !important;
}

.sponsored-dashboard-time-filter-item.active {
    background-color: rgba(250, 250, 250, 0.1) !important;
    border: 2px solid rgba(250, 250, 250, 0.5) !important;
    color: #FFFFFF !important;
}

.sponsored-dashboard-time-filter-icon {
    color: #BBBBBB !important;
    font-size: 25px !important;
    margin-bottom: 8px;
}

.sponsored-dashboard-time-filter-item span {
    font-size: 11px !important;
    font-weight: 600;
    color: #ffffff !important;
}

.sponsored-dashboard-filter-item span {
    font-size: 11px !important;
    font-weight: 600;
    color: #ffffff !important;
}

.sponsored-dashboard-filter-icon {
    color: #BBBBBB !important;
    font-size: 25px !important;
    margin-bottom: 8px;
}

.sponsored-dashboard-filter-item {
    padding: 10px !important;
    transition: all .3s ease;
    min-width: 80px !important;
    height: 80px !important;
}

.shop-create-advertisement-campaign-sidebar {
    padding: 15px !important;
    gap: 15px !important;
}

.sponsored-table {
    font-size: 11px !important;
    font-weight: 300 !important;
}

.shop-create-advertisement-campaign-content {
    display: grid;
    flex-grow: 1;
    gap: 25px !important;
    grid-template-columns: 1fr 3fr;
    max-width: 95vw;
    overflow: hidden;
    padding: 20px;
}

.shop-dashboard-sidebar-container {
    border-top: none !important;
}

.create-ad-next-button {
    border-radius: 50px !important;
    font-weight: 500 !important;
}

.create-ad-button-group {
    margin-top: 25px !important;
    width: 25% !important;
    float: right !important;
}

.create-ad-steps-content {
    flex-wrap: wrap;
    max-width: 1200px;
    overflow: hidden;
    border-radius: 100px !important;
    border: none !important;
}

.create-ad-steps-content, .create-ad-steps-wrapper {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    border: none !important;
    margin-bottom: 15px !important;
}

.shop-dashboard-header-icon {
    color: #CCCCCC !important;
    margin: 0 15px !important;
}

.create-ad-button-group {
    width: 25% !important;
    margin: auto !important;
    margin-top: 25px !important;
}
.profile-handle {
  color: #36d300 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
  margin-left: 0px !important;
  margin-top: 0 !important;
  cursor: pointer !important;
}
.sidebar-nav-item-default:hover {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: none !important;
}

span.main-header-component-cart-count {
  margin-top: -7px !important;
  margin-right: -5px !important;
}

.main-header-component-cart-count, .main-header-component-wishlist-count {
  margin-top: -7px !important;
  margin-right: -5px !important;
}

/* Modal backdrop */
.notification-post-view-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Modal content container */
.notification-post-view-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(250, 250, 250, 0.4);
  width: 60%;
  max-width: 800px;
  max-height: 80vh; 
  overflow-y: auto;
  border-radius: 15px;
  z-index: 1000;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
/* Ensure content inside the modal is scrollable */
.notification-post-view-modal-content::-webkit-scrollbar {
  width: 8px;
}

.notification-post-view-modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}

.notification-post-view-modal-content::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Modal close button */
.notification-post-view-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Post content inside modal */


/* Show modal */
.notification-post-view-modal.open .notification-post-view-backdrop {
  opacity: 1;
  visibility: visible;
}

.notification-post-view-modal.open .notification-post-view-modal-content {
  opacity: 1;
  visibility: visible;
}

.notifcations-view-post-button {
  background-color: #36d300; 
  color: white; 
  padding: 10px 20px; 
  font-size: 14px; 
  border: none; 
  border-radius: 25px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
}

.notifcations-view-post-button:hover {
  background-color: #13881e; 
}

span.main-header-component-wishlist-count {
  align-items: center;
  background-color: #36d300 !important;
  border-radius: 50px;
  color: #fff !important;
  display: inline-flex;
  font-size: 12px;
  font-weight: 700;
  height: 20px !important;
  width: 20px !important;
  justify-content: center;
  padding: .25rem;
  position: absolute;
  right: 0;
  top: 0;
}

.category-products-page__active-filter {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-radius: 9999px;
  color: #ffffff !important;
  display: inline-flex;
  font-size: 16px !important;
  font-weight: 500;
  padding: 15px 25px !important;
}

.category-products-page__remove-icon {
  height: 15px !important;
  width: 15px !important;
}

.category-products-page__menu-button {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-radius: 50px !important;
  box-shadow: 0 1px 2px 0 #0000000d;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-flex;
  font-size: .875rem;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  padding: .5rem .75rem;
  width: 150px;
}

.category-products-page__disclosure-title {
  color: #ffffff !important;
  font-weight: 500;
  font-size: 16px !important;
}

.friend-card {
  min-width: 175px !important;
  height: 255px !important;
}

a.friend-card-link {
  height: 100px !important;
}

h4.friend-card-name {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.suggested-connection-list {
  overflow-x: scroll !important;
}

.shop-banner-container {
  height: 250px !important;
}

h2.recommended-connections-title {
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-top: 25px !important;
  margin-bottom: 15px !important;
}

.category-products-page__products {
  width: 90% !important;
}

.category-products-page__disclosure-section {
  border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
  padding: 1.5rem 0;
  width: 85% !important;
  margin: auto !important;
}

.category-products-page__active-filters-bar {
  margin: 1rem auto;
  max-width: 7xl;
  padding: 0px !important;
  width: 85% !important;
  margin-top: 25px !important;
}

h2#filter-heading {
  color: #fff !important;
  font-weight: 700 !important;
}

.trending-products-carousel-product-name {
  font-weight: 700 !important;
}

.trending-products-carousel-info {
  background-color: rgba(250, 250, 250, 0.1) !important;
}

.category-products-page__active-filter {
  padding: 5px 15px !important;
  margin-right: 5px !important;
}

.newsletter-signup-container {
  background-color: rgba(250, 250, 250, 0.1) !important;
}

.room-creation-form-button {
  border-radius: 50px !important;
  font-weight: 700 !important;
}

.post-widget-comment-submit-button {
  border-radius: 50px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 10px 25px !important;
}

.profile-sidebar-component-item.active .profile-sidebar-component-text, .profile-sidebar-component-text.active {
  color: #ffffff !important;
  font-weight: 700 !important;
}

.profile-sidebar-component-button {
  border-radius: 50px !important;
  font-weight: 700 !important;
}

.profile-content-component-form-input {
  background-color: rgba(250, 250, 250, 0.3) !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-radius: 50px !important;
  padding: 8px 25px !important;
}

.profile-content-component-form-group {
  margin-bottom: 15px !important;
}

.profile-content-component-submit-btn {
  background-color: #36d300 !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-radius: 50px !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  margin-bottom: 25px !important;
}

.profile-sidebar-component-button {
  background-color: rgba(250, 250, 250, 0.1) !important;
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-radius: 50px !important;
  margin-top: 25px !important;
  font-weight: 700 !important;
}

.shop-login-component-form-group label {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
}

.shop-login-component-footer a {
  color: #36d300 !important;
  padding-left: .5rem;
  text-decoration: none;
}

.shop-login-component-button {
  background-color: #36d300 !important;
  border-radius: 50px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 7px 25px !important;
}

.shop-login-component-input {
  border: 1px solid rgba(250, 250, 250, 0.4) !important;
  border-radius: 50px !important;
  font-size: 16px !important;
  padding: 7px 15px !important;
  background-color: rgba(250, 250, 250, 0.3) !important;
}

.shop-login-component-eye-icon {
  cursor: pointer;
  position: absolute;
  right: 15px !important;
  top: 73% !important;
  transform: translateY(-50%);
}

.load-more-comments-button {
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 20px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  padding: 8px 16px;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.load-more-comments-button:hover {
  background-color: rgba(255, 255, 255, 0.3); 
  transform: translateY(-2px); 
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); 
}

.subscription-checkout-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.subscription-checkout-form-container {
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 20px;
  padding: 20px;
}

.subscription-checkout-form {
  display: flex;
  flex-direction: column;
}

.subscription-checkout-label {
  margin-bottom: 10px;
  color: #fff;
}

.subscription-checkout-input {
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid rgba(250, 250, 250, 0.4);
}

.subscription-checkout-button {
  background-color: #36d300;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}
.subscription-checkout-coupon-button {
  background-color: rgba(250, 250, 250, 0.1);
  color: #fff;
  padding: 10px 20px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}

.subscription-checkout-details-container {
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 20px;
  padding: 20px;
}

.subscription-checkout-details {
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
}

/**/
.subscription-couponcode-creation-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.subscription-couponcode-creation-form-container {
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 20px;
  padding: 20px;
}

.subscription-couponcode-creation-form {
  display: flex;
  flex-direction: column;
}

.subscription-couponcode-creation-label {
  margin-bottom: 10px;
  color: #fff;
}

.subscription-couponcode-creation-input {
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid rgba(250, 250, 250, 0.4);
}

.subscription-couponcode-creation-button {
  background-color: #36d300;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}

.subscription-couponcode-creation-coupon-button {
  background-color: rgba(250, 250, 250, 0.1);
  color: #fff;
  padding: 10px 20px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}

.subscription-couponcode-creation-details-container {
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 20px;
  padding: 20px;
}

.subscription-couponcode-creation-details {
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 15px;
}

/* Subscription coupon list */

.subscription-couponcode-list-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.subscription-couponcode-heading {
  font-size: 24px;
  color: #343a40;
  margin-bottom: 20px;
  text-align: center;
}

.subscription-couponcode-edit-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 10px;
  margin-bottom: 20px;
}

.subscription-couponcode-form {
  display: flex;
  flex-direction: column;
}

.subscription-couponcode-label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #fff;
}

.subscription-couponcode-input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 15px;
  font-size: 14px;
}

.subscription-couponcode-button-group {
  display: flex;
  justify-content: space-between;
}

.subscription-couponcode-save-btn,
.subscription-couponcode-cancel-btn {
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.subscription-couponcode-save-btn {
  background-color: #28a745;
  color: #fff;
  border: none;
}

.subscription-couponcode-cancel-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
}

.subscription-couponcode-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.subscription-couponcode-table th,
.subscription-couponcode-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.subscription-couponcode-table th {
  background-color: rgba(250, 250, 250, 0.1);
  color: #fff;
}


.subscription-couponcode-action-btn {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.subscription-couponcode-action-btn.delete {
  background-color: #dc3545;
}


.switcheroo-swap-icon {
  padding: 0px 3px 20px !important;
}

.my-content-carousel-container {
  border: 1px solid #fafafa66;
  background-color: rgba(250, 250, 250, 0.1) !important;
  border-radius: 15px;
  height: 310px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.user-banner-image {
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
}

a.share-button {
  background-color: rgba(250, 250, 250, 0.4) !important;
}

.share-button {
  height: 25px !important;
  width: 25px !important;
  border-radius: 50px !important;
  font-size: 16px !important;
  padding: 6px !important;
  margin-top: 25px !important;
  margin-bottom: 15px !important;
}

.shop-info-sidebar-follow-section {
  gap: 15px !important;
}

.shop-info-sidebar-top-row {
  padding-bottom: 25px !important;
  padding-top: 25px !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.shop-info-sidebar-bottom-row {
  gap: 0 !important;
  padding-top: 10px !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
  margin-bottom: 15px !important;
  margin-top: 5px !important;
}

.shop-settings-component-submit-button {
  background-color: #36d300;
  border: none !important;
  border-radius: 50px !important;
  box-shadow: 0 1px 2px #0000000d;
  cursor: pointer;
  margin-bottom: 25px !important;
  padding: 10px !important;
  text-align: center;
  width: 30%;
  font-weight: 700 !important;
}
/*---Shop Settings---*/

.shop-settings-component-avatar, .shop-settings-component-banner {
    display: flex !important;
    justify-content: center;
    margin-bottom: 1.25rem;
    position: relative;
    align-items: center;
    text-align: center;
}

.shop-settings-component-avatar img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin: auto !important;
}

.shop-settings-component-camera-icon-container {
    align-items: center;
    background-color: rgba(250, 250, 250, 0.4) !important;
    right: auto !important;
    width: 20px !important;
    height: 20px !important;
}

.shop-settings-component-input {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-radius: 50px !important;
    padding-left: 15px !important;
}

.shop-settings-page-manage-payment-btn {
    background-color: rgba(250, 250, 250, 0.3) !important;
    border: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-radius: 50px !important;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    padding: 8px 16px;
    text-decoration: none;
}

.css-mh3zap {
    color: #ffffff !important;
}

.css-14349d1 {
    color: #ffffff !important;
}

/* Onboarding steps */
.onboarding-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.onboarding-step {
  display: flex;
  align-items: center;
  position: relative;
}

/* Circle for each step */
.onboarding-step-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease;
}

/* Default styling for upcoming steps */
.onboarding-step-circle.upcoming {
  background-color: #e0e0e0;
  color: #999;
  border: 2px solid #e0e0e0;
}

/* Styling for completed steps */
.onboarding-step-circle.completed {
  background-color: #4caf50;
  color: white;
  border: 2px solid #4caf50;
}

.onboarding-step-circle.completed .onboarding-step-icon {
  font-size: 20px;
  color: white;
}

/* Styling for the current step */
.onboarding-step-circle.current {
  background-color: #ffffff;
  color: #4caf50;
  border: 2px solid #4caf50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
}

/* Step label styling */
.onboarding-step-label {
  margin-left: 10px;
  font-size: 14px;
  transition: color 0.3s ease;
}

/* Default styling for upcoming step labels */
.onboarding-step-label.upcoming {
  color: #999;
}

/* Styling for completed step labels */
.onboarding-step-label.completed {
  color: #4caf50;
}

/* Styling for the current step label */
.onboarding-step-label.current {
  color: #4caf50;
  font-weight: bold;
}

/* Line between steps */
.onboarding-step-line {
  width: 60px;
  height: 4px;
  margin-left: 14px;
  transition: background-color 0.3s ease;
}

/* Line for completed steps */
.onboarding-step-line.completed-line {
  background-color: #4caf50;
}

/* Line for upcoming steps */
.onboarding-step-line.upcoming-line {
  background-color: #e0e0e0;
}

/* Seller Activation Page Styles */

.seller-activation-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 250, 250, 0.1);
  padding: 20px;
}

.seller-activation-content {
  width: 100%;
  max-width: 600px;
  text-align: center;
  background-color: rgba(250, 250, 250, 0.1);
  padding: 40px;
  border-radius: 20px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.seller-activation-error {
  color: red;
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.seller-activation-success p {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 20px;
}

.seller-activation-setup-button {
  padding: 12px 25px;
  font-size: 16px;
  cursor: pointer;
  background-color: #36D300;
  color: white;
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.seller-activation-setup-button:hover {
  background-color: #3d6000;
}

.close-scanner-button {
  position: absolute;
  bottom: 10px;        
  width: 100%;      
  background-color: #f44336; 
  color: white;     
  padding: 20px 15px;
  border: none;
  border-radius: 25px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;   
}

.close-scanner-button:hover {
  background-color: #d32f2f; 
}

.membership-personal-data-button {
  min-width: 125px !important;
}